import printJS from 'print-js';
import Numeral from 'numeral';
import 'moment';    //Composant de manipulation des dates
import 'moment/locale/fr';
import moment from 'moment';
export class Xulaservice {
    constructor() {
        this.state = {
            serveur: 'Remote',
            //wsurl_remote: 'http://localhost:9077/xula',
            wsurl_remote: 'https://opt-api224.com/ws_xula/xula',
            // wsurl_remote: 'http://localhost:9077/ws_xula/xula',

            //wsurl_remote: 'https://new.xulanet.com/ws_xula/xula',
            //wsurl: 'http://192.168.0.10:9077/ws_xula/xula',
            //wsurl_remote: 'http://192.168.0.10:9077/ws_xula/xula', //Dr TOURE

            //wsurl_remote: 'http://192.168.110.11:9077/ws_xula/xula', //Hamana
            //wsurl_remote: 'http://192.168.100.10:9077/ws_xula/xula',
            //wsurl_remote: 'http://192.168.0.10:9077/ws_xula/xula',
            //wsurl_local: 'http://192.168.1.137:9077/ws_xula/xula', //Kissifaramaya et Camayenne plage
            //wsurl_local: 'http://192.168.110.11:9077/ws_xula/xula', //Hamana
            //wsurl_local: 'http://localhost:9077/xula',
            //wsurl_local: 'http://localhost:9077/ws_xula/xula',
            token: localStorage.getItem('token'),
            userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    validateEmail(value) {
        return String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    validatePhoneNumber(value) {
        return !isNaN(value) && value.length == 9 && value[0] == '6' && (function (x) { return (x | 0) === x; })(parseFloat(value))
    };


    get_serveur() {
        return this.state.serveur;
    }

    get_useronnected() {
        return this.state.userconnected;
    }

    disconnect() {
        localStorage.removeItem('userconnected');
        localStorage.removeItem('token');
        window.location = "#/login";
    }

    get_wsurl(serveur) {
        let url = this.state.wsurl_remote;
        if (serveur == 'Local') {
            url = this.state.wsurl_local;
        }
        return url;
    }

    //Permet de retourner le tocken en cours
    getToken() {
        return this.state.token;
    }

    wsurl() {
        if (this.state.serveur == 'Local') {
            let url = localStorage.getItem('srv_url');
            if (url == null) {
                url = this.state.wsurl_local;
            }
            return url;
        } else {
            return this.state.wsurl_remote;
        }
    }


    get_liste_gerer_boutique(idUser) {
        var lien = this.wsurl() + '/gerer_boutique/get_liste_gerer_by_user?idUser=' + idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_gerer_boutique(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idGerer === '0') {
            lien = this.wsurl() + '/gerer_boutique/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/gerer_boutique/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_gerer_boutique(data) {
        var lien = this.wsurl() + '/gerer_boutique/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    enreg_detail_reap_batch(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/detail_reap/add_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_all_detail_reap() {
        var lien = this.wsurl() + '/detail_reap/get_liste_all_detail_reap?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_detail_reap(idReap) {
        var lien = this.wsurl() + '/detail_reap/get_liste_detail_by_id_reap?idReap=' + idReap;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_detail_reap(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idDet === '0') {
            lien = this.wsurl() + '/detail_reap/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/detail_reap/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_detail_reap(data) {
        var lien = this.wsurl() + '/detail_reap/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_ligne_production_batch(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/ligne_production/add_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_ligne_production(idProd) {
        var lien = this.wsurl() + '/ligne_production/get_liste_ligne_production?idProd=' + idProd;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_production(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idLprod === '0') {
            lien = this.wsurl() + '/ligne_production/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/ligne_production/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_ligne_production(data) {
        var lien = this.wsurl() + '/ligne_production/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_all_production() {
        var lien = this.wsurl() + '/production/get_liste_all_production?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_production(pd1, pd2, rid_boutique, rstatut_prod, rnum_bc) {
        var lien = this.wsurl() + '/production/get_liste_production?pd1=' + pd1 + '&pd2=' + pd2 + '&rstatut_prod=' + rstatut_prod + '&rid_boutique=' + rid_boutique + '&rnum_bc=' + rnum_bc + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    enreg_production(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idProd === '0') {
            lien = this.wsurl() + '/production/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/production/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_production(data) {
        var lien = this.wsurl() + '/production/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }







    get_liste_prevdep() {
        var lien = this.wsurl() + '/previsiondepense/get_liste_prev_dep?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_prevdep(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idPrev === '0') {
            lien = this.wsurl() + '/previsiondepense/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/previsiondepense/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_prevdep(data) {
        var lien = this.wsurl() + '/previsiondepense/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    get_liste_obj() {
        var lien = this.wsurl() + '/objvente/get_liste_obj?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_obj(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idObj === '0') {
            lien = this.wsurl() + '/objvente/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/objvente/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_obj(data) {
        var lien = this.wsurl() + '/objvente/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    send_message(canalMsg, idMsg) {
        var lien = this.wsurl() + '/utils/send_message?canalMsg=' + canalMsg + '&idMsg=' + idMsg + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    generer_fiche_bon(idBon) {
        var lien = this.wsurl() + '/etats/generer_fiche_bon?idBon=' + idBon + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "FICHE_BON_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    get_liste_factures_echues(spd1, spd2, rstatut_livraison, rid_client) {
        var lien = this.wsurl() + '/etats/get_liste_factures_echues?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_client=' + rid_client + '&rstatut_livraison=' + rstatut_livraison + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_journal_dette() {
        var lien = this.wsurl() + '/etats/get_journal_dette?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_statut_sync() {
        if (this.state.serveur == 'Local') {
            let val = localStorage.getItem('offline');
            if (val == 'true') {
                val = 'En attente'
            } else {
                val = 'Envoyé'
            }
            return val;
        } else {
            return 'Envoyé';
        }
    }


    getValue(liste, id) {
        let value = null;
        for (let i = 0; i < liste.length; i++) {
            if (liste[i].value == id) {
                value = liste[i];
                break;
            }
        }
        return value;
    }

    add_lfid_batch(data) {
        var lien = this.wsurl() + '/ligne_fidelisation/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    update_lfid(data) {
        var lien = '';
        var methode = 'PUT';
        lien = this.wsurl() + '/ligne_fidelisation/update';
        data.iduser = this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_all_lignes_fidelisations() {
        var lien = this.wsurl() + '/ligne_fidelisation/get_all_lignes_fidelisations?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_lignes_fidelisations(idFid) {
        var lien = this.wsurl() + '/ligne_fidelisation/get_lignes_fidelisations?idFid=' + idFid + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_lignes_fidelisations_for_adding(idFid) {
        var lien = this.wsurl() + '/ligne_fidelisation/get_lignes_fidelisations_for_adding?idFid=' + idFid + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    add_ticket_batch(data, nb) {
        var lien = this.wsurl() + '/ticket_fidelisation/add_batch?nb=' + nb;
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_tickets(spd1, spd2, ridCli) {
        var lien = this.wsurl() + '/ticket_fidelisation/get_liste_tickets?pd1=' + spd1 + '&pd2=' + spd2 + '&ridCli=' + ridCli + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_tickets_by_fidelisation(idFid) {
        var lien = this.wsurl() + '/ticket_fidelisation/get_tickets_by_fidelisation?idFid=' + idFid + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_tickets_for_vente(ridCli, ridArticle) {
        var lien = this.wsurl() + '/ticket_fidelisation/get_tickets_for_vente?ridArticle=' + ridArticle + '&ridCli=' + ridCli + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_detail_lsortie(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idDlsortie == '0') {
            lien = this.wsurl() + '/detail_lsortie/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/detail_lsortie/update';
        }
        data.iduser = this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_detail_lsortie(data) {
        var lien = this.wsurl() + '/detail_lsortie/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_detail_lsortie(idLsortie) {
        var lien = this.wsurl() + '/detail_lsortie/get_liste_detail_lsortie?idLsortie=' + idLsortie + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    enreg_paramprix(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idPprix == '0') {
            lien = this.wsurl() + '/paramprix/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/paramprix/update';
        }
        data.iduser = this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_paramprix(data) {
        var lien = this.wsurl() + '/paramprix/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_paramprix(rid_stype) {
        var lien = this.wsurl() + '/paramprix/liste_paramprix?rid_stype=' + rid_stype + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_article_for_prod(ridCprod) {
        var lien = this.wsurl() + '/articles/get_liste_article_for_prod?ridCprod=' + ridCprod + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_article_spec() {
        var lien = this.wsurl() + '/articles/get_liste_article_spec?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_badge(ids) {
        var lien = this.wsurl() + '/etats/generer_badge?ids=' + ids + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BADGE_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }


    get_situation_boutique(spd1, spd2) {
        var lien = this.wsurl() + '/etats/get_situation_boutique?pd1=' + spd1 + '&pd2=' + spd2 + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_situation_bobine(spd1, spd2, rstatut) {
        var lien = this.wsurl() + '/etats/get_situation_bobine?pd1=' + spd1 + '&pd2=' + spd2 + '&rstatut=' + rstatut + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_journal_livraison(spd1, spd2, rid_boutiq, rlib_article, rid_article, rtype) {
        var lien = this.wsurl() + '/etats/get_journal_livraison?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutiq=' + rid_boutiq + '&rid_article=' + rid_article + '&rlib_article=' + rlib_article + '&rtype_sortie=' + rtype + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_ticket_fidelite(data) {
        var lien = this.wsurl() + '/ticket_fidelisation/generate';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    generer_carte_fidelite(ids) {
        var lien = this.wsurl() + '/etats/generer_carte_fidelite?ids=' + ids + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "CARTE_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    export_carte_info(ids) {
        var lien = this.wsurl() + '/etats/export_carte_info?ids=' + ids + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "CARTE_INFO" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    download_photos_clients(ids) {
        var lien = this.wsurl() + '/utils/download_photos_clients?ids=' + ids + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "PHOTOS_CLIENTS_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".ZIP");
                a.click();
            }
            );
        });
    }

    editer_ticket_fidelite(ids) {
        var lien = this.wsurl() + '/etats/editer_ticket_fidelite?ids=' + ids + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "TICKET_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
        /*
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(ids)
        }).then(response => {
            if (response.ok) {
               return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "LISTE_ABONNE_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".XLS");
                a.click();
            }
            );
        });
            }
        })
        */
    }


    enreg_fidelisation(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idFid == '0') {
            lien = this.wsurl() + '/fidelisation/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/fidelisation/update';
        }
        data.iduser = this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_fidelisation(data) {
        var lien = this.wsurl() + '/fidelisation/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_fidelisation() {
        var lien = this.wsurl() + '/fidelisation/liste_fidelisation?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_catfidelisation(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCatFid == '0') {
            lien = this.wsurl() + '/catfidelisation/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/catfidelisation/update';
        }
        data.iduser = this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_catfidelisation(data) {
        var lien = this.wsurl() + '/catfidelisation/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_catfidelisation() {
        var lien = this.wsurl() + '/catfidelisation/liste_catfidelisation?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_article_light() {
        var lien = this.wsurl() + '/articles/get_liste_article_light?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_situation_agent(spd1, spd2, ridboutiq) {
        var lien = this.wsurl() + '/etats/get_situation_agent?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + ridboutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_planifprix_for_adding(rid_article) {
        var lien = this.wsurl() + '/planifprix/get_liste_planifprix_for_adding?rid_article=' + rid_article + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    update_planif_prix(data) {
        var methode = 'PUT';
        var lien = this.wsurl() + '/planifprix/update';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_planif_prix(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/planifprix/add_batch';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_planif_prix(spd1, spd2, rid_article, rid_boutique, retat_plan) {
        var lien = this.wsurl() + '/planifprix/liste_planifprix?pd1=' + spd1 + '&pd2=' + spd2 + '&retat_plan=' + retat_plan + '&rid_boutique=' + rid_boutique + '&rid_article=' + rid_article + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_planif_prix(data) {
        var methode = 'DELETE';
        var lien = this.wsurl() + '/planifprix/delete';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    get_st_vente_by_client(spd1, spd2, rid_article, topN) {
        var lien = this.wsurl() + '/etats/get_st_vente_by_client?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_article=' + rid_article + '&topN=' + topN + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_detail_ttdu_client(rboutique, rannee) {
        var lien = this.get_wsurl() + '/etats/get_detail_ttdu_client?ridBoutiq=' + rboutique + '&rannee=' + rannee + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_detail_tt_non_livre(rboutique, rannee, rtype) {
        var lien = this.get_wsurl() + '/etats/get_detail_tt_non_livre?ridBoutiq=' + rboutique + '&rannee=' + rannee + '&rtype=' + rtype + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_detail_recouvrement(rboutique, rannee) {
        var lien = this.get_wsurl() + '/etats/get_detail_recouvrement?ridBoutiq=' + rboutique + '&rannee=' + rannee + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }


    get_liste_operation_global_client() {
        var lien = this.wsurl() + '/etats/liste_operation_global_client?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_trs_by_facture(data) {
        var lien = this.state.wsurl_remote + '/transfert_stock/liste_vtransfert_stock_by_facture?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_sortie_by_ids(data) {
        var lien = this.state.wsurl_remote + '/sortie/get_liste_sortie_by_ids?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_sortie_serv(spd1, spd2, rnum_facture, rboutique, rstatut_reglement, rstatut_livraison, rid_client, rid_parent_assure, rid_assure, rlivreur, typeSortie, rtype_client, serveur) {
        var lien = '';
        if (serveur == 'Local') {
            lien = this.state.wsurl_local + '/sortie/get_liste_sortie_by_criteria?pd1=' + spd1 + '&pd2=' + spd2 + '&rnumfacture=' + rnum_facture + '&ridboutiq=' + rboutique + '&rstatut_reglement=' + rstatut_reglement + '&rstatut_livraison=' + rstatut_livraison + '&rid_client=' + rid_client + '&rid_parent_assure=' + rid_parent_assure + '&rid_assure=' + rid_assure + '&rlivreur=' + rlivreur + '&rtype_sortie=' + typeSortie + '&rtype_client=' + rtype_client + '&iduser=' + this.state.userconnected.idUser;
        } else {
            lien = this.state.wsurl_remote + '/sortie/get_liste_sortie_by_criteria?pd1=' + spd1 + '&pd2=' + spd2 + '&rnumfacture=' + rnum_facture + '&ridboutiq=' + rboutique + '&rstatut_reglement=' + rstatut_reglement + '&rstatut_livraison=' + rstatut_livraison + '&rid_client=' + rid_client + '&rid_parent_assure=' + rid_parent_assure + '&rid_assure=' + rid_assure + '&rlivreur=' + rlivreur + '&rtype_sortie=' + typeSortie + '&rtype_client=' + rtype_client + '&iduser=' + this.state.userconnected.idUser;
        }
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_corbeille_sortie(spd1, spd2, rnum_facture, rboutique, rid_client) {
        var lien = this.get_wsurl() + '/sortie/get_liste_corbeille_sortie?pd1=' + spd1 + '&pd2=' + spd2 + '&rnumfacture=' + rnum_facture + '&ridboutiq=' + rboutique + '&rid_client=' + rid_client + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    add_batch_tmp_sortie_supr(data, serveur) {
        var methode = 'DELETE';
        var lien = '';
        if (serveur == 'Local') {
            lien = this.state.wsurl_local + '/tmp_sortie_supr/add_batch?iduser=' + this.state.userconnected.idUser;
        } else {
            lien = this.state.wsurl_remote + '/tmp_sortie_supr/add_batch?iduser=' + this.state.userconnected.idUser;
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }




    get_statut_offline() {
        let val = localStorage.getItem('offline');
        return val;
    }

    refresh_solde(date_solde) {
        var lien = this.wsurl() + '/solde/refresh?date_solde=' + date_solde + '&iduser=' + this.state.userconnected.idUser;
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_voyage(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idVoy == '0') {
            lien = this.wsurl() + '/voyage/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/voyage/update';
        }
        data.iduser = this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_voyage(data) {
        var lien = this.wsurl() + '/voyage/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_voyage(spd1, spd2, rmatr_pers, rid_imo, rtype_voy) {
        var lien = this.wsurl() + '/voyage/get_liste_voyage?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatr_pers=' + rmatr_pers + '&rid_imo=' + rid_imo + '&rtype_voy=' + rtype_voy + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }



    generer_liste_alerte_peremption(spd1, spd2, rid_boutiq, format) {
        var lien = this.wsurl() + '/etats/generer_liste_alerte_peremption?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutiq=' + rid_boutiq + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "LISTE_ALERTE_PEREMPTION_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }


    get_liste_alerte_rupture(rid_boutiq, rstatut) {
        var lien = this.wsurl() + '/etats/get_liste_alerte_rupture?rid_boutiq=' + rid_boutiq + '&rstatut=' + rstatut + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_alerte_peremption(spd1, spd2, rid_boutiq) {
        var lien = this.wsurl() + '/etats/get_liste_alerte_peremption?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutiq=' + rid_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //alert('nok')
            }
        })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_mvt_client(spd1, spd2, ridcli, rid_boutiq, rlibOp) {
        var lien = this.wsurl() + '/etats/get_liste_mvt_client?pd1=' + spd1 + '&pd2=' + spd2 + '&ridcli=' + ridcli + '&rid_boutiq=' + rid_boutiq + '&rlibOp=' + rlibOp + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_balance_assure(spd1, spd2, ridcli, rstatut_reglement, ridbenef, ridsouscripteur, rtype_client, format) {
        var lien = this.wsurl() + '/etats/generer_balance_assure?pd1=' + spd1 + '&pd2=' + spd2 + '&ridcli=' + ridcli + '&rstatut_reglement=' + rstatut_reglement + '&ridbenef=' + ridbenef + '&ridsouscripteur=' + ridsouscripteur + '&rtype_client=' + rtype_client + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BALANCE_ASSURE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    get_balance_assure(spd1, spd2, ridcli, rstatut_reglement, ridbenef, ridsouscripteur, rtype_client) {
        var lien = this.wsurl() + '/etats/get_balance_assure?pd1=' + spd1 + '&pd2=' + spd2 + '&ridcli=' + ridcli + '&rstatut_reglement=' + rstatut_reglement + '&ridbenef=' + ridbenef + '&ridsouscripteur=' + ridsouscripteur + '&rtype_client=' + rtype_client + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_facture_benef(spd1, spd2, ridcli, rstatut_reglement, ridbenef, ridsouscripteur, rtype_client, format) {
        var lien = this.wsurl() + '/etats/generer_factures_assure?pd1=' + spd1 + '&pd2=' + spd2 + '&ridcli=' + ridcli + '&rstatut_reglement=' + rstatut_reglement + '&ridbenef=' + ridbenef + '&ridsouscripteur=' + ridsouscripteur + '&rtype_client=' + rtype_client + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "FACTURE_ASSURE_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }


    generer_balance_souscripteur(spd1, spd2, ridcli, rstatut_reglement, ridbenef, ridsouscripteur, rtype_client, format) {
        var lien = this.wsurl() + '/etats/generer_balance_souscripteur?pd1=' + spd1 + '&pd2=' + spd2 + '&ridcli=' + ridcli + '&rstatut_reglement=' + rstatut_reglement + '&ridbenef=' + ridbenef + '&ridsouscripteur=' + ridsouscripteur + '&rtype_client=' + rtype_client + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BALANCE_SOUSCRIPTEUR_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    get_balance_souscripteur(spd1, spd2, ridcli, rstatut_reglement, ridbenef, ridsouscripteur, rtype_client) {
        var lien = this.wsurl() + '/etats/get_balance_souscripteur?pd1=' + spd1 + '&pd2=' + spd2 + '&ridcli=' + ridcli + '&rstatut_reglement=' + rstatut_reglement + '&ridbenef=' + ridbenef + '&ridsouscripteur=' + ridsouscripteur + '&rtype_client=' + rtype_client + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    delete_parent_assure(data) {
        var lien = this.wsurl() + '/parent_assure/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_parent_assure(ridcli) {
        var lien = this.wsurl() + '/parent_assure/liste_parent_assure?ridcli=' + ridcli + '&iduser=' + this.state.userconnected.idUser;
        // alert(lien);
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_parent_assure(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idParentAss === '0') {
            lien = this.wsurl() + '/parent_assure/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/parent_assure/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }






    generer_compte_client(ridcli, format) {
        var lien = this.wsurl() + '/etats/generer_compte_client?ridcli=' + ridcli + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "COMPTE_CLIENT_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }


    get_last_msg(idUser, token) {
        var lien = this.wsurl() + '/etats/get_last_msg?iduser=' + idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }).then(response => {
            if (response.ok) {
                return response.text();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_dest_msg_batch(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/dest_msg/add_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_dest_msg(idMsg) {
        var lien = this.wsurl() + '/dest_msg/get_dest_by_msg?idMsg=' + idMsg + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    enreg_dest_msg(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idDestmsg === '0') {
            lien = this.wsurl() + '/dest_msg/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/dest_msg/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_dest_msg(data) {
        var lien = this.wsurl() + '/dest_msg/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_msg(cibleMsg) {
        var lien = this.wsurl() + '/msg/liste_msg?iduser=' + this.state.userconnected.idUser + '&cibleMsg=' + cibleMsg;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_msg(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idMsg === '0') {
            lien = this.wsurl() + '/msg/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/msg/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_msg(data) {
        var lien = this.wsurl() + '/msg/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }




    get_liste_trace_sortie(rref_trace) {
        var lien = this.wsurl() + '/traces/liste_trace_sortie?rref_trace=' + rref_trace + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }


    get_liste_trace(spd1, spd2, rid_user, rref_trace, raction_trace, rcible_trace) {
        var lien = this.wsurl() + '/traces/liste_trace?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_user=' + rid_user + '&rref_trace=' + rref_trace + '&raction_trace=' + raction_trace + '&rcible_trace=' + rcible_trace + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    generer_edition_balance_four(format) {
        var lien = this.wsurl() + '/etats/generer_edition_balance_four?format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BALANCE_FOURNISSEUR_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    get_balance_four() {
        var lien = this.wsurl() + '/etats/get_balance_four?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_journal_trs(spd1, spd2, rid_boutiq_srce, rid_boutiq_dest, rlib_article) {
        var lien = this.wsurl() + '/etats/get_journal_trs?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutiq_srce=' + rid_boutiq_srce + '&rid_boutiq_dest=' + rid_boutiq_dest + '&rlib_article=' + rlib_article + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_edition_recu_trf(idEf) {
        var lien = this.wsurl() + '/etats/generer_recu_trf?idEf=' + idEf + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "RECU_TRANSFERT_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }


    enreg_assure(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idAss === '0') {
            lien = this.wsurl() + '/assures/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/assures/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_assure(data) {
        var lien = this.wsurl() + '/assures/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_assure(ridcli) {
        var lien = this.wsurl() + '/assures/liste_assure?ridcli=' + ridcli + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }



    generer_details_facture(spd1, spd2, rstatut_reglement, ridcli, ridsouscripteur, ridbenef, typefacture, format) {
        var lien = this.wsurl() + '/etats/generer_details_facture?pd1=' + spd1 + '&pd2=' + spd2 + '&rstatut_reglement=' + rstatut_reglement + '&ridcli=' + ridcli + '&ridsouscripteur=' + ridsouscripteur + '&ridbenef=' + ridbenef + '&typefacture=' + typefacture + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "DETAIL_FACTURE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    generer_liste_mv_article(spd1, spd2, rid_article, format) {
        var lien = this.wsurl() + '/etats/generer_liste_mv_article?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_article=' + rid_article + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "LISTE_MV_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    liste_mv_article(spd1, spd2, rid_article) {
        var lien = this.wsurl() + '/etats/liste_mv_article?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_article=' + rid_article + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    get_journal_sortie_stock(spd1, spd2, rid_boutiq, rlib_article, rid_article, rstatut_livraison, rtype, rConformite) {
        var lien = this.wsurl() + '/etats/get_journal_sortie_stock?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutiq=' + rid_boutiq + '&rid_article=' + rid_article + '&rlib_article=' + rlib_article + '&rstatut_livraison=' + rstatut_livraison + '&rtype_sortie=' + rtype + '&rConformite=' + rConformite + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_journal_appro(spd1, spd2, rid_boutiq, rlib_article, rid_article, rcb_article, rnum_cmde, rtype_appro) {
        var lien = this.wsurl() + '/etats/get_journal_appro?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutiq=' + rid_boutiq + '&rid_article=' + rid_article + '&rlib_article=' + rlib_article + '&rcb_article=' + rcb_article + '&rnum_cmde=' + rnum_cmde + '&rtype_appro=' + rtype_appro + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_st_sortie_by_agent(spd1, spd2, rboutique) {
        var lien = this.wsurl() + '/etats/get_st_sortie_by_agent?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + rboutique + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_st_sortie_by_article(spd1, spd2, rboutique, rtype, rstype, topN) {
        var lien = this.wsurl() + '/etats/get_st_sortie_by_article?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + rboutique + '&rtype=' + rtype + '&rstype=' + rstype + '&topN=' + topN + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_st_sortie_by_article(spd1, spd2, rboutique, rtype, rstype, topN, format) {
        var lien = this.wsurl() + '/etats/generer_st_sortie_by_article?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + rboutique + '&rtype=' + rtype + '&rstype=' + rstype + '&topN=' + topN + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "STAT_SORTIE_ARTICLE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    get_st_depense(spd1, spd2, rboutique) {
        var lien = this.wsurl() + '/etats/get_st_depense?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + rboutique + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_st_depense(spd1, spd2, rboutique, format) {
        var lien = this.wsurl() + '/etats/generer_st_depense?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + rboutique + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "STAT_DEPENSE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }


    get_st_sortie_by_month(spd1, spd2, rboutique, rid_client) {
        var lien = this.wsurl() + '/etats/get_st_sortie_by_month?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + rboutique + '&rid_client=' + rid_client + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_st_sortie_by_month(spd1, spd2, rboutique, rid_client, format) {
        var lien = this.wsurl() + '/etats/generer_st_sortie_by_month?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + rboutique + '&rid_client=' + rid_client + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "STAT_VENTE_MOIS_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }


    send_facture(idSortie, type_facture, format, data) {
        var lien = this.wsurl() + '/etats/send_facture?idSortie=' + idSortie + '&type_facture=' + type_facture + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_all_stype_inart() {
        var lien = this.wsurl() + '/etats/get_liste_all_stype_inart?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_table_sortie(rid_salle) {
        var lien = this.wsurl() + '/etats/get_liste_table_sortie?rid_salle=' + rid_salle + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_table(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idTable === '0') {
            lien = this.wsurl() + '/tables/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/tables/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_table(data) {
        var lien = this.wsurl() + '/tables/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_table(rid_salle) {
        var lien = this.wsurl() + '/tables/liste_table?rid_salle=' + rid_salle + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_salle(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idSalle === '0') {
            lien = this.wsurl() + '/salles/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/salles/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_salle(data) {
        var lien = this.wsurl() + '/salles/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    liste_salle_in_table(rid_boutiq) {
        var lien = this.wsurl() + '/salles/liste_salle_in_table?rid_boutiq=' + rid_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_salle(rid_boutiq) {
        var lien = this.wsurl() + '/salles/liste_salle?rid_boutiq=' + rid_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    download_fichier_joint(namefile, destination) {
        var lien = this.wsurl() + '/utils/download_fichier_joint?namefile=' + namefile + '&destination=' + destination + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", namefile);
                a.click();
            }
            );
        });
    }

    download_file(namefile, destination) {
        var lien = this.wsurl() + '/utils/download_file?namefile=' + namefile + '&destination=' + destination + '&iduser=' + this.state.userconnected.idUser;
        return lien
    }

    upload_file(file, namefile, destination) {
        var methode = 'POST';
        var lien = this.wsurl() + '/utils/upload_file';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('iduser', this.state.userconnected.idUser);
        formData.append('namefile', namefile);
        formData.append('destination', destination);
        return fetch(lien, {
            method: methode,
            headers: {
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }


    generer_journal_commande(spd1, spd2, rnumcmde, rboutique, rstatut_cmde, rid_four, rtype_cmde, rtype_four, rcible_date, format) {
        var lien = this.wsurl() + '/etats/generer_journal_commande?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_four=' + rid_four + '&ridboutiq=' + rboutique + '&rnumcmde=' + rnumcmde + '&rstatut_cmde=' + rstatut_cmde + '&rtype_cmde=' + rtype_cmde + '&rtype_four=' + rtype_four + '&rcible_date=' + rcible_date + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "JOURNAL_COMMANDE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    regler_cmde_bloc(data, modeRgl, refRgl, daterglCmde, statut_sync) {
        var methode = 'POST';
        var lien = this.wsurl() + '/regler_cmde/add_batch?modeRgl=' + modeRgl + '&refRgl=' + refRgl + '&daterglCmde=' + daterglCmde + '&statut_sync=' + statut_sync + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    generer_edition_solde(spd1, spd2, ridboutiq, format) {
        var lien = this.wsurl() + '/etats/generer_journal_solde?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + ridboutiq + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "JOURNAL_SOLDE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    get_journal_solde(spd1, spd2, ridboutiq) {
        var lien = this.wsurl() + '/etats/get_journal_solde?pd1=' + spd1 + '&pd2=' + spd2 + '&ridboutiq=' + ridboutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    generer_edition_balance_client(format) {
        var lien = this.wsurl() + '/etats/generer_edition_balance_client?format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BALANCE_CLIENT_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    generer_edition_jrn_vente(spd1, spd2, rnum_facture, rboutique, rstatut_reglement, rstatut_livraison, rid_client, rid_parent_assure, rid_assure, rlivreur, typeSortie, format, rtype_client, rtag_sortie) {
        var lien = this.wsurl() + '/etats/generer_edition_jrn_vente?pd1=' + spd1 + '&pd2=' + spd2 + '&rnumfacture=' + rnum_facture + '&ridboutiq=' + rboutique + '&rstatut_reglement=' + rstatut_reglement + '&rstatut_livraison=' + rstatut_livraison + '&rid_client=' + rid_client + '&rid_parent_assure=' + rid_parent_assure + '&rid_assure=' + rid_assure + '&rlivreur=' + rlivreur + '&rtype_sortie=' + typeSortie + '&format=' + format + '&rtag_sortie=' + rtag_sortie + '&rtype_client=' + rtype_client + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "JOURNAL_VENTE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }


    generer_edition_inventaire(rlib, rcb, rtype, rstype, rid_boutiq, format) {
        var lien = this.wsurl() + '/etats/generer_inventaire?rlib=' + rlib + '&rcb=' + rcb + '&rtype=' + rtype + '&rstype=' + rstype + '&ridboutiq=' + rid_boutiq + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "INVENTAIRE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }


    get_identification(login, pwd) {
        var lien = this.wsurl() + '/get_identification';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userName: login, password: pwd })
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_situation_client(spd1, spd2, ridCli) {
        var lien = this.wsurl() + '/client/get_situation_client?pd1=' + spd1 + '&pd2=' + spd2 + '&ridCli=' + ridCli + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    regler_sortie_bloc(data, modeRegl, refRegl, statut_sync) {
        var methode = 'POST';
        var lien = this.wsurl() + '/regler_sortie/regler_en_bloc?modeRegl=' + modeRegl + '&refRegl=' + refRegl + '&statut_sync=' + statut_sync + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    valider_depense(data) {
        var methode = 'PUT';
        var lien = this.wsurl() + '/depense/valider';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_depense(data) {
        var methode = 'POST';
        var lien = '';
        if (data.iddep === '0') {
            lien = this.wsurl() + '/depense/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/depense/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_depense(spd1, spd2, retat_dep, rtype_dep, rid_boutique, rdate_rech) {
        var lien = this.wsurl() + '/depense/liste_depense?pd1=' + spd1 + '&pd2=' + spd2 + '&retat_dep=' + retat_dep + '&rid_boutique=' + rid_boutique + '&rtype_dep=' + rtype_dep + '&rdate_rech=' + rdate_rech + '&iduser=' + this.state.userconnected.idUser;
        //console.log(lien);
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_depense(data) {
        var methode = 'DELETE';
        var lien = this.wsurl() + '/depense/delete';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    generer_edition_recu_operation_client(idOpcli) {
        var lien = this.wsurl() + '/etats/generer_recu_operation_client?idOpcli=' + idOpcli + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "RECEU_OPERATION_CLIENT_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    get_liste_operation_client(spd1, spd2, rid_client, rid_boutique, rlib) {
        var lien = this.wsurl() + '/operation_client/liste_operation?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_client=' + rid_client + '&rid_boutique=' + rid_boutique + '&rlib=' + rlib + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_operation_client(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/operation_client/add';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_inventaire(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/articles/enreg_inventaire?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_liste_paye(spd1, spd2, ridBoutiq, rmatrPers, rstatutPaye, format) {
        var lien = this.wsurl() + '/etats/generer_liste_paye?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatrPers=' + rmatrPers + '&rstatutPaye=' + rstatutPaye + '&ridBoutiq=' + ridBoutiq + '&ridPaye=Tous' + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "LISTE_PAYE_" + moment(new Date()).format('DDMMYYYYhhmmss') + "." + format);
                a.click();
            }
            );
        });
    }

    generer_edition_bulletin(spd1, spd2, ridBoutiq, rmatrPers, rstatutPaye, ridPaye) {
        var lien = this.wsurl() + '/etats/generer_bulletin_paye?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatrPers=' + rmatrPers + '&rstatutPaye=' + rstatutPaye + '&ridBoutiq=' + ridBoutiq + '&ridPaye=' + ridPaye + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BULLETIN_PAYE_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    get_liste_paye(spd1, spd2, ridBoutiq, rmatrPers, rstatutPaye) {
        var lien = this.wsurl() + '/etats/liste_paye?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatrPers=' + rmatrPers + '&rstatutPaye=' + rstatutPaye + '&ridBoutiq=' + ridBoutiq + '&ridPaye=Tous' + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_paye(mois, annee, id_boutiq) {
        var methode = 'POST';
        var lien = this.wsurl() + '/paye/generer_paye?mois=' + mois + '&annee=' + annee + '&id_boutiq=' + id_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    update_paye(data) {
        var methode = 'PUT';
        var lien = this.wsurl() + '/paye/update?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    update_paye_batch(data) {
        var methode = 'PUT';
        var lien = this.wsurl() + '/paye/update_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_paye_batch(data) {
        var methode = 'DELETE';
        var lien = this.wsurl() + '/paye/delete_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_paye(data) {
        var methode = 'DELETE';
        var lien = this.wsurl() + '/paye/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_bon(spd1, spd2, rmatr_pers, retat_bon) {
        var lien = this.wsurl() + '/bon/liste_bon?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatr_pers=' + rmatr_pers + '&retat_bon=' + retat_bon + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_bon(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idBon === '0') {
            lien = this.wsurl() + '/bon/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/bon/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    delete_bon(data) {
        var lien = this.wsurl() + '/bon/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_accorder(rid_prime) {
        var lien = this.wsurl() + '/accorder/liste_accorder?rid_prime=' + rid_prime + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_accorder(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idAccorder === '0') {
            lien = this.wsurl() + '/accorder/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/accorder/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_accorder(data) {
        var lien = this.wsurl() + '/accorder/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_prime() {
        var lien = this.wsurl() + '/primes/liste_prime?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    enreg_prime(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idPrime === '0') {
            lien = this.wsurl() + '/primes/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/primes/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_prime(data) {
        var lien = this.wsurl() + '/primes/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    mv_article(idSrce, idDest) {
        var methode = 'POST';
        var lien = this.wsurl() + '/articles/mouvement?idSrce=' + idSrce + '&idDest=' + idDest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_article(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idArticle === '0') {
            lien = this.wsurl() + '/articles/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/articles/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_article(data) {
        var lien = this.wsurl() + '/articles/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                alert(error)
                return error
            })
    }





    enreg_sous_type_article(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idStype === '0') {
            lien = this.wsurl() + '/sous_type_article/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/sous_type_article/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_sous_type_article(data) {
        var lien = this.wsurl() + '/sous_type_article/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_sous_type_article(rtype) {
        var lien = this.wsurl() + '/sous_type_article/liste_sous_type_article?rtype=' + rtype + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_type_article(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idType === '0') {
            lien = this.wsurl() + '/type_article/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/type_article/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    delete_type_article(data) {
        var lien = this.wsurl() + '/type_article/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_type_article() {
        var lien = this.wsurl() + '/type_article/liste_type_article?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_personnel() {
        var lien = this.wsurl() + '/personnel/liste_personnel?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_personnel(data) {
        var methode = 'POST';
        var lien = '';
        if (data.matrPers === '0') {
            lien = this.wsurl() + '/personnel/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/personnel/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_personnel(data) {
        var lien = this.wsurl() + '/personnel/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_seuil_article() {
        var lien = this.wsurl() + '/seuil_article/liste_seuil_article?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_seuil_article(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idSeuil === '0') {
            lien = this.wsurl() + '/seuil_article/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/seuil_article/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_seuil_article(data) {
        var lien = this.wsurl() + '/seuil_article/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_entree_fond(spd1, spd2, rlib_ef, rid_boutiq_srce, rid_boutiq_dest) {
        var lien = this.wsurl() + '/entree_fond/liste_rentree_fond?pd1=' + spd1 + '&pd2=' + spd2 + '&rlib_ef=' + rlib_ef + '&rid_boutiq_srce=' + rid_boutiq_srce + '&rid_boutiq_dest=' + rid_boutiq_dest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    valider_entree_fond(data) {
        var methode = 'PUT';
        var lien = this.wsurl() + '/entree_fond/valider';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_entree_fond(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idEf === '0') {
            lien = this.wsurl() + '/entree_fond/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/entree_fond/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_entree_fond(data) {
        var lien = this.wsurl() + '/entree_fond/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    getListe_valeur(codeParam) {
        var lien = this.wsurl() + '/val_selection/liste_valeur?codeParam=' + codeParam + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    enreg_etabl(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idEtabl === 0) {
            lien = this.wsurl() + '/etablissement/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/etablissement/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    getListe_param() {
        var lien = this.wsurl() + '/params/liste_param?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    enreg_param(data) {
        var methode = 'PUT';
        var lien = this.wsurl() + '/params/update';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }



    enreg_regler_cmde(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idrglCmde === '0') {
            lien = this.wsurl() + '/regler_cmde/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/regler_cmde/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_regler_cmde(data) {
        var lien = this.wsurl() + '/regler_cmde/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_regler_cmde(spd1, spd2, rid_four, rid_cmde, rnum_cmde, rid_boutiq) {
        var lien = this.wsurl() + '/regler_cmde/liste_regler_cmde?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_four=' + rid_four + '&rid_cmde=' + rid_cmde + '&rnum_cmde=' + rnum_cmde + '&rid_boutiq=' + rid_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    enreg_client(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idCli === '0') {
            lien = this.wsurl() + '/client/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/client/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    mv_client(idSrce, idDest) {
        var methode = 'POST';
        var lien = this.wsurl() + '/client/mouvement?idSrce=' + idSrce + '&idDest=' + idDest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_client(data) {
        var lien = this.wsurl() + '/client/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_trace_by_ref(refTrace, cibleTrace) {
        var lien = this.wsurl() + '/traces/liste_trace_by_ref?rref_trace=' + refTrace + '&rcible_trace=' + cibleTrace + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_user(data) {
        var lien = this.wsurl() + '/users/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_user(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idUser === '0') {
            lien = this.wsurl() + '/users/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/users/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    get_liste_users() {
        var lien = this.wsurl() + '/users/liste_users?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_edition_bs(idTrs) {
        var lien = this.wsurl() + '/etats/generer_bs?idTrs=' + idTrs + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BS_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    delete_ltrs(data) {
        var lien = this.wsurl() + '/ligne_trs/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_ltrs(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idTrs === '0') {
            lien = this.wsurl() + '/ligne_trs/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/ligne_trs/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ltrs_batch(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/ligne_trs/add_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_ltrs(idTrs) {
        var lien = this.wsurl() + '/ligne_trs/get_ltrs_by_trs?idTrs=' + idTrs + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_trs(spd1, spd2, rid_boutiq_srce, rid_boutiq_dest, retat_trs, rapprob_trs, rtype_trs, rlivrTrs, rnum_facture) {
        var lien = this.wsurl() + '/transfert_stock/liste_vtransfert_stock?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutiq_srce=' + rid_boutiq_srce + '&rid_boutiq_dest=' + rid_boutiq_dest + '&retat_trs=' + retat_trs + '&rapprob_trs=' + rapprob_trs + '&rtype_trs=' + rtype_trs + '&rlivrTrs=' + rlivrTrs + '&rnum_facture=' + rnum_facture + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_trs(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idTrs === '0') {
            lien = this.wsurl() + '/transfert_stock/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/transfert_stock/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_trs(data) {
        var lien = this.wsurl() + '/transfert_stock/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_reap(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idReap == '0') {
            lien = this.wsurl() + '/reap/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/reap/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_reap(data) {
        var lien = this.wsurl() + '/reap/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    liste_reap_usine(spd1, spd2, rnum_cmde, rid_boutiq, rid_article) {
        var lien = this.wsurl() + '/reap/liste_reap_usine?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_article=' + rid_article + '&rid_boutiq=' + rid_boutiq + '&rnum_cmde=' + rnum_cmde + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_reap(spd1, spd2, rnum_cmde, rid_boutiq, rid_article) {
        var lien = this.wsurl() + '/reap/liste_reap?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_article=' + rid_article + '&rid_boutiq=' + rid_boutiq + '&rnum_cmde=' + rnum_cmde + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_lcmde_for_reap() {
        var lien = this.wsurl() + '/ligne_cmde/get_liste_lcmde_for_reap?&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_fournisseur(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idFour === '0') {
            lien = this.wsurl() + '/fournisseur/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/fournisseur/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_fournisseur(data) {
        var lien = this.wsurl() + '/fournisseur/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    getListe_fournisseur() {
        var lien = this.wsurl() + '/fournisseur/liste_fournisseur?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_cmde(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCmde == '0') {
            lien = this.wsurl() + '/commande/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/commande/update?idCmde=' + data.idCmde;
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_cmde(data) {
        var lien = this.wsurl() + '/commande/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_lcmde(data) {
        var lien = this.wsurl() + '/ligne_cmde/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }
    enreg_lcmde(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idLcmde == '0') {
            lien = this.wsurl() + '/ligne_cmde/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/ligne_cmde/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_lcmde_batch(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/ligne_cmde/add_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_edition_bc(idCmde) {
        var lien = this.wsurl() + '/etats/generer_bc?idCmde=' + idCmde + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BC_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    get_liste_lcmde(idCmde) {
        var lien = this.wsurl() + '/ligne_cmde/get_liste_lcmde?idCmde=' + idCmde + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_cmde_by_criteria(spd1, spd2, rnumcmde, rboutique, rstatut_cmde, rid_four, rtype_cmde, rtype_four, rcible_date) {
        var lien = this.wsurl() + '/commande/get_liste_cmde_by_criteria?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_four=' + rid_four + '&ridboutiq=' + rboutique + '&rnumcmde=' + rnumcmde + '&rstatut_cmde=' + rstatut_cmde + '&rtype_cmde=' + rtype_cmde + '&rtype_four=' + rtype_four + '&rcible_date=' + rcible_date + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_regler_sortie(spd1, spd2, rid_client, rid_sortie, rnum_facture, rid_boutiq) {
        var lien = this.wsurl() + '/regler_sortie/liste_regler_sortie?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_client=' + rid_client + '&rid_sortie=' + rid_sortie + '&rnum_facture=' + rnum_facture + '&rid_boutiq=' + rid_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_regl_sortie_batch(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/regler_sortie/add_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_regl_sortie(data) {
        var methode = 'POST';
        var lien = '';
        //alert(JSON.stringify(data));
        if (data.idrglSortie == '0') {
            lien = this.wsurl() + '/regler_sortie/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
            data.idUserRegl = this.state.userconnected.idUser;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/regler_sortie/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    restaure_sortie(data) {
        var lien = this.wsurl() + '/sortie/restaure';
        var methode = 'POST';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    delete_regl_sortie(data) {
        var lien = this.wsurl() + '/regler_sortie/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    generer_edition_facture(idSortie, type_facture, format) {
        var lien = this.wsurl() + '/etats/generer_facture?idSortie=' + idSortie + '&type_facture=' + type_facture + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        printJS({ printable: lien, type: 'pdf', showModal: true });
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "FACTURE_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    generer_edition_bl(numBl) {
        var lien = this.wsurl() + '/etats/generer_bl?numBl=' + numBl + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "BL_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    generer_edition_recu(idrglSortie) {
        var lien = this.wsurl() + '/etats/generer_recu?idrglSortie=' + idrglSortie + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "RECU_VERSEMENT_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".PDF");
                a.click();
            }
            );
        });
    }

    get_liste_livraison_lsortie_by_bl(numBl) {
        var lien = this.wsurl() + '/livraison_lsortie/liste_livraison_lsortie_by_bl?numBl=' + numBl + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                //alert('nok')
            }
        })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_livraison_lsortie_by_sortie(idSortie) {
        var lien = this.wsurl() + '/livraison_lsortie/liste_livraison_lsortie_by_sortie?idSortie=' + idSortie + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    delete_livraison_lsortie(data) {
        var lien = this.wsurl() + '/livraison_lsortie/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_livraison_lsortie_batch(data) {
        var lien = this.wsurl() + '/livraison_lsortie/add_batch?iduser=' + this.state.userconnected.idUser;
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }


    enreg_livraison_lsortie_batch_2(data) {
        var lien = this.wsurl() + '/livraison_lsortie/add_batch_2?iduser=' + this.state.userconnected.idUser;
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_livraison_sortie(data) {
        var lien = this.wsurl() + '/livraison_sortie/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_livraison_sortie(data) {
        var methode = 'POST';
        var lien = '';
        if (data.numBl == '0') {
            lien = this.wsurl() + '/livraison_sortie/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/livraison_sortie/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    get_liste_livraison_sortie_by_sortie(idSortie) {
        var lien = this.wsurl() + '/livraison_sortie/liste_livraison_sortie_by_sortie?idSortie=' + idSortie + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }







    delete_lsortie(data) {
        var lien = this.wsurl() + '/ligne_sortie/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_and_pay_lsortie(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/ligne_sortie/enreg_and_pay?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    enreg_lsortie(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idLsortie == '0') {
            lien = this.wsurl() + '/ligne_sortie/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/ligne_sortie/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_lsortie_batch(data) {
        var lien = this.wsurl() + '/ligne_sortie/add_batch?iduser=' + this.state.userconnected.idUser;
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getListe_client(rfiltre) {
        var lien = this.wsurl() + '/client/liste_client?rfiltre=' + rfiltre + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    getliste_client_light(rfiltre) {
        var lien = this.wsurl() + '/client/liste_client_light?rfiltre=' + rfiltre + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_dashboard(rid_boutiq, rannee) {
        var lien = this.wsurl() + '/etats/get_dashboard?rid_boutiq=' + rid_boutiq + '&rannee=' + rannee + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_dashboard(rid_boutiq, rannee) {
        var lien = this.wsurl() + '/etats/get_dashboard?rid_boutiq=' + rid_boutiq + '&rannee=' + rannee + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getListe_codif(type_codif) {
        var lien = this.wsurl() + '/codification/liste_codification?rtype=' + type_codif + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_codif(data) {
        var lien = this.wsurl() + '/codification/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_codif(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCodif == '0') {
            lien = this.wsurl() + '/codification/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/codification/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_boutique(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idBoutiq === '0') {
            lien = this.wsurl() + '/boutique/add';
            if (this.state.userconnected.idEtabl.codeEtabl != 'OPTION') {
                data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
            }
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/boutique/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_boutique(data) {
        var lien = this.wsurl() + '/boutique/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getListe_etabl() {
        var lien = this.wsurl() + '/etablissement/liste_etablissement?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getListe_boutique() {
        var lien = this.wsurl() + '/boutique/liste_boutique?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    getListe_boutique_gerer() {
        var lien = this.wsurl() + '/boutique/liste_boutique_gerer?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Gestion des ventes
    enreg_sortie(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCodif == '0') {
            lien = this.wsurl() + '/sortie/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/sortie/update';
        }
        data.iduser = this.state.userconnected.idUser;
        data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_sortie_by_criteria(spd1, spd2, rnum_facture, rboutique, rstatut_reglement, rstatut_livraison, rid_client, rid_parent_assure, rid_assure, rlivreur, typeSortie, rtype_client, rtag) {
        var lien = this.wsurl() + '/sortie/get_liste_sortie_by_criteria?pd1=' + spd1 + '&pd2=' + spd2 + '&rnumfacture=' + rnum_facture + '&ridboutiq=' + rboutique + '&rstatut_reglement=' + rstatut_reglement + '&rstatut_livraison=' + rstatut_livraison + '&rid_client=' + rid_client + '&rid_parent_assure=' + rid_parent_assure + '&rid_assure=' + rid_assure + '&rlivreur=' + rlivreur + '&rtype_sortie=' + typeSortie + '&rtype_client=' + rtype_client + '&rtag_sortie=' + rtag + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_sortie(data) {
        var lien = this.wsurl() + '/sortie/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    delete_sortie_bloc(serveur) {
        var methode = 'DELETE';
        var lien = this.state.wsurl_remote + '/sortie/delete_bloc?iduser=' + this.state.userconnected.idUser;
        if (serveur == 'Local') {
            lien = this.state.wsurl_local + '/sortie/delete_bloc?iduser=' + this.state.userconnected.idUser;
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_corbeille_lsortie(idSortie) {
        var lien = this.wsurl() + '/ligne_sortie/get_liste_corbeille_lsortie?idSortie=' + idSortie + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_lsortie(idSortie) {
        var lien = this.wsurl() + '/ligne_sortie/get_liste_lsortie?idSortie=' + idSortie + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_inventaire(rlib, rcb, rtype, rstype, rid_boutiq) {
        var lien = this.wsurl() + '/article/get_inventaire?rlib=' + rlib + '&rcb=' + rcb + '&rtype=' + rtype + '&rstype=' + rstype + '&ridboutiq=' + rid_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_inventaire_mins(rfiltre, rid_boutiq) {
        var lien = this.wsurl() + '/article/get_inventaire_mins?rfiltre=' + rfiltre + '&ridboutiq=' + rid_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_inventaire_mins2(rfiltre, rid_boutiq, ridType, ridSType) {
        var lien = this.wsurl() + '/article/get_inventaire_mins2?rfiltre=' + rfiltre + '&ridboutiq=' + rid_boutiq + '&ridType=' + ridType + '&ridSType=' + ridSType + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getliste_article_by_stype(rstype) {
        var lien = this.wsurl() + '/article/liste_article_par_stype?rstype=' + rstype + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_cprod_en_cours() {
        var lien = this.wsurl() + '/commandeprod/liste_cprod_en_cours?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_cprod(spd1, spd2, rid_boutique, rstatut) {
        var lien = this.wsurl() + '/commandeprod/liste_cprod?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_boutique=' + rid_boutique + '&rstatut=' + rstatut + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_cprod(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idCprod === '0') {
            lien = this.wsurl() + '/commandeprod/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/commandeprod/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_cprod(data) {
        var lien = this.wsurl() + '/commandeprod/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_ligne_cprod(data) {
        var lien = this.wsurl() + '/ligne_cprod/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_cprod(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idLcprod === '0') {
            lien = this.wsurl() + '/ligne_cprod/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/ligne_cprod/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_cprod_batch(data) {
        var methode = 'POST';
        var lien = this.wsurl() + '/ligne_cprod/add_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_ligne_cprod(idCprod) {
        var lien = this.wsurl() + '/ligne_cprod/get_lignes_by_cprod?idCprod=' + idCprod + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


}