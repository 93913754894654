import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Cleave from 'cleave.js/react';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Numeral from 'numeral'; import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import './styles.css';

export class FormReglCmde extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            idCmde: null,
            rnum_cmde: '',
            idrglCmde: '',
            liste_data: [],
            lboutique_rech: [],
            ridBoutiq: null,
            liste_cmde_for_reglement: [],
            liste_four: [],
            selectedData: null,
            montant_total: '0',
            modeRgl: { label: 'Espèce', value: 'Espèce' },
            refRgl: '',
            daterglCmde: new Date(),
            mtrglCmde: 0,
            liste_mode_reglement: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' },
                { label: 'Compte', value: 'Compte' }
            ],
            msg: '',
            modif: false,
            visible: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.xulaservice = new Xulaservice();
    }

    cmdeItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-5">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-5">
                            <label>{option.nomFour}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label style={{ fontWeight: 'bold', color: 'red' }}>{option.mt_restant_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.xulaservice.getListe_fournisseur().then(data => {
            this.setState({ liste_four: data });
        });
        this.xulaservice.get_liste_cmde_by_criteria('Tous', 'Tous', 'Tous', 'Tous', 'Non regle', 'Tous', 'Tous', 'Tous', 'DATE_CMDE').then(data => {
            this.setState({ liste_cmde_for_reglement: data });
        });
        this.get_liste_data();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_boutique() {
        let lboutique_rech = [{ value: 'Tous', label: 'Boutique' }];
        this.xulaservice.getListe_boutique().then(data => {
            if (data !== null && data !== undefined) {
                let lboutique = data.map(x => {
                    return { value: x.idBoutiq, label: x.nomBoutiq }
                });
                if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                    lboutique_rech = lboutique_rech.concat(lboutique);
                } else {
                    let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                    if (lboutique.length > 0) {
                        let liste = lboutique.filter(function (el) {
                            return el.value === id;
                        });
                        lboutique_rech = lboutique_rech.concat(liste);
                    }
                }
            }
            this.setState({ lboutique_rech: lboutique_rech });
        });
    }

    get_liste_data() {
        let rid_cmde = 'Tous';
        let rid_four = 'Tous';
        let rid_boutiq = 'Tous';
        this.setState({ showindicator: true });
        let rnum = 'Tous';
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridBoutiq != undefined && this.state.ridBoutiq != null) {
            rid_boutiq = this.state.ridBoutiq.value;
        }
        if (this.state.ridFour != undefined && this.state.ridFour != null) {
            rid_four = this.state.ridFour.value;
        }
        if (this.state.rnum_cmde != undefined && this.state.rnum_cmde != null && this.state.rnum_cmde != '') {
            rnum = this.state.rnum_cmde;
        }
        this.xulaservice.get_liste_regler_cmde(spd1, spd2, rid_four, rid_cmde, rnum, rid_boutiq).then(data => this.setState({ liste_data: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.mtrglCmde
                }, initialValue);
                this.setState({ montant_total: this.formaterMontant(tt) });
            }
        }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_data();
            this.componentDidMount();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false });
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ idrglCmde: data.idrglCmde });
            this.setState({ daterglCmde: data.daterglCmde });
            this.setState({ mtrglCmde: data.mtrglCmde });
            this.setState({ modif: true });
            this.setState({ refRgl: data.refRgl });
            this.setState({ modeRgl: { value: data.modeRgl, label: data.modeRgl } });
            this.setState({ selectedData: data });
        }
    }

    annule_data() {
        this.setState({ refRgl: '' });
        this.setState({ daterglCmde: new Date() });
        this.setState({ modif: false });
        this.setState({ mtrglCmde: 0 });
        this.forceUpdate();
    }

    enreg_data() {
        if (this.state.mtrglCmde === '' || this.state.mtrglCmde === undefined || this.state.mtrglCmde === 0 || this.state.daterglCmde === '' || this.state.daterglCmde === undefined || this.state.daterglCmde === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                if (this.state.idCmde === null || this.state.idCmde === undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir la commande à regler' });
                    return;
                }
                if (this.state.idCmde.mt_restant < parseFloat(this.state.mtrglCmde)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                    return;
                }
                let date = moment(this.state.daterglCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                if (this.state.userconnected.profilUser != 'PDG') {
                    date = moment(new Date()).format('DD/MM/YYYY');
                }
                let data = { idrglCmde: '0', refRgl: this.state.refRgl, mtrglCmde: this.state.mtrglCmde, modeRgl: this.state.modeRgl.value, daterglCmde: date, idCmde: { idCmde: this.state.idCmde.value } };
                this.xulaservice.enreg_regler_cmde(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                if (this.state.selectedData.mtrglCmde < parseFloat(this.state.mtrglCmde)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi ne peut etre supérieur à celui déja enregistré' });
                    return;
                }
                let date = moment(this.state.daterglCmde, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = this.state.selectedData;
                data.daterglCmde = date;
                data.mtrglCmde = this.state.mtrglCmde;
                data.modeRgl = this.state.modeRgl.value;
                data.refRgl = this.state.refRgl;
                this.xulaservice.enreg_regler_cmde(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {

        let disabled = false;
        if (rowData.idCmde.typeCmde === 'Retour') {
            disabled = true;
        }
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.edit_data(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disabled} onClick={(e) => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.xulaservice.delete_regler_cmde(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: 'la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.enreg_data();
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let ptotal = <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red', marginRight: 10 }}> {this.state.montant_total} </label>

        let lfournisseur_rech = [{ value: 'Tous', label: 'Fournisseur', typeFour: 'NA', tvaFour: 0 }];

        if (this.state.liste_four != null && this.state.liste_four != undefined) {
            let lfournisseur = this.state.liste_four.map(x => {
                return { value: x.idFour, label: x.nomFour, typeFour: x.typeFour, tvaFour: x.tvaFour }
            });
            lfournisseur_rech = lfournisseur_rech.concat(lfournisseur);
        }

        let lcmde = null;
        if (this.state.liste_cmde_for_reglement != null && this.state.liste_cmde_for_reglement != undefined) {
            lcmde = this.state.liste_cmde_for_reglement.map(x => {
                return { value: x.idCmde, label: x.numCmde, nomFour: x.idFour.nomFour, mt_restant: x.mt_restant, mt_restant_format: x.mt_restant_format, label_rech: x.numCmde + ' - ' + x.idFour.nomFour }
            });
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomCli">Date *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar disabled={this.state.userconnected.profilUser != 'PDG'} value={this.state.daterglCmde} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglCmde: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Commande *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lcmde} placeholder="Sélectionner" value={this.state.idCmde} panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' itemTemplate={this.cmdeItemTemplate} filterMatchMode='contains'
                                        onChange={(e) => {
                                            this.setState({ idCmde: e.value }, () => {
                                                this.setState({ mtrglCmde: this.state.idCmde.mt_restant });
                                            })
                                        }
                                        } autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Montant *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Cleave value={this.state.mtrglCmde} onKeyDown={this.handleKeyPress} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtrglCmde: e.target.rawValue })} className="p-inputtext" />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Mode reglement </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ modeRgl: e.value }) }} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="adrCli">Référence reglement</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="adrCli" value={this.state.refRgl} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                </div>


                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_data()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_data()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <InputText value={this.state.rnum_cmde} placeholder="N° BC" onChange={(e) => this.setState({ rnum_cmde: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={lfournisseur_rech} placeholder="Fournisseur" value={this.state.ridFour} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridFour: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-6 p-md-2">
                                            <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_data}
                                responsive={true} selectionMode="single" paginatorPosition="top" paginator={true} rows={10}
                                rowsPerPageOptions={[10, 20, 50, 100, 200]}
                                onRowClick={e => this.setState({ selectedData: e.data })} paginatorRight={ptotal}
                                 selection={this.state.selectedData}>
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="daterglCmde" header="Date regl." style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtrglCmde)}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idCmde.numCmde"  body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.idCmde.numCmde}</span>
                                        <br/>
                                        <span style={{ color: '#000000' }}>{rowData.idCmde.dateCmde}</span>
                                    </div>
                                }} header="N° BC" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idCmde.idFour.nomFour" header="Fournisseur" style={{ width: '18%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idCmde.idFour.typeFour" header="Type" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="modeRgl" header="Mode" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="refRgl" header="Référence" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idCmde.idBoutiq.nomBoutiq" header="Boutique" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}