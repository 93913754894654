import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment, { utc } from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import Numeral from 'numeral';
import Cleave from 'cleave.js/react';
import { FormSituation_client } from './FormSituation_client';
import { FormHistoriqueArticle } from './FormHistoriqueArticle';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FormOptArticle } from './FormOptArticle';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { RadioButton } from 'primereact/radiobutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { InputTextarea } from 'primereact/inputtextarea';
import WindowedSelect from "react-windowed-select";
import { ContextMenu } from 'primereact/contextmenu';

export class FormVente extends Component {
    constructor() {
        super();
        this.state = {
            idSortie: 0,
            tvaSortie: 0,
            libArticle: 'Article',
            numFactureSortie: '',
            dateSortie: new Date(),
            cmtSortie: '',
            paSortie: 0,
            liste_pers: [],
            matrLivreur: null,
            idTable: null,
            idAssSortie: { value: '-1', label: 'Non applicable', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' },
            ridParent_assure: null,
            ridAssure: null,
            refSortie: '',
            libSortie: '',
            nomInteresseSortie: 'COMPTOIR',
            remiseSortie: 0,
            modeRgl: { value: 'Espèce', label: 'Espèce' },
            montant_interesse_format: '0',
            total_restant: '0',
            mtapayer_format: '0',
            remise_total_format: '0',
            mtTVA: '0',
            montant_brut_format: '0',
            total_facture: '0',
            idLsortie: 0,
            qteLsortie: 1,
            qteLivrLsortie: 1,
            puLsortie: 0,
            num_ligne: 0,
            qte_stock: 0,
            receveurLsortie: '',
            typeSortie: 'Facture',
            dateBl: new Date(),
            receveurBl: '',
            pd1: new Date(),
            pd2: new Date(),
            delaiRglSortie: new Date(),
            valideSortie: new Date(),
            delaiLivrSortie: new Date(),
            infoClient: null,
            rnum_facture: '',
            statutLivraison: null,
            statutReglement: null,
            idArticle: null,
            idArticle_opt: null,
            lprod_opt: [],
            idArt: null,
            idCli: null,
            nomSelectedClient: '',
            ridClient: null,
            liste_client: [],
            liste_parent_assure: [],
            liste_assure_rech: [],
            liste_assure_vente: [{ value: '-1', label: 'Non applicable', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' }],
            liste_generale_assure: [],
            liste_generale_assure_rech: [],
            idBoutiq: null,
            ridBoutiq: null,
            liste_sortie: [],
            liste_lsortie: [],
            liste_boutique: [],
            lparent_assure_rech: [],
            liste_article: [],
            lparent_assure: [],
            liste_article_format: [],
            liste_ligne: [],
            liste_livraison_lsortie: [],
            liste_livraison_sortie: [],
            liste_ligne_validation: [],
            liste_regler_sortie: [],
            liste_regler_sortie_bloc: [],
            liste_sortie_for_reglement_selecteds: [],
            liste_sortie_for_reglement: [],
            liste_trace: [],
            daterglSortie: new Date(),
            total_reglement_format: '0',
            mtrglSortie: 0,
            lprod: [],
            lclient: [],
            lclient_rech: [],
            lboutique_rech: [],
            lboutique: [],
            idCli_situation: null,
            selectedSortie: null,
            selectedReglSortie: null,
            selectedLsortie: null,
            selectedLivraisonLsortie: null,
            selectedLivraisonSortie: null,
            selectedLigne: null,
            selectedLigne_validation: null,
            msg: '',
            idSortie_impression: null,
            modif: false,
            modif_lsortie: false,
            modif_bl: false,
            modif_regl_sortie: false,
            visible: false,
            visible_situation_client: false,
            visible_dlg_historique: false,
            visible_lsortie: false,
            visible_new_sortie_dlg: false,
            visible_dlg_reglSortie: false,
            visible_dlg_reglSortie_bloc: false,
            visible_dlg_sortie: false,
            visible_dlg_lsortie: false,
            visible_dlg_livraison: false,
            visible_dlg_Bl: false,
            visible_dlg_article: false,
            visible_dlg_assure: false,
            showindicator: false,
            disable_new_sortie: true,
            disable_add_prod: true,
            disable_val_sortie: true,
            disable_prod: true,
            mode_new_facture: false,
            actif_vente_proforma: false,
            visible_dlg_proforma: false,
            visible_edition_facture: false,
            visible_conf_modif_cli: false,
            mode_impression: 'A4',
            mode_remise: { label: 'Remise globale en montant', value: 'MT' },
            modalitePaySortie: '',
            garantieSortie: '',
            refRglSortie: '',
            tagSortie: 'Normal',
            mtpaye: 0,
            mtapayer: 0,
            val_remise: 0,
            seuilCreditCli: 0,
            liste_corbeille_lsortie: [],
            liste_corbeille_sortie: [],
            total_facture_corbeille: '0',
            selectedCorbeilleSortie: null,
            visible_dlg_corbeille_sortie: false,

            liste_statut_livraison: [
                { label: 'Livraison', value: 'Tous' },
                { label: 'Livré', value: 'LIVRE' },
                { label: 'Non livré', value: 'NON LIVRE' }
            ],
            liste_mode_remise: [
                { label: 'Remise globale en montant', value: 'MT' },
                { label: 'Remise globale en pourcentage', value: 'PRC' }
            ],
            liste_statut_reglement: [
                { label: 'Reglement', value: 'Tous' },
                { label: 'Reglé', value: 'Regle' },
                { label: 'Non reglé', value: 'Non regle' },
            ],
            liste_mode_reglement: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' },
                { label: 'Compte', value: 'Compte' },
                { label: 'Orange Money', value: 'Orange Money' },
                { label: 'Momo', value: 'Momo' },
                { label: 'UBA', value: 'UBA' },
                { label: 'EcoBank', value: 'EcoBank' },
                { label: 'Payement marchand', value: 'Payement marchand' },
            ],
            liste_tag_sortie: [
                { label: 'Normal', value: 'Normal' },
                { label: 'Perdu', value: 'Perdu' },
                { label: 'Litige', value: 'Litige' },
            ],
            liste_type_sortie: [
                { label: 'Sortie peremption', value: 'Sortie peremption' },
                { label: 'Sortie defectueu', value: 'Sortie defectueu' },
            ],

            liste_type_cli: [
                { label: 'Type client', value: 'Tous' },
                { label: 'Particulier', value: 'Particulier' },
                { label: 'Default', value: 'Default' },
                { label: 'Client interne', value: 'Client interne' },
                { label: 'Assurance', value: 'Assurance' },
                { label: 'Organisation', value: 'Organisation' },
                { label: 'Administration publique', value: 'Administration publique' }
            ],
            rtypeClient: null,
            remiseLsortie: 0,

            nomAss: '',
            numPoliceAss: '',
            sexeAss: null,
            pchargeAss: 0,
            typeAss: null,
            etatAss: null,
            libAss: null,
            selectedAssure: null,
            matrAss: '',
            remiseAss: 0,
            adrAss: '',
            idAss: '',
            idParentAss: null,
            modif_parent: false,
            remiseParentAss: 0,
            adrParentAss: '',
            numPoliceParentAss: '',
            nomParentAss: '',
            typeParentAss: null,
            sexeParentAss: null,
            selectedParentAssure: null,
            indexAssure: 0,

            lclient_assure: [],
            liste_type_assure: [
                { label: 'Personne', value: 'Personne' },
                { label: 'Société', value: 'Société' }
            ],
            liste_sexe: [
                { label: 'Masculin', value: 'Masculin' },
                { label: 'Feminin', value: 'Feminin' }
            ],
            modif_assure: false,
            selectedSalle: null,
            selectedTable: null,
            selectedStype: null,
            selectedArticle: null,
            liste_sous_type_article: [],
            liste_table: [],
            liste_sortie_reconcilliation: [],
            liste_sortie_reconcilliation_selected: [],
            activeIndex: 0,
            activeIndex_stype: 0,
            liste_salle: [],
            visible_dlg_vente_restau: false,
            visible_dlg_mail: false,
            visible_dlg_reconcilliation: false,
            contenuMsg: '',
            objetMsg: '',
            destMsg: '',
            ccMsg: '',
            code_barre: '',
            typeCli: { value: 'Particulier', label: 'Particulier' },
            nomCli: '',
            adrCli: '',
            telCli: '',
            emailCli: '',
            tvaCli: 0,
            coefLsortie: 1,
            constLsortie: 1,
            descLsortie: '',
            visible_dlg_client: false,
            visible_drp_boutiq_concerne: false,

            visible_dlg_trace: false,
            liste_trace: [],
            montant_paye_format: '0',
            montant_restant_format: '0',
            remiseCli: 0,
            precompteSortie: 0,
            rfiltreClient: 'COMPTOIR',
            rfiltreArticle: '',
            designationLsortie: '',


            liste_paramprix: [],
            liste_article_spec: [],
            visible_dlg_detail: false,
            visible_dlg_descLsortieContent: false,
            modif_detail: false,
            liste_detail: [],
            idDlsortie: '0',
            descDlsortie: '',
            coordonneeDlsortie: '',
            qteDlsortie: 0,
            nbDlsortie: 0,
            poidsArticle: 0,
            puDlsortie: 0,
            total_detail: 0,
            selectedDetail: null,
            idArticleSpec: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

            idLfid: null,
            prcFid: 0,
            numTicketLfid: '',
            refLfid: '',
            liste_fidelisation: [],

        };

        this.menuModel = [
            { label: 'Exporter en CSV', icon: 'pi pi-fw pi-print', command: () => this.dt.exportCSV() },
            { label: 'Réconcillier', icon: 'pi pi-fw pi-refresh', command: () => this.show_dlg_reconcilliation() }
        ];

        this.edit_sortie = this.edit_sortie.bind(this);
        this.conf_delete_sortie = this.conf_delete_sortie.bind(this);

        this.conf_delete_assure = this.conf_delete_assure.bind(this);
        this.conf_delete_parent = this.conf_delete_parent.bind(this);

        this.edit_bl = this.edit_bl.bind(this);
        this.edit_reglSortie = this.edit_reglSortie.bind(this);

        this.edit_lsortie = this.edit_lsortie.bind(this);
        this.conf_delete_lsortie = this.conf_delete_lsortie.bind(this);
        this.conf_delete_bl = this.conf_delete_bl.bind(this);
        this.conf_delete_reglSortie = this.conf_delete_reglSortie.bind(this);

        this.show_new_client_dlg = this.show_new_client_dlg.bind(this);

        this.show_new_sortie_dlg = this.show_new_sortie_dlg.bind(this);
        this.show_lsortie_dlg = this.show_lsortie_dlg.bind(this);

        this.conf_delete_ligne = this.conf_delete_ligne.bind(this);

        this.show_dlg_livraison = this.show_dlg_livraison.bind(this);
        this.show_dlg_assure = this.show_dlg_assure.bind(this);
        this.show_dlg_Bl = this.show_dlg_Bl.bind(this);
        this.show_dlg_reglSortie = this.show_dlg_reglSortie.bind(this);
        this.show_dlg_reglSortie_bloc = this.show_dlg_reglSortie_bloc.bind(this);
        this.show_dlg_corbeille = this.show_dlg_corbeille.bind(this);
        this.montantTemplate = this.montantTemplate.bind(this);
        this.qteLsortieEditor = this.qteLsortieEditor.bind(this);
        this.puLsortieEditor = this.puLsortieEditor.bind(this);
        this.receveurLsortieEditor = this.receveurLsortieEditor.bind(this);
        this.qteLivrLsortieEditor = this.qteLivrLsortieEditor.bind(this);
        this.remiseLsortieEditor = this.remiseLsortieEditor.bind(this);
        this.qteLivrLsEditor = this.qteLivrLsEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);

        this.show_trace = this.show_trace.bind(this);
        this.conf_print_facture = this.conf_print_facture.bind(this);
        this.conf_send_facture = this.conf_send_facture.bind(this);
        this.conf_print_bl = this.conf_print_bl.bind(this);
        this.conf_print_recu = this.conf_print_recu.bind(this);
        this.myservice = new Xulaservice();

        this.articleItemTemplate = this.articleItemTemplate.bind(this);
        this.statutTemplate = this.statutTemplate.bind(this);
        this.factureTemplate = this.factureTemplate.bind(this);
        this.clientTemplate = this.clientTemplate.bind(this);

        this.show_situation_client_dlg = this.show_situation_client_dlg.bind(this);

        this.remiseLsortieBodyTemplate = this.remiseLsortieBodyTemplate.bind(this);
        this.puLsortieBodyTemplate = this.puLsortieBodyTemplate.bind(this);
        this.montantVenteBodyTemplate = this.montantVenteBodyTemplate.bind(this);
        this.articleLivraisonBodyTemplate = this.articleLivraisonBodyTemplate.bind(this);
        this.montantReglBodyTemplate = this.montantReglBodyTemplate.bind(this);
        this.vente_proforma = this.vente_proforma.bind(this);

        this.show_dlg_article = this.show_dlg_article.bind(this);
        this.show_dlg_historique = this.show_dlg_historique.bind(this);
        this.show_dlg_reconcilliation = this.show_dlg_reconcilliation.bind(this);
        this.show_dlg_detail_lsortie = this.show_dlg_detail_lsortie.bind(this);
        this.delete_ligne_detail = this.delete_ligne_detail.bind(this);
        this.resynchroniser = this.resynchroniser.bind(this);
        this.edit_detail = this.edit_detail.bind(this);

        this.taguer_sortie = this.taguer_sortie.bind(this);

    }


    edit_detail(data) {
        if (data != null && data != undefined) {
            this.setState({ qteDlsortie: data.qteDlsortie });
            this.setState({ nbDlsortie: data.nbDlsortie });
            this.setState({ idArticleSpec: this.myservice.getValue(this.state.liste_article_spec, data.idArticle.idArticle) });
            this.setState({
                puDlsortie: data.puDlsortie,
                idDlsortie: data.idDlsortie,
                descDlsortie: data.descDlsortie,
                coordonneeDlsortie: data.coordonneeDlsortie
            });

            this.setState({ modif_detail: true });
            this.setState({ selectedDetail: data });
        }
    }

    get_liste_paramprix() {
        this.myservice.get_liste_paramprix().then(data => this.setState({ liste_paramprix: data }));
    }

    resultat_detail_lsortie(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.myservice.get_liste_detail_lsortie(this.state.selectedLsortie.idLsortie).then(data_ligne => this.setState({ liste_detail: data_ligne }, () => {
                this.calculer_total_detail();
            }));
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    taguer_sortie(data) {
        this.myservice.enreg_sortie(data).then(rep => this.resultat(rep.code, rep.contenu));
    }

    delete_ligne_detail(data) {
        if (this.state.visible_dlg_lsortie == false) {
            for (var i = this.state.liste_detail.length; i--;) {
                if (data.idArticle.idArticle == this.state.liste_detail[i].idArticle.idArticle) {
                    this.state.liste_detail.splice(i, 1);
                    break;
                }
            }
        } else {
            this.myservice.delete_detail_lsortie(data).then(rep => this.resultat_detail_lsortie(rep.code, rep.contenu));
        }

        this.forceUpdate();
        this.calculer_total_detail();
    }

    get_liste_article_spec() {
        let liste = null;
        if (this.state.liste_article != null && this.state.liste_article != undefined) {
            liste = this.state.liste_article.map(x => {
                return { value: x.idArt, label: x.libArt, label_rech: x.libArt + ' ' + x.cbArt, idStype: x.idstype, poidsArticle: x.poidsArticle, specArticle: x.specArticle, formatArticle: x.formatArticle }
            })
            this.setState({ liste_article_spec: liste });
        }
        /*
        this.myservice.get_liste_article_spec().then(data => {
            if (data != null && data != undefined) {
                liste = data.map(x => {
                    return { value: x.idArticle, label: x.libArticle, label_rech: x.libArticle + ' ' + x.cbArticle, idStype: x.idStype.idStype, poidsArticle: x.poidsArticle, specArticle: x.specArticle, formatArticle: x.formatArticle }
                });
            }
            this.setState({ liste_article_spec: liste });

        });
        */
    }

    show_dlg_reconcilliation() {
        if (this.myservice.get_statut_sync() !== 'En attente') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez utiliser le mode offline pour faire cette opération' });
        } else {
            this.setState({ visible_dlg_reconcilliation: true });
        }
    }

    verif_intermag(liste, qtt, numFacture) {
        let conforme = 'NOK';
        if (liste !== null && liste !== undefined) {
            if (liste.length > 0) {
                let data_find = liste.find(({ idSortieCli }) => idSortieCli === numFacture);
                if (data_find !== null && data_find !== undefined) {
                    if (data_find.qttTrs == qtt) {
                        conforme = 'OK';
                    }
                }
            }
        }
        return conforme;
    }

    reconcillier() {
        let typesortie = this.get_type_sortie();
        this.setState({ showindicator: true, liste_sortie_reconcilliation: [] });
        let rstatut_livraison = 'Tous';
        let rstatut_reglement = 'Tous';
        let rboutique = 'Tous';
        let rid_client = 'Tous';
        let rnum_facture = 'Tous';
        let rlivreur = 'Tous';
        let rid_parent_assure = 'Tous';
        let rid_assure = 'Tous';
        this.setState({ total_facture: '0' });
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq !== undefined && this.state.ridBoutiq !== null) {
            rboutique = this.state.ridBoutiq.value;
        }
        let liste_rec = [];
        this.myservice.get_liste_sortie_serv(spd1, spd2, rnum_facture, rboutique, rstatut_reglement, rstatut_livraison, rid_client, rid_parent_assure, rid_assure, rlivreur, typesortie, 'Tous', 'Local').then(local_data => {
            if (local_data != null && local_data != undefined) {
                if (local_data.length > 0) {
                    let liste_ids = local_data.map(x => {
                        return x.idSortie
                    });
                    let liste_intermag = [];
                    let liste = local_data.filter(function (el) {
                        return el.idBoutiq.idBoutiq !== el.idBoutiqConcerne.idBoutiq;
                    });
                    if (liste !== null && liste !== undefined) {
                        liste_intermag = liste.map(x => {
                            return x.idSortie
                        });
                    }
                    this.myservice.get_liste_sortie_by_ids(liste_ids).then(remote_data => {
                        let l = local_data.length;
                        if (remote_data != null && remote_data != undefined) {
                            if (remote_data.length > 0) {
                                this.myservice.get_liste_trs_by_facture(liste_intermag).then(liste_trs => {
                                    for (var i = local_data.length; i--;) {
                                        let numFacture = local_data[i].numFactureSortie;
                                        let data_find = remote_data.find(({ numFactureSortie }) => numFactureSortie === numFacture);
                                        var data_com = local_data[i];
                                        data_com.conforme_intermag = 'OK';
                                        if (local_data[i].idBoutiq.idBoutiq !== local_data[i].idBoutiqConcerne.idBoutiq) {
                                            data_com.conforme_intermag = this.verif_intermag(liste_trs, local_data[i].qttSortie, local_data[i].numFactureSortie);
                                        }
                                        if (data_find !== null && data_find !== undefined) {
                                            data_com.montant_sortie_remote = data_find.montant_sortie;
                                            data_com.montant_sortie_format_remote = data_find.montant_sortie_format;
                                            data_com.mt_restant_format_remote = data_find.mt_restant_format;
                                            data_com.statutSortie_remote = data_find.statutSortie;
                                            data_com.statutLivraison_remote = data_find.statutLivraison;
                                        } else {
                                            data_com.montant_sortie_remote = 0;
                                            data_com.montant_sortie_format_remote = '';
                                            data_com.mt_restant_format_remote = '';
                                            data_com.statutSortie_remote = '';
                                            data_com.statutLivraison_remote = '';
                                        }
                                        data_com.ordre = l - i;
                                        if (data_com.montant_sortie == data_com.montant_sortie_remote && data_com.mt_restant_format == data_com.mt_restant_format_remote && data_com.statutLivraison == data_com.statutLivraison_remote) {
                                            data_com.conforme = 'Oui';
                                        } else {
                                            data_com.conforme = 'Non';
                                        }
                                        liste_rec.push(data_com);
                                    }
                                    this.setState({ liste_sortie_reconcilliation: liste_rec, showindicator: false });
                                });
                                //let result = local_data.map(a => ({...remote_data.find(p => a.numFactureSortie === p.numFactureSortie || p.numFactureSortie==null), ...a}));
                            } else {
                                for (var i = local_data.length; i--;) {
                                    var data_com = local_data[i];
                                    data_com.montant_sortie_remote = 0;
                                    data_com.montant_sortie_format_remote = '';
                                    data_com.mt_restant_format_remote = '';
                                    data_com.statutSortie_remote = '';
                                    data_com.statutLivraison_remote = '';
                                    data_com.conforme = 'Non'
                                    data_com.conforme_intermag = '';
                                    data_com.ordre = l - i;
                                    liste_rec.push(data_com);
                                }
                                this.setState({ liste_sortie_reconcilliation: liste_rec, showindicator: false });
                            }
                        } else {
                            for (var i = local_data.length; i--;) {
                                var data_com = local_data[i];
                                data_com.montant_sortie_remote = 0;
                                data_com.montant_sortie_format_remote = '';
                                data_com.mt_restant_format_remote = '';
                                data_com.statutSortie_remote = '';
                                data_com.statutLivraison_remote = '';
                                data_com.conforme = 'Non';
                                data_com.conforme_intermag = '';
                                data_com.ordre = l - i;
                                liste_rec.push(data_com);
                            }
                            this.setState({ liste_sortie_reconcilliation: liste_rec, showindicator: false });
                        }
                    });
                }
            }

        });
    }

    resynchroniser() {
        if (this.myservice.get_statut_sync() !== 'En attente') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez utiliser le mode offline pour faire cette opération' });
        } else if (this.state.liste_sortie_reconcilliation_selected == null || this.state.liste_sortie_reconcilliation_selected == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures' });
        } else if (this.state.liste_sortie_reconcilliation_selected.length == 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures' });
        }
        else {
            this.setState({ showindicator: true });
            let idEtabl = this.state.userconnected.idEtabl.idEtabl;
            let data_local_resync = this.state.liste_sortie_reconcilliation_selected.map(x => {
                return { idSortie: x.idSortie, idEtabl: idEtabl, iduserSortie: x.iduserSortie.idUser, serveur: 'LOCAL' }
            });
            let data_remote_resync = this.state.liste_sortie_reconcilliation_selected.map(x => {
                return { idSortie: x.idSortie, idEtabl: idEtabl, iduserSortie: x.iduserSortie.idUser, serveur: 'REMOTE' }
            });
            this.myservice.add_batch_tmp_sortie_supr(data_remote_resync, 'Remote').then(rep_remote => {
                if (rep_remote !== null && rep_remote !== undefined) {
                    this.myservice.delete_sortie_bloc('Remote').then(rep => {
                        if (rep !== null && rep !== undefined) {
                            this.myservice.add_batch_tmp_sortie_supr(data_local_resync, 'Local').then(data => this.resultat(data.code, data.contenu));;
                        }
                    });;
                }
            });;
        }
    }

    conf_send_facture(data) {
        this.setState({ destMsg: data.idCli.emailCli });
        this.setState({ objetMsg: 'Facture N° ' + data.numFactureSortie });
        this.setState({ visible_dlg_mail: true });
    }

    annule_send_facture() {
        this.setState({ contenuMsg: '' });
        this.setState({ destMsg: '' });
        this.setState({ objetMsg: '' });
        this.setState({ visible_dlg_mail: false });
    }

    send_facture() {
        if (this.state.contenuMsg === '' || this.state.objetMsg === '' || this.state.destMsg === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedSortie;
            let msg = { objetMsg: this.state.objetMsg, destMsg: this.state.destMsg, ccMsg: this.state.ccMsg, contenuMsg: this.state.contenuMsg, typeMsg: 'Mail', refMsg: data.idSortie, libRefMsg: data.typeSortie, etatMsg: 'En attente' };
            this.myservice.send_facture(data.idSortie, data.typeSortie, 'A4', msg).then(data => this.resultat(data.code, data.contenu));;
        }
    }

    vente_proforma(data) {
        if (data !== null && data !== undefined) {
            if (data != null && data != undefined) {
                this.setState({ idSortie: data.idSortie });
                this.setState({ cmtSortie: data.cmtSortie });
                this.setState({ dateSortie: new Date() });
                this.setState({ numFactureSortie: data.numFactureSortie });
                this.setState({ valideSortie: data.validiteSortie_format });
                this.setState({ refSortie: data.refSortie });
                this.setState({ tvaSortie: data.tvaSortie });
                this.setState({ remiseSortie: data.remiseSortie });
                this.setState({ paSortie: data.paSortie });
                this.setState({ modif: false });
                this.setState({ actif_vente_proforma: true });
                this.setState({ selectedSortie: data });
                let liste = this.state.liste_lsortie;
                if (liste != null && liste != undefined) {
                    if (liste.length > 0) {
                        this.setState({ liste_ligne: [] }, () => {
                            Array.prototype.insert = function (index) {
                                this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
                            };
                            for (var i = liste.length; i--;) {
                                this.state.liste_ligne.insert(this.state.num_ligne, { idLsortie: liste[i].idLsortie, qteLsortie: liste[i].qteLsortie, puLsortie: liste[i].puLsortie, qteLivrLsortie: liste[i].qteLsortie, remiseLsortie: liste[i].remiseLsortie, receveurLsortie: liste[i].receveurLsortie, montantVente: liste[i].qteLsortie * liste[i].puLsortie, montantRemise: liste[i].qteLsortie * liste[i].remiseLsortie, idArticle: { idArticle: liste[i].idArticle.idArticle, libArticle: liste[i].idArticle.libArticle }, idSortie: { idSortie: this.state.selectedSortie.idSortie, idBoutiq: this.state.userconnected.matrPers.idBoutiq.idBoutiq } });
                            }
                            this.setState({ visible_dlg_proforma: false });
                            if (this.state.liste_client != null && this.state.liste_client != undefined) {
                                let liste_selected_cli = this.state.liste_client.filter(function (el) {
                                    return el.idCli == data.idCli.idCli;
                                });

                                let lclient = liste_selected_cli.map(x => {
                                    return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, typeCli: x.typeCli, label_rech: x.nomCli + ' ' + x.idCli, precompteCli: x.precompteCli }
                                })[0];
                                this.setState({ idCli: lclient }, () => {
                                    this.calculer_total(this.state.liste_ligne);
                                    // this.calculer_restant();
                                });
                            }
                            window.location = '#/gestion/Nouvelle vente';
                        });
                    }
                }
            }
        }
    }

    show_situation_client_dlg() {
        if (this.state.idCli_situation != null && this.state.idCli_situation != undefined) {
            if (this.state.idCli_situation.value != 'Tous') {
                this.setState({ visible_situation_client: true });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Veuillez sélectionner un client' });
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: 'Veuillez sélectionner un client' });
        }
    }

    show_dlg_historique() {
        if (this.state.idArticle !== null && this.state.idArticle !== undefined) {
            let art = this.state.idArticle;
            art.idBoutiq = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
            let param_opt = { idArticle: art, lprod: this.state.lprod };
            this.setState({ idArticle_opt: param_opt }, () => {
                this.setState({ visible_dlg_historique: true });
            });
        } else {
            this.setState({ visible_dlg_historique: true });
        }
    }

    show_dlg_article() {
        if (this.state.idArticle !== null && this.state.idArticle !== undefined) {
            let art = this.state.idArticle;
            art.idBoutiq = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
            let param_opt = { idArticle: art, lprod: [this.state.idArticle] };
            this.setState({ idArticle_opt: param_opt }, () => {
                this.setState({ visible_dlg_article: true });
            });
        } else {
            this.setState({ visible_dlg_article: true });
        }
    }

    get_liste_regler_sortie(spd1, spd2, rid_client, rid_sortie) {
        this.myservice.get_liste_regler_sortie(spd1, spd2, rid_client, rid_sortie, 'Tous').then(data => this.setState({ liste_regler_sortie: data }));
    }

    montantReglBodyTemplate(rowData, column) {
        let val = this.formaterMontant(rowData.mtrglSortie);
        return <div>
            <span style={{ fontWeight: 'bold' }}>{val}</span><br />
        </div>
    }

    remiseLsortieBodyTemplate(rowData, column) {
        let val = this.formaterMontant(rowData.remiseLsortie);
        return <div>
            <span>{val}</span><br />
        </div>
    }

    puLsortieBodyTemplate(rowData, column) {
        let val = this.formaterMontant(rowData.puLsortie);
        return <div>
            <span>{val}</span><br />
        </div>
    }

    montantVenteBodyTemplate(rowData, column) {
        let val = this.formaterMontant(rowData.montantVente);
        return <div>
            <span>{val}</span><br />
        </div>
    }

    articleLivraisonBodyTemplate(rowData, column) {
        let color = 'black';
        if (rowData.qteLivrLs > rowData.qteLivrLs_restant) {
            color = 'red';
        }
        return <div>
            <span style={{ color: color }}>{rowData.idLsortie.idArticle.libArticle}</span><br />
        </div>
    }

    assureTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-5">
                            <label style={{ color: '#000000', fontWeight: 'bold' }}>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.matrAss}</label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label style={{ color: '#F34C1F', fontWeight: 'bold' }}>{option.nomSouscripteur}</label>
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label style={{ color: '#000000', fontWeight: 'bold' }}>{option.pchargeAss}%</label>
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label>{option.remiseCli}%</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <label>{option.label}</label>
                        </div>

                        {option.prix_except > 0 ?
                            <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                                <label>{this.formaterMontant(parseFloat(option.prix_except))}</label>
                            </div>
                            :
                            <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                                <label>{option.prix_ref_vente_format}</label>
                            </div>
                        }

                        <div className="p-col-12 p-md-2" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    clientItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.telCli}</label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label style={{ fontWeight: 'bold' }}>{option.soldeCli_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label>{option.remiseCli}%</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    statutTemplate(rowData, column) {
        let nom_table = rowData.nom_table;
        let typeSortie = rowData.typeSortie;
        let lab = '';
        let cl = '';
        if (rowData.idBoutiq.idBoutiq !== rowData.idBoutiqConcerne.idBoutiq) {
            typeSortie = 'Inter-MAG';
        }
        if (nom_table = 'null / null') {
            nom_table = '';
        }
        return <div>
            <span>{typeSortie}</span><br />
            <span style={{ color: '#000', fontWeight: 'bold' }}>{rowData.statutSortie} {rowData.statutLivraison}</span><br />
            <span>{nom_table}</span>
        </div>
    }

    factureTemplate(rowData, column) {
        return <div>
            <span style={{ color: rowData.tagSortie == 'Normal' ? '#873600' : '#FF0000 ', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span><br />
            <span style={{ color: '#000' }}>{rowData.dateSortie}</span><br />
            <span style={{ color: '#AF6D22' }}>{rowData.typeSortie}</span>
        </div>
    }

    montantTemplate(rowData, column) {
        let livreur = '';
        if (rowData.livreur != null) {
            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Restaurant') {
                livreur = rowData.livreur.nomPers;
            }
        }
        return <div>
            <span style={{ color: '#000', fontWeight: 'bold' }}>{rowData.montant_sortie_format}</span><br />
            <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.mt_restant_format}</span><br />
            <span style={{ color: '#000' }}>{livreur}</span>
        </div>
    }

    clientTemplate(rowData, column) {
        let nom_boutiq_dest = '';
        if (rowData.idBoutiq.idBoutiq !== rowData.idBoutiqConcerne.idBoutiq) {
            nom_boutiq_dest = rowData.idBoutiqConcerne.nomBoutiq;
        }
        return <div>
            <span>{rowData.idCli.nomCli}</span><br />
            {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' ?
                <div >
                    <span style={{ color: '#000000', fontWeight: 'bold' }}>{rowData.nom_souscripteur}</span><br />
                    <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.nom_assure}</span>
                </div>
                :
                <div >
                    <span>{nom_boutiq_dest}</span>
                </div>
            }
        </div>
    }

    qteLivrLsortieEditor(props) {
        return this.inputTextEditor(props, 'qteLivrLsortie');
    }

    qteLivrLsEditor(props) {
        return this.inputTextEditor_livraison(props, 'qteLivrLs');
    }

    remiseLsortieEditor(props) {
        return this.inputTextEditor(props, 'remiseLsortie');
    }

    puLsortieEditor(props) {
        return this.inputTextEditor(props, 'puLsortie');
    }

    qteLsortieEditor(props) {
        return this.inputTextEditor(props, 'qteLsortie');
    }

    receveurLsortieEditor(props) {
        return this.inputTextEditor(props, 'receveurLsortie');
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        //alert(updateds[props.rowIndex][props.field]);
        updateds[props.rowIndex][props.field] = value;

        this.setState({ liste_ligne: updateds }, () => {
            this.calculer_total(updateds);
            if (value !== '' && this.state.modif === true && this.state.visible_dlg_vente_restau === true) {
                let data = updateds[props.rowIndex];
                data.statutSyncLsortie = this.myservice.get_statut_sync();
                this.myservice.enreg_lsortie(data).then(reponse => {
                    if (reponse != null && reponse != undefined) {
                        if (reponse.code === 200) {
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Modifiée avec succès' });
                        } else {
                            this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec modification' });
                        }
                    }
                });
            }
        });
    }

    inputTextEditor(props, field) {
        let disabled = false;
        return <Cleave disabled={disabled} value={props.rowData[field]} options={{ numeral: true, delimiter: ' ' }}
            onChange={(e) => this.onEditorValueChange(props, e.target.rawValue)} className="p-inputtext" />
    }

    onEditorValueChange_livraison(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_livraison_lsortie: updateds });
    }

    inputTextEditor_livraison(props, field) {
        return <InputText keyfilter="pint" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange_livraison(props, e.target.value)} />;
    }

    get_type_sortie() {
        let val = 'Facture';
        if (this.props.match.params.typeSortie != null && this.props.match.params.typeSortie != undefined) {
            if (this.props.match.params.typeSortie === 'Nouvelle vente' || this.props.match.params.typeSortie === 'Restaurant') {
                val = 'Facture';
            } else if (this.props.match.params.typeSortie === 'Nouvelle proforma') {
                val = 'Proforma';
            } else if (this.props.match.params.typeSortie === 'Perte') {
                val = 'Perte';
            } else {
                val = this.props.match.params.typeSortie;
            }
        }
        return val;
    }

    componentDidMount() {
        this.get_liste_boutique();
        this.get_liste_paramprix();
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            this.setState({ rfiltreClient: 'Tous' }, () => {
                this.get_liste_client();
            })
        } else {
            if (this.myservice.wsurl().indexOf(":9077") > 0) {
                this.setState({ rfiltreClient: 'Tous' }, () => {
                    this.get_liste_client();
                });
            } else {
                if (this.state.userconnected.idEtabl.codeEtabl !== 'EDK') {
                    this.setState({ rfiltreClient: 'Tous' }, () => {
                        this.get_liste_client();
                    });
                } else {
                    this.setState({ rfiltreClient: 'COMPTOIR' }, () => {
                        this.get_liste_client();
                    });
                }
            }

        }

        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            this.get_liste_assure();
            this.get_liste_parent_assure();
        }

        if (this.props.match.params.typeSortie != null && this.props.match.params.typeSortie != undefined) {
            if (this.props.match.params.typeSortie === 'Nouvelle vente') {
                this.setState({ typeSortie: 'Facture' });
                this.setState({ mode_new_facture: true });
                this.forceUpdate();
            } else if (this.props.match.params.typeSortie === 'Restaurant') {
                this.setState({ mode_new_facture: true });
                this.setState({ typeSortie: 'Facture' });
                this.get_liste_salle();
                this.get_liste_table();
                this.get_liste_sous_type_article();
                this.get_liste_personnel();
            } else {
                this.setState({ typeSortie: this.props.match.params.typeSortie });
                this.setState({ mode_new_facture: false });
                this.forceUpdate();
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (prevProps.match.params.typeSortie !== this.props.match.params.typeSortie) {
            this.setState({ liste_lsortie: [] });
            this.get_liste_sortie();
            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                this.setState({ rfiltreArticle: 'Rechercher', rfiltreClient: 'Tous' }, () => {
                    this.get_liste_article(this.state.idBoutiq.value);
                    this.get_liste_client();
                })
            } else {
                if (this.myservice.wsurl().indexOf(":9077") > 0) {
                    this.get_liste_article(this.state.idBoutiq.value);
                    this.get_liste_client();
                } else {
                    if (this.state.userconnected.idEtabl.codeEtabl == 'EDK') {
                        this.setState({ rfiltreArticle: 'Tous' }, () => {
                            this.get_liste_article(this.state.idBoutiq.value);
                        });
                    } else {
                        this.setState({ rfiltreArticle: 'Tous', rfiltreClient: 'Tous' }, () => {
                            this.get_liste_article(this.state.idBoutiq.value);
                            this.get_liste_client();
                        });
                    }
                }
            }

            if (this.props.match.params.typeSortie === 'Restaurant') {
                this.get_liste_salle();
                this.get_liste_table();
                this.get_liste_sous_type_article();
            }
        }
    }

    get_liste_personnel() {
        this.myservice.get_liste_personnel().then(data => {
            if (data !== null && data !== undefined) {
                let idBoutiq = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                let lpers = data.filter(function (el) {
                    return el.idBoutiq.idBoutiq === idBoutiq;
                });
                if (lpers !== null && lpers !== undefined) {
                    let liste = lpers.map(x => {
                        return { value: x.matrPers, label: x.nomPers, label_rech: x.matrPers + ' - ' + x.nomPers }
                    });

                    this.setState({ liste_pers: liste }, () => {
                        let x = this.state.userconnected.matrPers;
                        this.setState({ matrLivreur: { value: x.matrPers, label: x.nomPers, label_rech: x.matrPers + ' - ' + x.nomPers } });
                    });
                }
            }
        });
    }

    get_tickets_for_vente() {
        let liste = [];
        this.setState({
            prcFid: 0,
            refLfid: '',
            liste_fidelisation: []
        })
        if (this.state.idCli !== null && this.state.idCli !== undefined && this.state.idArticle !== null && this.state.idArticle !== undefined) {
            this.myservice.get_tickets_for_vente(this.state.idCli.value, this.state.idArticle.value).then(data => {
                if (data != null && data != undefined) {
                    liste = data.map(x => {
                        return { value: x.idTicket, label: x.idTicket, prcFid: x.idLfid.tauxLfid, idLfid: x.idLfid.idLfid }
                    });
                }
                this.setState({ liste_fidelisation: liste });
            });
        }
    }

    search_article() {
        let cb = this.state.code_barre;
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            this.setState({ rfiltreArticle: cb }, () => {
                this.get_liste_article(this.state.idBoutiq.value);
                if (this.state.visible_dlg_lsortie === true) {
                    document.getElementById("new_dp_prod_modif").click();
                } else {
                    document.getElementById("new_dp_prod_add").click();
                }
            })
        } else {
            let art = this.state.lprod.filter(function (el) {
                return el.cbArt === cb;
            })[0];
            if (art !== null && art !== undefined) {
                this.setState({ idArticle: art }, () => {
                    this.setState({ puLsortie: art.prix_ref_vente });
                    this.setState({ qte_stock: art.qte_stock_format });
                    let remise_client = 0;
                    if (this.state.idCli != null && this.state.idCli != undefined) {
                        remise_client = this.state.idCli.remiseCli;
                    }
                    let tt_remise = this.state.idArticle.currentRemise + remise_client;
                    if (this.state.qteLsortie === '' || this.state.qteLsortie === 0) {
                        this.setState({ qteLsortie: 1 }, () => {
                            this.setState({ remiseLsortie: 0.01 * tt_remise * art.prix_ref_vente }, () => {
                                this.add_article('cb');
                            })
                        });
                    } else {
                        this.setState({ remiseLsortie: 0.01 * tt_remise * art.prix_ref_vente }, () => {
                            this.add_article('cb');
                        })
                    }
                });
            }
        }
    }

    handleKeyPress = e => {
        if (e.keyCode === 13 && e.ctrlKey) {
            this.enreg_sortie(this.get_type_sortie());
        } else if (e.key === 'u' && e.ctrlKey) {
            this.annule_sortie();
        } else if (e.key === 'b' && e.ctrlKey) {
            this.enreg_sortie('Proforma');
        } else if (e.key === 'a' && e.ctrlKey) {
            this.add_article();
        } else if (e.key === 'k' && e.ctrlKey) {
            document.getElementById("new_dp_prod").click();
        } else if (e.key === 'j' && e.ctrlKey) {
            window.location = '#/gestion/Proforma';
        } else if (e.key === 'i' && e.ctrlKey) {
            if (this.selectedSortie != null && this.state.selectedSortie != undefined) {
                this.vente_proforma(this.selectedSortie);
            }
        } else if (e.keyCode === 13) {
            this.search_article();
        }
    }

    get_containt(idSalle) {
        if (this.state.liste_table != null && this.state.liste_table != undefined) {
            if (this.state.liste_table.length > 0) {
                let liste = this.state.liste_table.filter(function (el) {
                    return el.salle.idSalle === idSalle;
                });
                if (liste !== null && liste != undefined) {
                    if (liste.length > 0) {
                        return <DataView value={liste} layout='grid'
                            itemTemplate={(data, layout) => {
                                if (data != undefined && data != null) {
                                    let aff_commande = false;
                                    if (data.aff_commande === 'true') {
                                        aff_commande = true;
                                    }

                                    return (
                                        <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
                                            <div className="card card-w-title" style={{ minHeight: '140px', borderRadius: 20, backgroundColor: '#eff2f6', marginBottom: -5 }}>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-5" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#000', fontSize: '16px', fontWeight: 'bold' }}>{data.nom_table}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-7" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#fc7703', fontSize: '16px', fontWeight: 'bold' }}>{data.sortie.numFactureSortie}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#108f10', fontSize: '18px', fontWeight: 'bold' }}>{data.sortie.montant_sortie_format}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#000000', fontSize: '14px' }}>{data.sortie.dateSortie_format}</label>
                                                    </div>
                                                    <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                        <div className="p-grid" style={{ width: '300px' }}>
                                                            {aff_commande === true ? <div className="p-col"><Button label="Vendre" style={{ width: 120, marginLeft: 0, borderRadius: 15, backgroundColor: '#004457' }} icon="pi pi-shopping-cart" onClick={(e) => this.setState({ selectedTable: data, visible_dlg_vente_restau: true })} className="p-button-warning p-button-rounded" /></div> : null}
                                                            {aff_commande === false ? <div className="p-col"><Button label="Editer" style={{ width: 80, marginLeft: -5, borderRadius: 15 }} icon="pi pi-pencil" onClick={(e) => this.setState({ selectedTable: data, visible_dlg_vente_restau: true }, () => {
                                                                this.setState({ showindicator: true });
                                                                this.myservice.get_liste_lsortie(this.state.selectedTable.sortie.idSortie).then(ligne => {
                                                                    if (ligne !== null && ligne !== undefined) {
                                                                        for (var i = ligne.length; i--;) {
                                                                            this.state.liste_ligne.push({ idLsortie: ligne[i].idLsortie, qteLsortie: ligne[i].qteLsortie, puLsortie: ligne[i].puLsortie, qteLivrLsortie: ligne[i].qteLivrLsortie, remiseLsortie: ligne[i].remiseLsortie, receveurLsortie: ligne[i].receveurLsortie, montantVente: ligne[i].qteLsortie * ligne[i].puLsortie, montantRemise: ligne[i].qteLsortie * ligne[i].remiseLsortie, idArticle: { idArticle: ligne[i].idArticle.idArticle, libArticle: ligne[i].idArticle.libArticle }, qte_stock: ligne[i].qteLsortie, idSortie: { idSortie: ligne[i].idSortie.idSortie, typeSortie: ligne[i].idSortie.typeSortie, numFactureSortie: ligne[i].idSortie.numFactureSortie, idCli: ligne[i].idSortie.idCli } });
                                                                        }
                                                                    }
                                                                    let idCli = ligne[0].idSortie.idCli.idCli;
                                                                    let client = this.state.lclient.filter(function (el) {
                                                                        return el.value === idCli;
                                                                    })[0];
                                                                    this.setState({ showindicator: false, modif: true, idCli: client, val_remise: ligne[0].idSortie.remiseSortie, paSortie: ligne[0].idSortie.paSortie, tvaSortie: ligne[0].idSortie.tvaSortie }, () => {
                                                                        this.calculer_total(this.state.liste_ligne);
                                                                    });
                                                                });
                                                            })} className="p-button-warning p-button-rounded" /></div> : null}
                                                            {aff_commande === false ? <div className="p-col"><Button label="Payer" style={{ width: 80, marginLeft: -12, borderRadius: 15 }} icon="fa fa-fw fa-credit-card" onClick={(e) => this.setState({ selectedTable: data }, () => {
                                                                let data = { idrglSortie: '0', refRgl: '', statutSyncRegl: this.myservice.get_statut_sync(), mtrglSortie: this.state.selectedTable.sortie.mt_restant, modeRgl: 'Espèce', daterglSortie: moment(new Date()).format('DD/MM/YYYY'), idSortie: { idSortie: this.state.selectedTable.sortie.idSortie } };
                                                                this.myservice.enreg_regl_sortie(data).then(rep => {
                                                                    if (rep !== null && rep !== undefined) {
                                                                        if (rep.code == 200) {
                                                                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Payement effectué avec succès' });
                                                                            this.get_liste_table();
                                                                        } else {
                                                                            this.growl.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
                                                                        }
                                                                    } else {
                                                                        this.growl.show({ severity: 'error', summary: 'Error', detail: 'Erreur inconnue' });
                                                                    }

                                                                });
                                                            })} className="p-button-success" /></div> : null}
                                                            {aff_commande === false ? <div className="p-col"><Button style={{ width: 80, marginLeft: -12, borderRadius: 15, backgroundColor: 'grey' }} label="Ticket" icon="pi pi-print" onClick={(e) => {
                                                                this.setState({ selectedTable: data }, () => {
                                                                    let idSortie_impression = { idSortie: this.state.selectedTable.sortie.idSortie, typeSortie: 'Facture' };
                                                                    this.conf_print_facture(idSortie_impression, 'Ticket');
                                                                });
                                                            }} className="p-button-info p-button-rounded" /></div> : null}
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                        <label style={{ color: '#1B3F4A', fontSize: '16px', fontWeight: 'bold' }}>{data.sortie.livreur.nomPers}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        null
                                    );
                                }

                            }}
                        />
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }

    }

    get_content_article(idStype) {
        if (this.state.lprod != null && this.state.lprod != undefined) {
            if (this.state.lprod.length > 0) {
                let liste = this.state.lprod.filter(function (el) {
                    return el.idstype === idStype;
                });
                if (liste !== null && liste != undefined) {
                    if (liste.length > 0) {
                        return <DataView value={liste} layout='grid'
                            paginator={true} rows={12} alwaysShowPaginator={false}
                            itemTemplate={(data, layout) => {
                                if (data != undefined && data != null) {
                                    let color = 'black';
                                    if (parseFloat(data.qte_stock < 0)) {
                                        color = 'red';
                                    }
                                    return (
                                        <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
                                            <a onClick={(e) => {
                                                this.setState({ idArticle: data }, () => {
                                                    let remise_client = 0;
                                                    if (this.state.idCli != null && this.state.idCli != undefined) {
                                                        remise_client = this.state.idCli.remiseCli;
                                                    }
                                                    let tt_remise = this.state.idArticle.currentRemise + remise_client;
                                                    this.setState({ puLsortie: this.state.idArticle.prix_ref_vente }, () => {
                                                        this.setState({ qteLsortie: 1 }, () => {
                                                            this.setState({ remiseLsortie: 0.01 * tt_remise * this.state.idArticle.prix_ref_vente }, () => {
                                                                this.add_article();
                                                            })
                                                        });
                                                    });
                                                });

                                            }}>
                                                <div className="card card-w-title" style={{ minHeight: '80px', marginBottom: -8, backgroundColor: '#ffffff' }}>
                                                    <div className="p-grid">
                                                        <div className="p-col-12" style={{ marginBottom: -10, width: '100%', height: '30px' }}>
                                                            <span style={{ color: '#000', fontSize: '14px', fontWeight: 'bold', width: '100%', display: 'inline-block' }}>{data.label}</span>
                                                        </div>
                                                        <div className="p-col-12" style={{ marginBottom: -10 }}>
                                                            <img src={this.myservice.download_file(data.couvArticle, 'photos')} alt={data.label} style={{ width: '100%', height: '90px' }} />
                                                        </div>
                                                        <div className="p-col-12 p-md-9" style={{ marginBottom: -10 }}>
                                                            <label style={{ color: '#fc5203', fontSize: '18px', fontWeight: 'bold' }}>{data.prix_ref_vente_format}</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-3" style={{ marginBottom: -10 }}>
                                                            <label style={{ color: color, fontSize: '18px', fontWeight: 'bold' }}>{data.qte_stock}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                } else {
                                    return (
                                        null
                                    );
                                }

                            }}
                        />
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }

    }

    get_liste_parent_assure() {
        this.myservice.get_liste_parent_assure('Tous').then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ liste_parent_assure: data }, () => {
                    let lparent_assure = this.state.liste_parent_assure.map(x => {
                        return { value: x.idParentAss, label: x.idCli.nomCli + ' / ' + x.nomParentAss + '  -  ' + x.idParentAss, idCli: x.idCli.idCli, nomCli: x.idCli.nomCli, label_rech: x.nomParentAss + ' ' + x.idParentAss }
                    });
                    this.setState({ lparent_assure: lparent_assure });
                });
            }

        });
    }

    get_liste_assure() {
        this.myservice.get_liste_assure().then(data => {
            if (data !== null && data !== undefined) {
                this.setState({ liste_generale_assure: data });
                let liste = data.map(x => {
                    return { value: x.idAss, label: x.nomAss, matrAss: x.matrAss, idCli: x.idParentAss.idCli.idCli, nomCli: x.idParentAss.idCli.nomCli, label_rech: x.nomAss + ' ' + x.idAss, nomSouscripteur: x.idParentAss.nomParentAss, idParentAss: x.idParentAss.idParentAss }
                });
                this.setState({ liste_generale_assure_rech: liste });
            }
        });
    }

    get_liste_sous_type_article() {
        this.myservice.get_liste_all_stype_inart().then(data => this.setState({ liste_sous_type_article: data }));
    }

    get_liste_salle() {
        this.myservice.liste_salle_in_table(this.state.userconnected.matrPers.idBoutiq.idBoutiq).then(data => this.setState({ liste_salle: data }));
    }

    get_liste_table() {
        this.myservice.get_liste_table_sortie('Tous').then(data => this.setState({ liste_table: data }));
    }

    get_liste_boutique() {
        let lboutique_rech = [{ value: 'Tous', label: 'Boutiques' }];
        this.myservice.getListe_boutique().then(data => this.setState({ liste_boutique: data }, () => {
            if (this.state.liste_boutique !== null && this.state.liste_boutique !== undefined) {
                if (this.state.liste_boutique.length > 0) {
                    let lboutique = this.state.liste_boutique.map(x => {
                        return { value: x.idBoutiq, label: x.nomBoutiq }
                    });
                    this.setState({ lboutique: lboutique }, () => {
                        if (this.state.userconnected != null && this.state.userconnected != undefined) {
                            this.setState({ idBoutiq: { value: this.state.userconnected.matrPers.idBoutiq.idBoutiq, label: this.state.userconnected.matrPers.idBoutiq.nomBoutiq } }, () => {
                                if (this.myservice.wsurl().indexOf(":9077") > 0) {
                                    this.setState({ rfiltreArticle: 'Tous' }, () => {
                                        this.get_liste_article(this.state.idBoutiq.value);
                                    })
                                } else {
                                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                                        this.setState({ rfiltreArticle: 'Tous' }, () => {
                                            this.get_liste_article(this.state.idBoutiq.value);
                                        })
                                    }
                                }
                                this.setState({ ridBoutiq: this.state.idBoutiq }, () => {
                                    this.get_liste_sortie();
                                })
                            });
                        }
                    });

                    if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                        this.setState({ lboutique: lboutique });
                        lboutique_rech = lboutique_rech.concat(lboutique);
                    } else {

                        let id = this.state.userconnected.matrPers.idBoutiq.idBoutiq;
                        if (lboutique.length > 0) {
                            let liste = lboutique.filter(function (el) {
                                return el.value === id;
                            });
                            lboutique_rech = lboutique_rech.concat(liste);
                        }


                    }
                    this.setState({ lboutique_rech: lboutique_rech });

                }
            }
        }));

        if (this.state.userconnected.profilUser == 'Directeur') {
            this.myservice.getListe_boutique_gerer().then(data2 => {
                if (data2 != null && data2 != undefined) {
                    let liste = data2.map(x => {
                        return { value: x.idBoutiq, label: x.nomBoutiq }
                    });
                    lboutique_rech = lboutique_rech.concat(liste);
                    this.setState({ lboutique_rech: lboutique_rech })
                }
            });
        }
    }

    get_liste_client() {
        this.setState({ showindicator: true });
        let lclient_rech = [{ value: 'Tous', label: 'Toutes les clients', remiseCli: 0, soldeCli_format: '0', telCli: 'x', tvaCli: 0, balanceCli: 0, caventeCli: 0, typeCli: 'Inconnu', label_rech: 'TOUS 0' }];
        this.myservice.getliste_client_light(this.state.rfiltreClient).then(data => this.setState({ liste_client: data }, () => {
            let lclient = null;
            this.setState({ showindicator: false });
            if (this.state.liste_client !== null && this.state.liste_client !== undefined) {
                if (this.state.liste_client.length > 0) {
                    lclient = this.state.liste_client.map(x => {
                        return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, typeCli: x.typeCli, label_rech: x.nomCli + ' ' + x.idCli, precompteCli: x.precompteCli }
                    });
                    let liste_assure = lclient.filter(function (el) {
                        return el.typeCli == 'Assurance';
                    });

                    this.setState({ lclient_assure: liste_assure });

                    if (this.get_type_sortie() === 'Perte') {
                        lclient = lclient.filter(function (el) {
                            return el.typeCli == 'Client interne';
                        });
                    }
                    lclient_rech = lclient_rech.concat(lclient);
                    this.setState({ lclient_rech: lclient_rech });
                    this.setState({ lclient: lclient }, () => {
                        if (this.state.actif_vente_proforma === false) {
                            let cli = this.state.lclient.filter(function (el) {
                                return el.typeCli === 'Default';
                            })[0];

                            this.setState({ idCli: cli });
                        }

                    });
                }
            }
        }));
    }

    get_liste_article(idBoutiq) {
        let lprod = null;
        let lprod_spec = null;
        this.setState({ showindicator: true });
        this.myservice.get_inventaire_mins(this.state.rfiltreArticle, idBoutiq).then(data => this.setState({ liste_article: data, showindicator: false }, () => {
            if (this.state.liste_article !== null && this.state.liste_article !== undefined) {
                if (this.state.liste_article.length > 0) {
                    lprod = this.state.liste_article.map(x => {
                        return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, couvArticle: x.couvArticle, label_rech: x.libArt + ' ' + x.cbArt, prix_except: x.prix_except, constRefArticle: x.constRefArticle }
                    })
                    this.setState({ lprod: lprod });
                }
            }
        }));
    }

    select_parent_assure_by_client(idClient) {
        let liste_filtre = [{ value: 'Tous', label: 'Souscripteur', idCli: '0', nomCli: '', label_rech: 'Tous' }];
        if (this.state.lparent_assure != null && this.state.lparent_assure != undefined) {
            if (this.state.lparent_assure.length > 0) {
                let liste = this.state.lparent_assure.filter(function (el) {
                    return el.idCli == idClient;
                });
                liste_filtre = liste_filtre.concat(liste);
            }
        }
        this.setState({ lparent_assure_rech: liste_filtre });
    }

    select_assure_by_parent(idParentAss) {
        this.setState({ showindicator: true });
        let liste_filtre = [{ value: 'Tous', label: 'Assuré', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' }];
        if (this.state.liste_generale_assure_rech != null && this.state.liste_generale_assure_rech != undefined) {
            if (this.state.liste_generale_assure_rech.length > 0) {
                let liste = this.state.liste_generale_assure_rech.filter(function (el) {
                    return el.idParentAss == idParentAss;
                });
                liste_filtre = liste_filtre.concat(liste);
            }
        }
        this.setState({ lassure_rech: liste_filtre, showindicator: false });
    }

    select_assure_by_client(idClient) {
        let liste_filtre = [];
        if (this.state.liste_generale_assure != null && this.state.liste_generale_assure != undefined) {
            if (this.state.liste_generale_assure.length > 0) {
                liste_filtre = this.state.liste_generale_assure.filter(function (el) {
                    return el.idParentAss.idCli.idCli == idClient;
                });
                let liste = liste_filtre.map(x => {
                    return { value: x.idAss, label: x.nomAss, pchargeAss: x.pchargeAss, remiseAss: x.remiseAss, matrAss: x.matrAss, idCli: x.idParentAss.idCli.idCli, nomCli: x.idParentAss.idCli.nomCli, remiseCli: x.idParentAss.idCli.remiseCli, label_rech: x.nomAss + ' ' + x.idParentAss.nomParentAss + ' ' + x.idAss, nomSouscripteur: x.idParentAss.nomParentAss }
                });
                liste_filtre = liste;
            }
        }
        let liste = [{ value: '-1', label: 'Non applicable', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' }];
        liste = liste.concat(liste_filtre)
        this.setState({ liste_assure_vente: liste });
    }

    get_liste_sortie() {
        let typesortie = this.get_type_sortie();
        this.setState({ showindicator: true, liste_sortie: [] });
        let rstatut_livraison = 'Tous';
        let rstatut_reglement = 'Tous';
        let rboutique = 'Tous';
        let rid_client = 'Tous';
        let rnum_facture = 'Tous';
        let rlivreur = 'Tous';
        let rid_parent_assure = 'Tous';
        let rid_assure = 'Tous';
        this.setState({ total_facture: '0' });
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq !== undefined || this.state.ridBoutiq !== null) {
            rboutique = this.state.ridBoutiq.value;
        }
        if (this.state.statutReglement !== undefined && this.state.statutReglement !== null) {
            rstatut_reglement = this.state.statutReglement.value;
        }
        if (this.state.statutLivraison !== undefined && this.state.statutLivraison !== null) {
            rstatut_livraison = this.state.statutLivraison.value;
        }
        if (this.state.ridClient !== undefined && this.state.ridClient !== null) {
            rid_client = this.state.ridClient.value;
        }

        if (this.state.ridParent_assure !== undefined && this.state.ridParent_assure !== null) {
            rid_parent_assure = this.state.ridParent_assure.value;
        }
        if (this.state.ridAssure !== undefined && this.state.ridAssure !== null) {
            rid_assure = this.state.ridAssure.value;
        }
        if (this.state.ridLivreur !== undefined && this.state.ridLivreur !== null) {
            rlivreur = this.state.ridLivreur.value;
        }
        if (this.state.rnum_facture !== undefined && this.state.rnum_facture !== null) {
            rnum_facture = this.state.rnum_facture;
        }
        this.myservice.get_liste_sortie_by_criteria(spd1, spd2, rnum_facture, rboutique, rstatut_reglement, rstatut_livraison, rid_client, rid_parent_assure, rid_assure, rlivreur, typesortie, 'Tous').then(data => this.setState({ liste_sortie: data, showindicator: false }, () => {

            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.montant_sortie
                    }, initialValue);
                    let tp = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mt_regle
                    }, initialValue);
                    let tr = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mt_restant
                    }, initialValue);
                    this.setState({
                        total_facture: this.formaterMontant(tt),
                        montant_paye_format: this.formaterMontant(tp),
                        montant_restant_format: this.formaterMontant(tr),
                    });

                    if (this.state.liste_sortie.length > 0 && this.state.selectedSortie != null && this.state.selectedSortie != undefined) {
                        let id = this.state.selectedSortie.idSortie;
                        let sortie = this.state.liste_sortie.filter(function (el) {
                            return el.idSortie === id;
                        })[0];
                        if (sortie != null && sortie != undefined) {
                            this.setState({ selectedSortie: sortie }, () => {
                                this.setState({ mtrglSortie: this.state.selectedSortie.mt_sortie_restant });
                            });
                        }
                    }
                }
            }
        }));
    }

    get_liste_corbeille_sortie() {
        this.setState({ showindicator: true, liste_sortie: [] });
        let rboutique = 'Tous';
        let rid_client = 'Tous';
        let rnum_facture = 'Tous';

        this.setState({ total_facture_corbeille: '0' });
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridBoutiq !== undefined || this.state.ridBoutiq !== null) {
            rboutique = this.state.ridBoutiq.value;
        }
        if (this.state.ridClient !== undefined && this.state.ridClient !== null) {
            rid_client = this.state.ridClient.value;
        }
        if (this.state.rnum_facture !== undefined && this.state.rnum_facture !== null) {
            rnum_facture = this.state.rnum_facture;
        }
        this.myservice.get_liste_corbeille_sortie(spd1, spd2, rnum_facture, rboutique, rid_client).then(data => this.setState({ liste_corbeille_sortie: data, showindicator: false }, () => {
            if (data != null && data != undefined) {
                let initialValue = 0;
                let tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montant_sortie
                }, initialValue);
                this.setState({ total_facture_corbeille: this.formaterMontant(tt) });
            }
        }));
    }

    get_liste_lsortie(idSortie) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_lsortie(idSortie).then(data => this.setState({ liste_lsortie: data, showindicator: false }));
    }

    get_liste_corbeille_lsortie(idSortie) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_corbeille_lsortie(idSortie).then(data => this.setState({ liste_corbeille_lsortie: data, showindicator: false }));
    }

    get_liste_livraison_lsortie_by_sortie(idSortie) {
        this.myservice.get_liste_livraison_lsortie_by_sortie(idSortie).then(data => {
            this.setState({ liste_livraison_lsortie: data });
        });
    }

    get_liste_livraison_lsortie_by_bl(numBl) {
        this.myservice.get_liste_livraison_lsortie_by_bl(numBl).then(data => {
            this.setState({ liste_livraison_lsortie: data });
        });
    }

    resultat_livraison(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_livraison();
            this.setState({ liste_livraison_lsortie: [] }, () => {
                this.get_liste_livraison_lsortie_by_sortie(this.state.selectedSortie.idSortie);
            });

        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    annule_livraison() {
        this.setState({ modif_bl: false }, () => {
            this.setState({ dateBl: new Date() });
            this.setState({ receveurBl: '' });
        });

    }

    enreg_reglSortie_bloc() {
        if (this.state.liste_sortie_for_reglement_selecteds === null || this.state.liste_sortie_for_reglement_selecteds === undefined || this.state.daterglSortie === '' || this.state.daterglSortie === undefined || this.state.daterglSortie === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner les factures à régler' });
        } else {
            if (this.state.liste_sortie_for_reglement_selecteds.length <= 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures à régler' });
                return;
            }
            if (this.myservice.get_statut_offline() === 'true' && this.state.modeRgl.value === 'Compte') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez régler par compte en mode offline' });
                return;
            }
            this.setState({ showindicator: true });
            this.myservice.regler_sortie_bloc(this.state.liste_sortie_for_reglement_selecteds, this.state.modeRgl.value, this.state.refRgl, this.myservice.get_statut_sync()).then(reponse => {
                if (reponse != null && reponse != undefined) {
                    if (reponse.code === 200) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Règlement réussi' });
                        this.setState({ total_reglement_format: '0' });
                        this.setState({ liste_sortie_for_reglement_selecteds: [] });

                        this.get_liste_sortie_for_reglement();
                        this.setState({ showindicator: false });
                    }
                }
            });
        }
    }

    annule_reglSortie_bloc() {
        this.setState({ total_reglement_format: '0' });
        this.setState({ daterglSortie: '' });
        this.setState({ refRglSortie: '' });
        this.forceUpdate();
    }

    enreg_reglSortie() {
        if (this.state.selectedSortie === null || this.state.selectedSortie === undefined || this.state.daterglSortie === '' || this.state.daterglSortie === undefined || this.state.daterglSortie === null || this.state.mtrglSortie === undefined || this.state.mtrglSortie === null || this.state.mtrglSortie === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        }
        else {
            if (this.state.modif_regl_sortie === false) {
                if (this.state.selectedSortie.mt_sortie_restant < parseFloat(this.state.mtrglSortie)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                    return;
                } else if (this.state.selectedSortie.idCli.typeCli === 'Default' && this.state.modeRgl.value === 'Compte') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le type de client concerné par cette facture ne peut posséder de compte' });
                    return;
                }
                if (this.myservice.get_statut_offline() === 'true' && this.state.modeRgl.value === 'Compte') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez régler par compte en mode offline' });
                    return;
                }
                let date = moment(this.state.daterglSortie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { idrglSortie: '0', statutSyncRegl: this.myservice.get_statut_sync(), mtrglSortie: this.state.mtrglSortie, daterglSortie: date, idUserRegl: this.state.userconnected, modeRgl: this.state.modeRgl.value, refRgl: this.state.refRgl, idSortie: { idSortie: this.state.selectedSortie.idSortie, idCli: { idCli: this.state.selectedSortie.idCli.idCli } } };

                this.myservice.enreg_regl_sortie(data).then(reponse => {
                    if (reponse != null && reponse != undefined) {
                        this.resultat_reglSortie(reponse.code, reponse.contenu);
                        this.get_liste_regler_sortie('Tous', 'Tous', 'Tous', this.state.selectedSortie.idSortie);
                    }
                });
            } else {
                if (this.state.selectedReglSortie.mtrglSortie < parseFloat(this.state.mtrglSortie)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                } else if (this.state.selectedSortie.mt_sortie_restant < (parseFloat(this.state.mtrglSortie) - this.state.selectedReglSortie.mtrglSortie)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                } else {
                    if (this.state.selectedReglSortie.idSortie.idCli.typeCli === 'Default' && this.state.modeRgl.value === 'Compte') {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Le type de client concerné par cette facture ne peut posséder de compte' });
                        return;
                    }
                    if (this.myservice.get_statut_offline() === 'true' && this.state.modeRgl.value === 'Compte' && this.state.selectedReglSortie.statutSyncRegl !== 'Compte') {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez régler par compte en mode offline' });
                        return;
                    }
                    let date = moment(this.state.daterglSortie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                    let data = this.state.selectedReglSortie;
                    data.mtrglSortie = this.state.mtrglSortie;
                    data.daterglSortie = date;
                    data.statutSyncRegl = this.myservice.get_statut_sync();
                    data.refRgl = this.state.refRgl
                    data.modeRgl = this.state.modeRgl.value;
                    this.myservice.enreg_regl_sortie(data).then(reponse => {
                        if (reponse != null && reponse != undefined) {
                            this.resultat_reglSortie(reponse.code, reponse.contenu);
                            this.get_liste_regler_sortie('Tous', 'Tous', 'Tous', this.state.selectedSortie.idSortie);
                        }
                    });

                }

            }
        }
    }

    enreg_bl() {
        if (this.state.selectedSortie === null || this.state.selectedSortie === undefined || this.state.dateBl === '' || this.state.dateBl === undefined || this.state.dateBl === null || this.state.receveurBl === undefined || this.state.receveurBl === null || this.state.receveurBl === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une facture' });
        } else {
            if (this.state.modif_bl === false) {
                let date_bl = moment(this.state.dateBl, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = { numBl: '0', dateBl: date_bl, receveurBl: this.state.receveurBl, idSortie: { idSortie: this.state.selectedSortie.idSortie } };

                if (this.state.liste_livraison_lsortie == null || this.state.liste_livraison_lsortie == undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
                    return;
                } else if (this.state.liste_livraison_lsortie.length <= 0) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les articles à livrer' });
                    return;
                }
                for (var i = this.state.liste_livraison_lsortie.length; i--;) {
                    this.state.liste_livraison_lsortie[i].numBlLs = data;
                    this.state.liste_livraison_lsortie[i].iduser = this.state.userconnected.idUser;
                    if (this.state.liste_livraison_lsortie[i].qteLivrLs > this.state.liste_livraison_lsortie[i].qteLivrLs_restant) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les quantités à livrer' });
                        return;
                    }

                }
                for (var i = this.state.liste_livraison_lsortie.length; i--;) {
                    if (this.state.liste_livraison_lsortie[i].qteLivrLs === 0) {
                        this.state.liste_livraison_lsortie.splice(i, 1);
                    }
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_livraison_lsortie_batch(this.state.liste_livraison_lsortie).then(reponse => {
                    if (reponse != null && reponse != undefined) {
                        this.resultat_livraison(reponse.code, reponse.contenu);
                    }
                });
            } else {
                let data = this.state.selectedLivraisonSortie;
                let date_bl = moment(this.state.dateBl, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.dateBl = date_bl;
                data.receveurBl = this.state.receveurBl;
                this.myservice.enreg_livraison_sortie(data).then(reponse => {
                    if (reponse != null && reponse != undefined) {
                        this.resultat_livraison(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    edit_bl(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_bl: true }, () => {
                this.setState({ dateBl: data.dateBl });
                this.setState({ receveurBl: data.receveurBl });
            });
        }
    }

    edit_reglSortie(data) {
        if (data != null && data != undefined) {
            this.setState({ modif_regl_sortie: true }, () => {
                this.setState({ daterglSortie: data.daterglSortie });
                this.setState({ refRgl: data.refRgl });
                this.setState({ mtrglSortie: data.mtrglSortie });
                this.setState({ modeRgl: { value: data.modeRgl, label: data.modeRgl } });
            });
        }
    }

    resultat_reglSortie(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reglSortie();
            this.get_liste_sortie();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    annule_reglSortie() {
        this.setState({ idrglSortie: '0' });
        this.setState({ daterglSortie: '' });
        this.setState({ refRglSortie: '' });
        this.setState({ modif_regl_sortie: false });
        this.forceUpdate();
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_sortie();
            this.setState({ liste_lsortie: [] });
            this.get_liste_client();
            this.get_liste_sortie();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_lsortie(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_lsortie();
            if (this.state.selectedSortie != null && this.state.selectedSortie != undefined) {
                this.get_liste_lsortie(this.state.selectedSortie.idSortie);
                this.get_liste_sortie();
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    show_dlg_reglSortie(rowData) {
        if (this.state.userconnected.idEtabl.codeEtabl == 'KEBALY' && rowData.dateSortie_format_court !== moment(new Date()).format('DD/MM/YYYY') && (this.state.userconnected.profilUser == 'Vendeur' || this.state.userconnected.profilUser == 'Gérant')) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Vous n'avez pas le droit de régler cette facture" });
            return;
        }
        this.myservice.get_liste_regler_sortie('Tous', 'Tous', 'Tous', rowData.idSortie).then(data => {
            this.setState({ liste_regler_sortie: data }, () => {
                this.setState({ visible_dlg_reglSortie: true }, () => {
                    this.setState({ mtrglSortie: this.state.selectedSortie.mt_sortie_restant });
                });
            });
        });
    }

    conf_delete_reglSortie(rowData) {
        let data = { idrglSortie: rowData.idrglSortie };
        this.myservice.delete_regl_sortie(rowData).then(data => {
            this.resultat_reglSortie(data.code, data.contenu);
            this.get_liste_regler_sortie('Tous', 'Tous', 'Tous', rowData.idSortie.idSortie);
        });
    }

    show_trace(rowData) {
        this.setState({ visible_dlg_trace: true, showindicator: true });
        this.myservice.get_liste_trace_sortie(rowData.idSortie).then(data => this.setState({ liste_trace: data, showindicator: false }));
    }

    conf_print_facture(data, format) {
        this.myservice.generer_edition_facture(data.idSortie, data.typeSortie, format);
    }

    conf_print_bl(data) {
        this.myservice.generer_edition_bl(data.numBl);
    }

    conf_print_recu(data) {
        this.myservice.generer_edition_recu(data.idrglSortie);
    }

    edit_sortie(data) {
        if (data != null && data != undefined) {
            if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && data.disable_modif == "true") {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Vous n'avez pas le droit de modifier cette facture" });
                return;
            }
            this.setState({ idSortie: data.idSortie });
            this.setState({ cmtSortie: data.cmtSortie });
            this.setState({ dateSortie: data.dateSortie });
            this.setState({ modalitePaySortie: data.modalitePaySortie });
            this.setState({ delaiRglSortie: data.delaiRgl_format });
            this.setState({ numFactureSortie: data.numFactureSortie });
            this.setState({ idBoutiq: { value: data.idBoutiqConcerne.idBoutiq, label: data.idBoutiqConcerne.nomBoutiq } });
            this.setState({ typeSortie: { value: data.typeSortie, label: data.typeSortie } });
            if (parseFloat(data.qte_livr) == 0) {
                this.setState({ visible_drp_boutiq_concerne: true });
            } else {
                this.setState({ visible_drp_boutiq_concerne: false });
            }
            let liste_filtre = [];
            if (this.state.liste_generale_assure != null && this.state.liste_generale_assure != undefined) {
                if (this.state.liste_generale_assure.length > 0) {
                    liste_filtre = this.state.liste_generale_assure.filter(function (el) {
                        return el.idParentAss.idCli.idCli == data.idCli.idCli;
                    });
                    let liste = liste_filtre.map(x => {
                        return { value: x.idAss, label: x.nomAss, pchargeAss: x.pchargeAss, remiseAss: x.remiseAss, matrAss: x.matrAss, idCli: x.idParentAss.idCli.idCli, nomCli: x.idParentAss.idCli.nomCli, remiseCli: x.idParentAss.idCli.remiseCli, label_rech: x.nomAss + ' ' + x.idAss, nomSouscripteur: x.idParentAss.nomParentAss }
                    });
                    liste_filtre = liste;
                }
            }
            let liste = [{ value: '-1', label: 'Non applicable', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' }];
            liste = liste.concat(liste_filtre)

            this.setState({ liste_assure_vente: liste }, () => {
                let assure = this.state.liste_assure_vente.filter(function (el) {
                    return el.value === data.idAssSortie;
                })[0];
                this.setState({ idAssSortie: assure });
            });
            let client = [];

            if (this.state.userconnected.idEtabl.codeEtabl == 'EDK') {
                this.setState({ showindicator: true });
                this.myservice.getliste_client_light(data.idCli.nomCli).then(data_liste => {
                    let lclient = null;
                    this.setState({ showindicator: false });
                    if (data_liste != null && data_liste != undefined) {
                        lclient = data_liste.map(x => {
                            return { value: x.idCli, label: x.nomCli, remiseCli: x.remiseCli, soldeCli_format: x.soldeCli_format, telCli: x.telCli, tvaCli: x.tvaCli, balanceCli: x.balanceCli, caventeCli: x.caventeCli, typeCli: x.typeCli, label_rech: x.nomCli + ' ' + x.idCli, precompteCli: x.precompteCli }
                        });
                        client = lclient.filter(function (el) {
                            return el.value === data.idCli.idCli;
                        })[0];
                        this.setState({ lclient: lclient }, () => {
                            this.setState({ idCli: client });
                        });
                    }
                }
                );
            } else {
                client = this.state.lclient.filter(function (el) {
                    return el.value === data.idCli.idCli;
                })[0];
                this.setState({ idCli: client });
            }

            this.setState({ precompteSortie: data.precompteSortie });
            this.setState({ libSortie: data.libSortie });

            this.setState({ refSortie: data.refSortie });
            this.setState({ valideSortie: data.valideSortie });
            this.setState({ delaiLivrSortie: data.delaiLivrSortie });
            this.setState({ garantieSortie: data.garantieSortie });
            this.setState({ refSortie: data.refSortie });
            this.setState({ tvaSortie: data.tvaSortie });
            this.setState({ remiseSortie: data.remiseSortie });
            this.setState({ paSortie: data.paSortie });
            this.setState({ modif: true });
            this.setState({ visible_dlg_sortie: true });
            this.setState({ tagSortie: data.tagSortie });
            this.setState({ nomInteresseSortie: data.nomInteresseSortie });

            this.setState({ selectedSortie: data });

            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }

    restaurer_sortie(data) {
        this.myservice.restaure_sortie(data).then(data => {
            if (data !== null && data !== undefined) {
                if (data.code == 200) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: data.contenu });
                    this.get_liste_corbeille_sortie();
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: data.contenu });
                }
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec opération' });
            }
        });
    }

    edit_lsortie(data) {
        if (data != null && data != undefined) {
            this.setState({ idLsortie: data.idLsortie });
            this.setState({ qteLsortie: data.qteLsortie });
            this.setState({ coefLsortie: data.coefLsortie });
            this.setState({ constLsortie: data.constLsortie });
            this.setState({ descLsortie: data.descLsortie });
            this.setState({ puLsortie: data.puLsortie });
            this.setState({ remiseLsortie: data.remiseLsortie });
            this.setState({ qteLivrLsortie: data.qteLivrLsortie });
            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                this.setState({ rfiltreArticle: data.idArticle.idArticle }, () => {
                    this.setState({ showindicator: true });
                    this.myservice.get_inventaire_mins(this.state.rfiltreArticle, this.state.idBoutiq.value).then(data2 => this.setState({ showindicator: false }, () => {
                        if (data2 != null && data2 != undefined) {
                            let lprod = data2.map(x => {
                                return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock_reel), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock_reel, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, couvArticle: x.couvArticle, label_rech: x.libArt + ' ' + x.cbArt, prix_except: x.prix_except, constRefArticle: x.constRefArticle }
                            })
                            let id = this.state.rfiltreArticle;
                            let art = lprod.filter(function (el) {
                                return el.value == id;
                            })[0];
                            this.setState({ idArticle: art });
                            this.setState({ qte_stock: art.qte_stock_format });
                        }
                    }));
                })
            } else {
                let art = this.state.lprod.filter(function (el) {
                    return el.value == data.idArticle.idArticle;
                })[0];
                this.setState({ idArticle: art });
                this.setState({ qte_stock: art.qte_stock_format });
            }


            let mt = parseFloat(data.puLsortie) * parseFloat(data.qteLsortie) - parseFloat(data.remiseLsortie);
            this.setState({ total_restant: mt, mtpaye: 0 });

            this.get_liste_article_spec();
            this.myservice.get_liste_detail_lsortie(data.idLsortie).then(data_ligne => this.setState({ liste_detail: data_ligne }, () => {
                if (this.state.liste_detail.length > 0) {
                    this.calculer_total_detail();
                } else {
                    this.setState({ total_restant: data.montantLsortie });
                }
            }));

            this.setState({ modif_lsortie: true });
            this.setState({ visible_dlg_lsortie: true });
            this.setState({ disable_prod: true });
            this.setState({ selectedLsortie: data });
            this.setState({ receveurLsortie: data.idSortie.idCli.nomCli });
            this.setState({ libArticle: data.idArticle.libArticle });
            this.setState({ designationLsortie: data.designationLsortie });
            this.setState({ qteDlsortie: data.qteLsortie });

            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }

    annule_sortie() {
        this.setState({ idSortie: 0 });
        this.setState({ dateSortie: new Date() });
        this.setState({ delaiRglSortie: '' });
        this.setState({ valideSortie: '' });
        this.setState({ cmtSortie: '' });
        this.setState({ libSortie: '' });
        this.setState({ precompteSortie: 0 });
        this.setState({ numFactureSortie: '' });
        this.setState({ modif: false });
        this.setState({ disable_val_sortie: true });
        this.setState({ visible_dlg_sortie: false });
        this.setState({ montant_interesse_format: '0' });
        this.setState({ mtpaye: 0 });
        this.setState({ mtapayer: 0 });
        this.setState({ val_remise: 0 });
        this.setState({ idAssSortie: { value: '-1', label: 'Non applicable', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' } });
        this.forceUpdate();
    }

    annule_sortie_dlg() {
        this.annule_sortie();
        this.setState({ liste_ligne: [] });
        //document.getElementById("new_dp_prod").click();
    }

    annule_lsortie() {
        this.setState({ idLsortie: '0' });
        this.setState({ coefLsortie: 1 });
        this.setState({ constLsortie: 1 });
        this.setState({ descLsortie: '' });
        this.setState({ qteLsortie: 0 });
        this.setState({ puLsortie: 0 });
        this.setState({ receveurLsortie: '' });
        this.setState({ remiseLsortie: 0 });
        this.setState({ modif_lsortie: false });
        this.setState({ liste_ligne: [] });
        this.setState({ mtTVA: '0' });
        this.setState({ mtapayer_format: '0' });
        this.setState({ mtpaye: '0' });
        this.setState({ montant_brut_format: '0' });
        this.setState({ montant_interesse_format: '0' });
        this.setState({ numFactureSortie: '' });
        this.setState({ total_restant: '0' });
        this.setState({ actif_vente_proforma: false });
        if (this.state.modif === false && this.get_type_sortie() === 'Nouvelle facture') {
            document.getElementById("new_dp_client").click();
        }
        this.setState({ modif: false });
        this.setState({ idAssSortie: { value: '-1', label: 'Non applicable', pchargeAss: 0, remiseAss: 0, matrAss: '', idCli: '-1', nomCli: '', remiseCli: 0, label_rech: 'Non applicable', nomSouscripteur: 'Non applicable' } });
        /*
        if (this.state.visible_dlg_vente_restau === true) {
            this.get_liste_table();
        }
        */
        if (this.state.visible_dlg_vente_restau === true) {
            // window.location.reload();
        }

    }



    enreg_sortie(typeSortie) {
        if (this.state.idCli == "" || this.state.idCli == null || this.state.dateSortie == "" || this.state.dateSortie == null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.idBoutiq === undefined || this.state.idBoutiq === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez la boutique concernée' });
        } else {
            if (this.state.modif == false) {
                let type_sortie = typeSortie;
                if (type_sortie === 'Perte') {
                    type_sortie = this.state.typeSortie.value;
                    if (this.state.typeSortie.value === undefined) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le type de perte' });
                        return;
                    }
                }
                if (type_sortie === 'Facture' && this.state.idCli.typeCli === 'Default' && this.state.mtpaye < this.state.mtapayer && this.state.visible_dlg_vente_restau === false) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la totalité du montant à payer car obligatoire pour ce type de client' });
                    this.setState({ mtpaye: this.state.mtapayer });
                    return;
                } else if (type_sortie === 'Facture' && this.state.mtpaye > this.state.mtapayer) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier le montant payé saisi ' });
                    return;
                } else if (this.state.idCli.typeCli === 'Default' && this.state.userconnected.matrPers.idBoutiq.idBoutiq !== this.state.idBoutiq.value) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Pas d'inter magasin pour ce type de client" });
                    return;
                }

                if (this.myservice.get_statut_offline() == 'true' && this.state.modeRgl.value == 'Compte') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Vous ne pouvez pas régler par compte en mode offline" });
                    return;
                }

                let date_sortie = moment(this.state.dateSortie, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                if (this.state.userconnected.profilUser != 'PDG') {
                    date_sortie = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
                }
                let data = { nomInteresseSortie: this.state.nomInteresseSortie, dateSortie: date_sortie, precompteSortie: this.state.precompteSortie, statutSyncSortie: this.myservice.get_statut_sync(), refSortie: this.state.refSortie, libSortie: this.state.libSortie, idAssSortie: this.state.idAssSortie.value, paSortie: this.state.paSortie, remiseSortie: this.state.remiseSortie, tvaSortie: this.state.tvaSortie, typeSortie: type_sortie, delaiRglSortie: null, valideSortie: '', numFactureSortie: this.state.numFactureSortie, cmtSortie: this.state.cmtSortie, idCli: { idCli: this.state.idCli.value }, idBoutiq: this.state.userconnected.matrPers.idBoutiq, idBoutiqConcerne: { idBoutiq: this.state.idBoutiq.value } };

                if (this.state.selectedTable !== null && this.state.selectedTable !== undefined) {
                    data.idTable = this.state.selectedTable.id_table;
                }
                if (this.state.matrLivreur !== null && this.state.matrLivreur !== undefined) {
                    data.matrLivreur = this.state.matrLivreur.value;
                } else {
                    data.matrLivreur = this.state.userconnected.matrPers.matrPers;
                }

                if (this.state.actif_vente_proforma === false) {
                    data.idSortie = '0';
                } else {
                    data.typeSortie = 'Facture';
                    data.idSortie = this.state.idSortie;
                }

                let md_vente = null;
                let data_reglsortie = null;
                if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                } else if (this.state.liste_ligne.length <= 0) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les articles' });
                    return;
                }
                for (var i = this.state.liste_ligne.length; i--;) {
                    if (parseFloat(this.state.liste_ligne[i].qteLivrLsortie) > parseFloat(this.state.liste_ligne[i].qteLsortie) && typeSortie !== 'Proforma') {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les quantités à réapprovisionner' });
                        return;
                    }
                    if (parseFloat(this.state.liste_ligne[i].remiseLsortie) > parseFloat(this.state.liste_ligne[i].puLsortie)) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les remises' });
                        return;
                    }
                    if (data.idBoutiq.idBoutiq !== this.state.idBoutiq.value) {
                        this.state.liste_ligne[i].qteLivrLsortie = 0;
                    }
                    this.state.liste_ligne[i].idSortie = data;
                    this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
                    this.state.liste_ligne[i].statutSyncLsortie = this.myservice.get_statut_sync();

                }
                this.setState({ showindicator: true });
                md_vente = { liste_ligne_sortie: this.state.liste_ligne, mtRestant: this.state.total_restant };
                if (parseFloat(this.state.mtpaye) > 0 && type_sortie === 'Facture') {
                    data_reglsortie = { idrglSortie: '0', statutSyncRegl: this.myservice.get_statut_sync(), mtrglSortie: this.state.mtpaye, modeRgl: this.state.modeRgl.value, refRgl: this.state.refRglSortie, idUserRegl: this.state.userconnected }
                    md_vente.reglSortie = data_reglsortie;
                }
                //alert(JSON.stringify(this.state.liste_ligne[0].liste_detail));
                this.myservice.enreg_lsortie_batch(md_vente).then(reponse => {
                    let send_success = false;
                    if (reponse != null && reponse != undefined) {
                        for (var i = reponse.length; i--;) {
                            if (reponse[i].code === 200) {
                                this.growl.show({ severity: 'success', summary: 'Success', detail: reponse[i].contenu });
                                let msg = reponse[i].contenu;
                                if (msg.includes('Vente')) {
                                    send_success = true;
                                    this.setState({ idSortie_impression: { idSortie: reponse[i].id, typeSortie: data.typeSortie } });
                                }
                            } else {
                                this.growl.show({ severity: 'error', summary: 'Error', detail: reponse[i].contenu });
                            }
                        }
                        if (send_success == true) {
                            this.annule_lsortie();
                        }
                        if (this.state.actif_vente_proforma === true) {
                            this.setState({ liste_lsortie: [] });
                        } else {
                            if (this.state.selectedSortie != null && this.state.selectedSortie != undefined) {
                                this.get_liste_lsortie(this.state.selectedSortie.idSortie);
                            }
                        }
                        if ((this.get_type_sortie() !== 'Proforma') && (this.get_type_sortie() !== 'Perte)') && (send_success == true)) {
                            this.setState({ visible_edition_facture: true });
                        }

                    } else {
                        this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec, merci de ressayer' });
                    }
                    this.setState({ showindicator: false });
                });

            } else {
                let type_sortie = this.get_type_sortie();
                if (this.state.tvaSortie === undefined || this.state.tvaSortie === '' || this.state.remiseSortie === undefined || this.state.remiseSortie === '' || this.state.paSortie === undefined || this.state.paSortie === '' || this.state.numFactureSortie === undefined || this.state.numFactureSortie === '') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Renseigner les informations obligatoires' });
                    return;
                } else if (this.state.idCli.typeCli === 'Default' && this.state.selectedSortie.idBoutiq.idBoutiq !== this.state.idBoutiq.value) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Pas d'inter magasin pour ce type de client" });
                    return;
                }
                this.setState({ showindicator: true });
                let date_sortie = moment(this.state.dateSortie, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                let delai_rgl = moment(this.state.delaiRglSortie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let date_validite = moment(this.state.valideSortie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let delai = moment(this.state.delaiLivrSortie, 'DD/MM/YYYY').format('DD/MM/YYYY');

                if (delai === 'Invalid date') {
                    delai = moment(new Date()).format('DD/MM/YYYY');
                }
                if (delai_rgl === 'Invalid date') {
                    delai_rgl = moment(new Date()).format('DD/MM/YYYY');
                }
                if (date_validite === 'Invalid date') {
                    date_validite = moment(new Date()).format('DD/MM/YYYY');
                }

                let data = this.state.selectedSortie;
                if (type_sortie === 'Perte') {
                    type_sortie = this.state.typeSortie.value;
                    if (this.state.typeSortie.value === undefined) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le type de perte' });
                        return;
                    }
                    data.typeSortie = type_sortie;
                }
                data.precompteSortie = this.state.precompteSortie;
                data.cmtSortie=this.state.cmtSortie;
                data.modalitePaySortie = this.state.modalitePaySortie;
                data.garantieSortie = this.state.garantieSortie;
                data.delaiLivrSortie = delai;
                data.dateSortie = date_sortie;
                data.delaiRglSortie = delai_rgl;
                data.tvaSortie = this.state.tvaSortie;
                data.remiseSortie = this.state.remiseSortie;
                data.paSortie = this.state.paSortie;
                data.idAssSortie = this.state.idAssSortie.value;
                data.refSortie = this.state.refSortie;
                data.libSortie = this.state.libSortie;
                data.idCli = { idCli: this.state.idCli.value };
                data.valideSortie = date_validite;
                data.numFactureSortie = this.state.numFactureSortie;
                data.statutSyncSortie = this.myservice.get_statut_sync();
                data.idBoutiqConcerne = { idBoutiq: this.state.idBoutiq.value };
                data.idLfid = this.state.idLfid !== '' && this.state.idLfid !== undefined && this.state.idLfid !== null ? this.state.idLfid.value : '';
                data.nomInteresseSortie = this.state.nomInteresseSortie;
                this.myservice.enreg_sortie(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_lsortie() {
        if (this.state.selectedSortie === null || this.state.selectedSortie === undefined || this.state.qteLsortie === '' || this.state.qteLsortie === undefined || this.state.idArticle === null || this.state.idArticle === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
        } else if (parseFloat(this.state.qteLivrLsortie) > parseFloat(this.state.qteLsortie)) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier la quantité livrée qui ne peut etre supérieure à la quantité commandée' });
        } else if ((this.state.receveurLsortie === '' || this.state.receveurLsortie === undefined) && parseFloat(this.state.qteLivrLsortie) > 0) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le receveur' });
        } else {
            if (this.state.modif_lsortie === false) {
                if (this.myservice.get_statut_offline() == 'true' && this.state.modeRgl.value == 'Compte') {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Vous ne pouvez pas régler par compte en mode offline" });
                    return;
                }
                if (this.state.receveurLsortie === '' || this.state.receveurLsortie === undefined || this.state.selectedSortie === null || this.state.selectedSortie === undefined || this.state.qteLsortie === '' || this.state.qteLsortie === undefined || this.state.idArticle === null || this.state.idArticle === undefined) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une commande' });
                    return;
                }
                let data = { idLsortie: '0', constLsortie: this.state.constLsortie, coefLsortie: this.state.coefLsortie, descLsortie: this.state.descLsortie, statutSyncLsortie: this.myservice.get_statut_sync(), qteLsortie: this.state.qteLsortie, remiseLsortie: this.state.remiseLsortie, qteLivrLsortie: this.state.qteLivrLsortie, puLsortie: this.state.puLsortie, receveurLsortie: this.state.receveurLsortie, puRefLsortie: this.state.puLsortie, idSortie: { idSortie: this.state.selectedSortie.idSortie, idCli: this.state.selectedSortie.idCli.idCli }, idArticle: { idArticle: this.state.idArticle.value }, designationLsortie: this.state.designationLsortie };
                data.liste_detail = this.state.liste_detail;
                for (var i = this.state.liste_lsortie.length; i--;) {
                    if (this.state.idArticle.value == this.state.liste_lsortie[i].idArticle.idArticle) {
                        this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Ce produit existe déja dans la facture sélectionnée, vous ne pouvez que modifier sa quantité' });
                        return;
                    }
                }
                let md_vente = { liste_ligne_sortie: [data], mtRestant: this.state.total_restant };
                let data_reglsortie = null;

                if (parseFloat(this.state.mtpaye) > parseFloat(this.state.mtapayer)) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Attention montant payé supérieur au montant à payer' });
                    return;
                }
                this.setState({ showindicator: true });
                if (parseFloat(this.state.mtpaye) > 0 && 'Facture' === this.get_type_sortie()) {
                    data_reglsortie = { idrglSortie: '0', statutSyncRegl: this.myservice.get_statut_sync(), mtrglSortie: this.state.mtpaye, modeRgl: this.state.modeRgl.value, refRgl: this.state.refRglSortie, idUserRegl: this.state.userconnected }
                    md_vente.reglSortie = data_reglsortie;
                }
                if ('Facture' === this.get_type_sortie()) {
                    md_vente.liste_mv_article = [{ idMv: '0', idRefMv: this.state.selectedSortie.idSortie, qteStock: 0, libMv: 'Augmentation article vente', qteMv: this.state.qteLsortie, iduser: this.state.userconnected.idUser, idEtabl: this.state.userconnected.idEtabl.idEtabl, cmtMv: 'No: ' + this.state.selectedSortie.numFactureSortie + '/' + this.state.selectedSortie.idCli.nomCli, idArticle: { idArticle: this.state.idArticle.value }, idBoutiq: this.state.selectedSortie.idBoutiq }];
                }
                this.setState({ showindicator: true });
                this.myservice.enreg_and_pay_lsortie(md_vente).then(reponse => {
                    for (var i = reponse.length; i--;) {
                        if (reponse[i].code === 200) {
                            this.growl.show({ severity: 'success', summary: 'Success', detail: reponse[i].contenu });
                            this.setState({
                                designationLsortie: '',
                                qteDlsortie: 0,
                                nbDlsortie: 0,
                                qteLivrLsortie: 0,
                                qteLsortie: 0,
                                liste_detail: []
                            })
                        } else {
                            this.growl.show({ severity: 'error', summary: 'Error', detail: reponse[i].contenu });
                        }
                    }
                    this.setState({ showindicator: false });
                    this.get_liste_sortie();
                    this.get_liste_lsortie(this.state.selectedSortie.idSortie);
                    this.setState({ showindicator: false });
                });
            } else {

                let old_qte = this.state.selectedLsortie.qteLsortie;
                let data = this.state.selectedLsortie;
                data.qteLivrLsortie = this.state.qteLivrLsortie;
                data.puLsortie = this.state.puLsortie;
                data.puRefLsortie = this.state.puLsortie;
                data.remiseLsortie = this.state.remiseLsortie;
                data.qteLsortie = this.state.qteLsortie;
                data.descLsortie = this.state.descLsortie;
                data.coefLsortie = this.state.coefLsortie;
                data.constLsortie = this.state.constLsortie;
                data.receveurLsortie = this.state.receveurLsortie;
                data.statutSyncLsortie = this.myservice.get_statut_sync();
                data.idSortie = { idSortie: this.state.selectedSortie.idSortie, idCli: this.state.selectedSortie.idCli.idCli }
                data.liste_detail = this.state.liste_detail;
                data.designationLsortie = this.state.designationLsortie;
                let md_vente = { liste_ligne_sortie: [data] };
                let data_reglsortie = null;

                if (parseFloat(this.state.mtpaye) > parseFloat(this.state.mtapayer) && 'Facture' === this.get_type_sortie()) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Attention montant payé supérieur au montant à payer' });
                    return;
                }

                let old_mt = this.calculer_mt(this.state.selectedLsortie.qteLsortie, this.state.selectedLsortie.puLsortie, this.state.selectedLsortie.remiseLsortie);

                if (old_mt < this.state.mtapayer) {
                    if (parseFloat(this.state.mtpaye) > 0 && 'Facture' === this.get_type_sortie()) {
                        data_reglsortie = { idrglSortie: '0', statutSyncRegl: this.myservice.get_statut_sync(), mtrglSortie: this.state.mtpaye, modeRgl: this.state.modeRgl.value, refRgl: this.state.refRglSortie, idUserRegl: this.state.userconnected }
                        md_vente.reglSortie = data_reglsortie;
                    }
                }

                if ('Facture' === this.get_type_sortie()) {
                    let libMv = '';
                    let qteMv = old_qte - this.state.qteLsortie;
                    if (qteMv < 0) {
                        libMv = 'Augmentation article vente';
                        qteMv = Math.abs(qteMv);
                    } else if (qteMv > 0) {
                        libMv = 'Dimunition article vente';
                    }
                    if (qteMv > 0) {
                        md_vente.liste_mv_article = [{ idMv: '0', idRefMv: this.state.selectedSortie.idSortie, qteStock: 0, libMv: libMv, qteMv: qteMv, iduser: this.state.userconnected.idUser, idEtabl: this.state.userconnected.idEtabl.idEtabl, cmtMv: 'No: ' + this.state.selectedSortie.numFactureSortie + '/' + this.state.selectedSortie.idCli.nomCli, idArticle: this.state.selectedLsortie.idArticle, idBoutiq: this.state.selectedSortie.idBoutiq }];
                    } else {
                        md_vente.liste_mv_article = null;
                    }

                }
                this.setState({ showindicator: true });
                this.myservice.enreg_and_pay_lsortie(md_vente).then(reponse => {
                    for (var i = reponse.length; i--;) {
                        if (reponse[i].code === 200) {
                            this.growl.show({ severity: 'success', summary: 'Success', detail: reponse[i].contenu });
                        } else {
                            this.growl.show({ severity: 'error', summary: 'Error', detail: reponse[i].contenu });
                        }
                    }
                    this.setState({ showindicator: false });
                    this.get_liste_sortie();
                    this.get_liste_lsortie(this.state.selectedSortie.idSortie);
                });
            }
        }
    }

    conf_delete_ligne(data) {
        if (this.state.modif === true && this.state.visible_dlg_vente_restau === true) {
            this.myservice.delete_lsortie(data).then(rep => {
                if (rep.code == 200) {
                    for (var i = this.state.liste_ligne.length; i--;) {
                        if (data.idLsortie === this.state.liste_ligne[i].idLsortie) {
                            this.state.liste_ligne.splice(i, 1);
                            break;
                        }
                    }
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Supprimé avec succès' });
                    this.calculer_total(this.state.liste_ligne);
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec suppression' });
                }
            });

        } else {
            for (var i = this.state.liste_ligne.length; i--;) {
                if (data.idArticle.idArticle == this.state.liste_ligne[i].idArticle.idArticle) {
                    this.state.liste_ligne.splice(i, 1);
                    break;
                }
            }
        }

        this.forceUpdate();
        this.calculer_total(this.state.liste_ligne);
    }

    add_article(source) {
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        this.setState({ num_ligne: this.state.num_ligne + 1 });
        if (this.state.modif === true && this.state.visible_dlg_vente_restau === true) {

            let data = { idLsortie: '0', qteLsortie: this.state.qteLsortie, remiseLsortie: this.state.remiseLsortie, qteLivrLsortie: this.state.qteLivrLsortie, puLsortie: this.state.puLsortie, receveurLsortie: this.state.idCli.label, puRefLsortie: this.state.puLsortie, coefLsortie: this.state.coefLsortie, descLsortie: this.state.descLsortie, constLsortie: this.state.constLsortie, idSortie: { idSortie: this.state.selectedTable.sortie.idSortie, idCli: this.state.selectedTable.sortie.idCli }, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label }, statutSyncLsortie: this.myservice.get_statut_sync(), numTicketLfid: this.state.numTicketLfid, tauxLfid: this.state.prcFid, idLfid: this.state.refLfid };
            for (var i = this.state.liste_ligne.length; i--;) {
                if (this.state.idArticle.value === this.state.liste_ligne[i].idArticle.idArticle) {
                    data = { idLsortie: this.state.liste_ligne[i].idLsortie, qteLsortie: this.state.liste_ligne[i].qteLsortie + this.state.qteLsortie, remiseLsortie: this.state.remiseLsortie, qteLivrLsortie: this.state.liste_ligne[i].qteLivrLsortie + this.state.qteLivrLsortie, puLsortie: this.state.puLsortie, receveurLsortie: this.state.idCli.label, puRefLsortie: this.state.puLsortie, coefLsortie: this.state.coefLsortie, descLsortie: this.state.descLsortie, constLsortie: this.state.constLsortie, idSortie: { idSortie: this.state.selectedTable.sortie.idSortie, idCli: this.state.selectedTable.sortie.idCli }, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label }, numTicketLfid: this.state.numTicketLfid, tauxLfid: this.state.prcFid, idLfid: this.state.refLfid };
                    break;
                }
            }
            this.setState({ showindicator: true });
            this.myservice.enreg_lsortie(data).then(reponse => {
                this.setState({ showindicator: false });
                if (reponse.code === 200) {
                    let trouve = false;
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Ajouté avec succès' });
                    for (var i = this.state.liste_ligne.length; i--;) {
                        if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                            this.state.liste_ligne[i].idLsortie = reponse.id;

                            this.state.liste_ligne[i].coefLsortie = data.coefLsortie;
                            this.state.liste_ligne[i].constLsortie = data.constLsortie;
                            this.state.liste_ligne[i].descLsortie = data.descLsortie;

                            this.state.liste_ligne[i].qteLsortie = data.qteLsortie;
                            this.state.liste_ligne[i].puLsortie = data.puLsortie;
                            this.state.liste_ligne[i].puRefLsortie = data.puLsortie;
                            this.state.liste_ligne[i].remiseLsortie = data.remiseLsortie;
                            this.state.liste_ligne[i].qteLivrLsortie = data.qteLivrLsortie;
                            this.state.liste_ligne[i].montantVente = parseFloat(data.qteLsortie) * parseFloat(data.puLsortie);
                            this.state.liste_ligne[i].montantRemise = parseFloat(data.qteLsortie) * parseFloat(data.remiseLsortie);
                            trouve = true;
                            break;
                        }
                    }
                    if (trouve === false) {
                        this.state.liste_ligne.insert(this.state.num_ligne, { idLsortie: reponse.id, qteLsortie: data.qteLsortie, puLsortie: data.puLsortie, qteLivrLsortie: data.qteLivrLsortie, remiseLsortie: data.remiseLsortie, receveurLsortie: data.receveurLsortie, montantVente: data.qteLsortie * data.puLsortie, montantRemise: data.qteLsortie * this.state.remiseLsortie, puRefLsortie: this.state.puLsortie, coefLsortie: this.state.coefLsortie, descLsortie: this.state.descLsortie, constLsortie: this.state.constLsortie, idArticle: { idArticle: data.idArticle.idArticle, libArticle: data.idArticle.libArticle }, qte_stock: data.qteLsortie, numTicketLfid: this.state.numTicketLfid, tauxLfid: this.state.prcFid, idLfid: this.state.refLfid });
                    }
                    this.calculer_total(this.state.liste_ligne);
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec ajout' });
                }
            });
        } else {
            let trouve = false;
            if (this.state.idArticle == null || this.state.idArticle == '' || this.state.idArticle == undefined) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir un produit' });
            } else if (this.state.qteLsortie == null || this.state.qteLsortie == '' || this.state.qteLsortie == 0) {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir la quantité ' });
            }
            else {
                if (this.state.remiseLsortie == '' || this.state.remiseLsortie == undefined) {
                    this.setState({ remiseLsortie: 0 });
                }
                let qtlivr = this.state.qteLivrLsortie;
                let qt = this.state.qteLsortie;
                if (this.state.qteLsortie === null || this.state.qteLsortie === '' || this.state.qteLsortie === '0') {
                    qt = 1;
                    qtlivr = 1;
                }
                if (parseFloat(this.state.qteLsortie > parseFloat(this.state.idArticle.qte_stock))) {
                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: "Cette quantité n'est pas disponible en stock " });
                }

                if (this.state.liste_ligne.length == 0) {
                    this.state.liste_ligne.insert(this.state.num_ligne, { idLsortie: '0', liste_detail: this.state.liste_detail, designationLsortie: this.state.designationLsortie, qteLsortie: qt, puLsortie: this.state.puLsortie, qteLivrLsortie: qtlivr, remiseLsortie: this.state.remiseLsortie, receveurLsortie: this.state.receveurLsortie, montantVente: qt * this.state.puLsortie * this.state.coefLsortie * this.state.constLsortie, montantRemise: qt * this.state.remiseLsortie, puRefLsortie: this.state.puLsortie, coefLsortie: this.state.coefLsortie, descLsortie: this.state.descLsortie, constLsortie: this.state.constLsortie, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label }, qte_stock: this.state.idArticle.qte_stock, numTicketLfid: this.state.numTicketLfid, tauxLfid: this.state.prcFid, idLfid: this.state.refLfid });
                } else {
                    for (var i = this.state.liste_ligne.length; i--;) {
                        if (this.state.idArticle.value == this.state.liste_ligne[i].idArticle.idArticle) {
                            trouve = true;
                            this.state.liste_ligne[i].coefLsortie = parseFloat(this.state.coefLsortie);
                            this.state.liste_ligne[i].constLsortie = parseFloat(this.state.constLsortie);
                            this.state.liste_ligne[i].descLsortie = this.state.descLsortie;
                            this.state.liste_ligne[i].remiseLsortie = parseFloat(this.state.remiseLsortie);
                            this.state.liste_ligne[i].qteLsortie = parseFloat(this.state.liste_ligne[i].qteLsortie) + parseFloat(qt);
                            this.state.liste_ligne[i].qteLivrLsortie = parseFloat(this.state.liste_ligne[i].qteLivrLsortie) + parseFloat(qtlivr);
                            this.state.liste_ligne[i].montantVente = parseFloat(this.state.liste_ligne[i].qteLsortie) * parseFloat(this.state.liste_ligne[i].puLsortie) * parseFloat(this.state.liste_ligne[i].coefLsortie) * parseFloat(this.state.liste_ligne[i].constLsortie);
                            this.state.liste_ligne[i].montantRemise = parseFloat(this.state.liste_ligne[i].qteLsortie) * parseFloat(this.state.liste_ligne[i].remiseLsortie);
                        }
                    }
                    if (trouve == false) {
                        this.state.liste_ligne.insert(this.state.num_ligne, { idLsortie: '0', liste_detail: this.state.liste_detail, designationLsortie: this.state.designationLsortie, qteLsortie: qt, puLsortie: this.state.puLsortie, qteLivrLsortie: qtlivr, remiseLsortie: this.state.remiseLsortie, receveurLsortie: this.state.receveurLsortie, montantVente: qt * this.state.puLsortie * this.state.coefLsortie * this.state.constLsortie, montantRemise: qt * this.state.remiseLsortie, puRefLsortie: this.state.puLsortie, coefLsortie: this.state.coefLsortie, descLsortie: this.state.descLsortie, constLsortie: this.state.constLsortie, idArticle: { idArticle: this.state.idArticle.value, libArticle: this.state.idArticle.label }, qte_stock: this.state.idArticle.qte_stock, numTicketLfid: this.state.numTicketLfid, tauxLfid: this.state.prcFid, idLfid: this.state.refLfid });
                    }
                }
                this.forceUpdate();
                this.setState({ disable_val_sortie: false });
                this.calculer_total(this.state.liste_ligne);
                if (this.state.visible_dlg_vente_restau === false) {
                    if (source === 'cb') {
                        this.setState({ code_barre: '' }, () => {
                            document.getElementById("input_code_barre").focus();
                        })
                    } else {
                        document.getElementById("new_dp_prod_add").click();
                    }
                }
                this.setState({ qteLsortie: 1, liste_detail: [] });
            }
        }
    }

    actionTemplate(rowData, column) {
        let items = [];
        if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
            if (rowData.typeSortie === 'Facture') {
                items = [
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-times',
                        command: (e) => {
                            this.conf_delete_sortie(rowData);
                        }
                    },
                    {
                        label: 'Modifier',
                        icon: 'pi pi-pencil',
                        command: (e) => {
                            this.edit_sortie(rowData);
                        }
                    },
                    {
                        label: 'Facture',
                        icon: 'pi pi-file',
                        command: (e) => {
                            this.conf_print_facture(rowData, 'A4');
                        }
                    },
                    {
                        label: 'Ticket',
                        icon: 'pi pi-print',
                        command: (e) => {
                            this.conf_print_facture(rowData, 'Ticket');
                        }
                    },
                    {
                        label: 'BL',
                        icon: 'pi pi-file',
                        command: (e) => {
                            this.show_dlg_Bl(rowData);
                        }
                    },
                    {
                        label: 'Règlement',
                        icon: 'pi pi-briefcase',
                        command: (e) => {
                            this.show_dlg_reglSortie(rowData);
                        }
                    },
                    {
                        label: 'Envoyer mail',
                        icon: 'pi pi-envelope',
                        command: (e) => {
                            this.conf_send_facture(rowData, 'A4');
                        }
                    },
                    {
                        label: 'Traces',
                        icon: 'pi pi-search',
                        command: (e) => {
                            this.show_trace(rowData);
                        }
                    }
                ]
                if (rowData.tagSortie == 'Normal') {
                    items.push(
                        {
                            label: 'Taguer perdu',
                            icon: 'pi pi-bookmark',
                            command: (e) => {
                                rowData.tagSortie = 'Perdu';
                                this.taguer_sortie(rowData);
                            }
                        },
                        {
                            label: 'Taguer litigeux',
                            icon: 'pi pi-bell',
                            command: (e) => {
                                rowData.tagSortie = 'Litige';
                                this.taguer_sortie(rowData);
                            }
                        },
                    )
                } else {
                    items.push(
                        {
                            label: 'Taguer normal',
                            icon: 'pi pi-check',
                            command: (e) => {
                                rowData.tagSortie = 'Normal';
                                this.taguer_sortie(rowData);
                            }
                        }
                    )
                }
                if (rowData.idBoutiq.idBoutiq == rowData.idBoutiqConcerne.idBoutiq) {
                    items.push(
                        {
                            label: 'Livraison',
                            icon: 'pi pi-external-link',
                            command: (e) => {
                                this.show_dlg_livraison();
                                this.get_liste_livraison_lsortie_by_sortie(rowData.idSortie);
                            }
                        }
                    )
                }


            } else if (rowData.typeSortie === 'Proforma') {
                items = [
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-times',
                        command: (e) => {
                            this.conf_delete_sortie(rowData);
                        }
                    },
                    {
                        label: 'Modifier',
                        icon: 'pi pi-pencil',
                        command: (e) => {
                            this.edit_sortie(rowData);
                        }
                    },
                    {
                        label: 'Facture',
                        icon: 'pi pi-file',
                        command: (e) => {
                            this.conf_print_facture(rowData, 'A4');
                        }
                    },
                    {
                        label: 'Ticket',
                        icon: 'pi pi-print',
                        command: (e) => {
                            this.conf_print_facture(rowData, 'Ticket');
                        }
                    },
                    {
                        label: 'Traces',
                        icon: 'pi pi-search',
                        command: (e) => {
                            this.show_trace(rowData);
                        }
                    }
                ]
            } else {
                items = [
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-times',
                        command: (e) => {
                            this.conf_delete_sortie(rowData);
                        }
                    },
                    {
                        label: 'Modifier',
                        icon: 'pi pi-pencil',
                        command: (e) => {
                            this.edit_sortie(rowData);
                        }
                    },
                    {
                        label: 'Facture ',
                        icon: 'pi pi-file',
                        command: (e) => {
                            this.conf_print_facture(rowData, 'A4');
                        }
                    },
                    {
                        label: 'Ticket',
                        icon: 'pi pi-print',
                        command: (e) => {
                            this.conf_print_facture(rowData, 'Ticket');
                        }
                    },
                    {
                        label: 'Traces',
                        icon: 'pi pi-search',
                        command: (e) => {
                            this.show_trace(rowData);
                        }
                    }

                ]
            }

        } else {

            //   *******  PROFILS USINE  **************          
            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Usine') {
                if (this.state.userconnected.profilUser == 'Administrateur') {
                    if (rowData.typeSortie === 'Facture') {
                        items = [
                            {
                                label: 'Modifier',
                                icon: 'pi pi-pencil',
                                command: (e) => {
                                    this.edit_sortie(rowData);
                                }
                            },
                            {
                                label: 'Facture',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Ticket',
                                icon: 'pi pi-print',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'Ticket');
                                }
                            },
                            {
                                label: 'BL',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.show_dlg_Bl(rowData);
                                }
                            },
                            {
                                label: 'Envoyer mail',
                                icon: 'pi pi-envelope',
                                command: (e) => {
                                    this.conf_send_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Traces',
                                icon: 'pi pi-search',
                                command: (e) => {
                                    this.show_trace(rowData);
                                }
                            }
                        ]
                        if (rowData.idBoutiq.idBoutiq == rowData.idBoutiqConcerne.idBoutiq) {
                            items.push(
                                {
                                    label: 'Livraison',
                                    icon: 'pi pi-external-link',
                                    command: (e) => {
                                        this.show_dlg_livraison();
                                        this.get_liste_livraison_lsortie_by_sortie(rowData.idSortie);
                                    }
                                }
                            )
                        }
                        if (rowData.tagSortie == 'Litige' || rowData.tagSortie == 'Perdu') {
                            for (var i = items.length; i--;) {
                                if (items[i].label == 'Modifier' || items[i].label === 'BL' || items[i].label === 'Règlement') {
                                    items.splice(i, 1);
                                }
                            }
                        }
                    } else if (rowData.typeSortie === 'Proforma') {
                        items = [
                            {
                                label: 'Supprimer',
                                icon: 'pi pi-times',
                                command: (e) => {
                                    this.conf_delete_sortie(rowData);
                                }
                            },
                            {
                                label: 'Modifier',
                                icon: 'pi pi-pencil',
                                command: (e) => {
                                    this.edit_sortie(rowData);
                                }
                            },
                            {
                                label: 'Facture proforma',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Ticket',
                                icon: 'pi pi-print',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'Ticket');
                                }
                            },
                            {
                                label: 'Envoyer mail',
                                icon: 'pi pi-envelope',
                                command: (e) => {
                                    this.conf_send_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Traces',
                                icon: 'pi pi-search',
                                command: (e) => {
                                    this.show_trace(rowData);
                                }
                            }
                        ]
                    } else {
                        items = [
                            {
                                label: 'Supprimer',
                                icon: 'pi pi-times',
                                command: (e) => {
                                    this.conf_delete_sortie(rowData);
                                }
                            },
                            {
                                label: 'Modifier',
                                icon: 'pi pi-pencil',
                                command: (e) => {
                                    this.edit_sortie(rowData);
                                }
                            },
                            {
                                label: 'Facture',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Ticket',
                                icon: 'pi pi-print',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'Ticket');
                                }
                            },
                            {
                                label: 'Traces',
                                icon: 'pi pi-search',
                                command: (e) => {
                                    this.show_trace(rowData);
                                }
                            }

                        ]
                    }
                } else {
                    if (rowData.typeSortie === 'Facture') {
                        items = [
                            {
                                label: 'Modifier',
                                icon: 'pi pi-pencil',
                                command: (e) => {
                                    this.edit_sortie(rowData);
                                }
                            },
                            {
                                label: 'Facture',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Ticket',
                                icon: 'pi pi-print',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'Ticket');
                                }
                            },
                            {
                                label: 'BL',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.show_dlg_Bl(rowData);
                                }
                            },
                            {
                                label: 'Règlement',
                                icon: 'pi pi-briefcase',
                                command: (e) => {
                                    this.show_dlg_reglSortie(rowData);
                                }
                            },
                            {
                                label: 'Envoyer mail',
                                icon: 'pi pi-envelope',
                                command: (e) => {
                                    this.conf_send_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Traces',
                                icon: 'pi pi-search',
                                command: (e) => {
                                    this.show_trace(rowData);
                                }
                            }
                        ]
                        if (rowData.idBoutiq.idBoutiq == rowData.idBoutiqConcerne.idBoutiq) {
                            items.push(
                                {
                                    label: 'Livraison',
                                    icon: 'pi pi-external-link',
                                    command: (e) => {
                                        this.show_dlg_livraison();
                                        this.get_liste_livraison_lsortie_by_sortie(rowData.idSortie);
                                    }
                                }
                            )
                        }
                        if (rowData.tagSortie == 'Litige' || rowData.tagSortie == 'Perdu') {
                            for (var i = items.length; i--;) {
                                if (items[i].label == 'Modifier' || items[i].label === 'BL' || items[i].label === 'Règlement') {
                                    items.splice(i, 1);
                                }
                            }
                        }
                    } else if (rowData.typeSortie === 'Proforma') {
                        items = [
                            {
                                label: 'Supprimer',
                                icon: 'pi pi-times',
                                command: (e) => {
                                    this.conf_delete_sortie(rowData);
                                }
                            },
                            {
                                label: 'Modifier',
                                icon: 'pi pi-pencil',
                                command: (e) => {
                                    this.edit_sortie(rowData);
                                }
                            },
                            {
                                label: 'Facture proforma',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Ticket',
                                icon: 'pi pi-print',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'Ticket');
                                }
                            },
                            {
                                label: 'Envoyer mail',
                                icon: 'pi pi-envelope',
                                command: (e) => {
                                    this.conf_send_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Traces',
                                icon: 'pi pi-search',
                                command: (e) => {
                                    this.show_trace(rowData);
                                }
                            }
                        ]
                    } else {
                        items = [
                            {
                                label: 'Supprimer',
                                icon: 'pi pi-times',
                                command: (e) => {
                                    this.conf_delete_sortie(rowData);
                                }
                            },
                            {
                                label: 'Modifier',
                                icon: 'pi pi-pencil',
                                command: (e) => {
                                    this.edit_sortie(rowData);
                                }
                            },
                            {
                                label: 'Facture',
                                icon: 'pi pi-file',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'A4');
                                }
                            },
                            {
                                label: 'Ticket',
                                icon: 'pi pi-print',
                                command: (e) => {
                                    this.conf_print_facture(rowData, 'Ticket');
                                }
                            },
                            {
                                label: 'Traces',
                                icon: 'pi pi-search',
                                command: (e) => {
                                    this.show_trace(rowData);
                                }
                            }

                        ]
                    }
                }
                //   *******  FIN PROFILs USINE  **************          
            } else {
                if (rowData.typeSortie === 'Facture') {
                    items = [
                        {
                            label: 'Modifier',
                            icon: 'pi pi-pencil',
                            command: (e) => {
                                this.edit_sortie(rowData);
                            }
                        },
                        {
                            label: 'Facture',
                            icon: 'pi pi-file',
                            command: (e) => {
                                this.conf_print_facture(rowData, 'A4');
                            }
                        },
                        {
                            label: 'Ticket',
                            icon: 'pi pi-print',
                            command: (e) => {
                                this.conf_print_facture(rowData, 'Ticket');
                            }
                        },
                        {
                            label: 'BL',
                            icon: 'pi pi-file',
                            command: (e) => {
                                this.show_dlg_Bl(rowData);
                            }
                        },
                        {
                            label: 'Règlement',
                            icon: 'pi pi-briefcase',
                            command: (e) => {
                                this.show_dlg_reglSortie(rowData);
                            }
                        },
                        {
                            label: 'Envoyer mail',
                            icon: 'pi pi-envelope',
                            command: (e) => {
                                this.conf_send_facture(rowData, 'A4');
                            }
                        },
                        {
                            label: 'Traces',
                            icon: 'pi pi-search',
                            command: (e) => {
                                this.show_trace(rowData);
                            }
                        }
                    ]
                    if (rowData.idBoutiq.idBoutiq == rowData.idBoutiqConcerne.idBoutiq) {
                        items.push(
                            {
                                label: 'Livraison',
                                icon: 'pi pi-external-link',
                                command: (e) => {
                                    this.show_dlg_livraison();
                                    this.get_liste_livraison_lsortie_by_sortie(rowData.idSortie);
                                }
                            }
                        )
                    }
                    if (rowData.tagSortie == 'Litige' || rowData.tagSortie == 'Perdu') {
                        for (var i = items.length; i--;) {
                            if (items[i].label == 'Modifier' || items[i].label === 'BL' || items[i].label === 'Règlement') {
                                items.splice(i, 1);
                            }
                        }
                    }
                } else if (rowData.typeSortie === 'Proforma') {
                    items = [
                        {
                            label: 'Supprimer',
                            icon: 'pi pi-times',
                            command: (e) => {
                                this.conf_delete_sortie(rowData);
                            }
                        },
                        {
                            label: 'Modifier',
                            icon: 'pi pi-pencil',
                            command: (e) => {
                                this.edit_sortie(rowData);
                            }
                        },
                        {
                            label: 'Facture proforma',
                            icon: 'pi pi-file',
                            command: (e) => {
                                this.conf_print_facture(rowData, 'A4');
                            }
                        },
                        {
                            label: 'Ticket',
                            icon: 'pi pi-print',
                            command: (e) => {
                                this.conf_print_facture(rowData, 'Ticket');
                            }
                        },
                        {
                            label: 'Vendre',
                            icon: 'pi pi-shopping-cart',
                            command: (e) => {
                                this.vente_proforma(rowData);
                            }
                        },
                        {
                            label: 'Envoyer mail',
                            icon: 'pi pi-envelope',
                            command: (e) => {
                                this.conf_send_facture(rowData, 'A4');
                            }
                        },
                        {
                            label: 'Traces',
                            icon: 'pi pi-search',
                            command: (e) => {
                                this.show_trace(rowData);
                            }
                        }
                    ]
                } else {
                    items = [
                        {
                            label: 'Supprimer',
                            icon: 'pi pi-times',
                            command: (e) => {
                                this.conf_delete_sortie(rowData);
                            }
                        },
                        {
                            label: 'Modifier',
                            icon: 'pi pi-pencil',
                            command: (e) => {
                                this.edit_sortie(rowData);
                            }
                        },
                        {
                            label: 'Facture',
                            icon: 'pi pi-file',
                            command: (e) => {
                                this.conf_print_facture(rowData, 'A4');
                            }
                        },
                        {
                            label: 'Ticket',
                            icon: 'pi pi-print',
                            command: (e) => {
                                this.conf_print_facture(rowData, 'Ticket');
                            }
                        },
                        {
                            label: 'Traces',
                            icon: 'pi pi-search',
                            command: (e) => {
                                this.show_trace(rowData);
                            }
                        }

                    ]
                }
            }

        };
        if (this.myservice.get_statut_offline() == 'true' && rowData.statutSyncSortie == 'Envoyé') {
            for (var i = items.length; i--;) {
                if (items[i].label === 'Supprimer' || items[i].label === 'Vendre' || items[i].label === 'BL' || items[i].label === 'Livraison') {
                    items.splice(i, 1);
                }
            }
        }
        items.push(
            {
                label: 'Facture en XLS',
                icon: 'pi pi-file',
                command: (e) => {
                    this.conf_print_facture(rowData, 'XLS');
                }
            }
        )
        if (this.state.userconnected.idEtabl.codeEtabl == 'KEBALY' && (rowData.typeSortie === 'Proforma' || rowData.typeSortie === 'Facture')) {
            items.push(
                {
                    label: rowData.typeSortie + ' sans entête',
                    icon: 'pi pi-file',
                    command: (e) => {
                        this.conf_print_facture(rowData, 'Sans_entete');
                    }
                },
                {
                    label: 'Facture avec photo',
                    icon: 'pi pi-image',
                    command: (e) => {
                        this.conf_print_facture(rowData, 'A4_photo');
                    }
                },
            )
        }
        let label = rowData.ordre + '';
        let cl = '';
        if (rowData.statutSyncSortie == 'Envoyé') {
            cl = "p-button-success";
        } else {
            cl = "p-button-warning";
        }
        if (rowData.typeSortie === 'Facture' || rowData.typeSortie === 'Sortie peremption' || rowData.typeSortie === 'Sortie defectueu') {
            return <div>
                <SplitButton className={cl} label={label} onClick={(e) => this.edit_sortie(rowData)} icon="pi pi-pencil" model={items}></SplitButton>
            </div>
        } else {
            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq == 'Usine') {
                if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                    return <div>
                        <SplitButton className={cl} label={label} onClick={(e) => this.vente_proforma(rowData)} icon="pi pi-shopping-cart" model={items}></SplitButton>
                    </div>
                } else {
                    return <div>
                        <SplitButton className={cl} label={label} onClick={(e) => this.edit_sortie(rowData)} icon="pi pi-pencil" model={items}></SplitButton>
                    </div>
                }
            } else {
                return <SplitButton className={cl} label={label} onClick={(e) => this.vente_proforma(rowData)} icon="pi pi-shopping-cart" model={items}></SplitButton>
            }
        }
    }

    actionTemplate_corbeille(rowData, column) {
        return <div className="p-grid" >
            <div className="p-col"><Button onClick={(e) => this.restaurer_sortie(rowData)} style={{ width: 50, height: 25 }} icon="pi pi-upload" /></div>
        </div>
    }

    actionTemplate_lsortie(rowData, column) {
        let disable_button_edit = false;
        let disable_button_del = false;
        if (this.myservice.get_statut_offline() == 'true' && rowData.statutSyncLsortie == 'Envoyé') {
            disable_button_del = true;
        } else {
            if (this.state.selectedSortie.idBoutiq.idBoutiq !== this.state.selectedSortie.idBoutiqConcerne.idBoutiq || this.state.selectedSortie.typeSortie === 'Transfert') {
                disable_button_del = true;
                disable_button_edit = true;
                if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                    disable_button_del = false;
                    disable_button_edit = false;
                }
            } else {
                if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && rowData.disable_modif == "true") {
                    disable_button_del = true;
                    disable_button_edit = true;
                }
            };
        }
        let cl = '';
        if (rowData.statutSyncLsortie == 'Envoyé') {
            cl = "p-button-success";
        } else {
            cl = "p-button-warning";
        }

        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button_edit} onClick={(e) => this.edit_lsortie(rowData)} className={cl} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button_del} onClick={(e) => this.conf_delete_lsortie(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -11 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_bl(rowData, column) {
        let disable_button = false;
        if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.selectedSortie.disable_modif_bl == "true") {
            disable_button = true;
        }
        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.edit_bl(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button} onClick={(e) => this.conf_delete_bl(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -12 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_print_bl(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -12 }} icon="pi pi-print" /></div>
        </div>
    }

    actionTemplate_reglSortie(rowData, column) {
        let disable_button_del = false;
        if (this.myservice.get_statut_offline() == 'true' && rowData.statutSyncRegl == 'Envoyé') {
            disable_button_del = true;
        }
        if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.selectedSortie.disable_modif_bl == "true") {
            disable_button_del = true;
        }
        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable_button_del} onClick={(e) => this.edit_reglSortie(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disable_button_del} onClick={(e) => this.conf_delete_reglSortie(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_print_recu(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-print" /></div>
        </div>
    }

    actionTemplate_ligne(rowData, column) {
        let disable = false;
        if (this.state.selectedTable !== null && this.state.selectedTable !== undefined) {
            if (this.state.selectedTable.sortie !== null && this.state.selectedTable.sortie !== undefined) {
                if (this.state.selectedTable.sortie.statutSyncSortie == 'Envoyé') {
                    disable = true;
                }
            }
        }

        return <div className="p-grid" >
            <div className="p-col"><Button disabled={disable} onClick={(e) => this.conf_delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    conf_delete_bl(rowData) {
        let data = { numBl: rowData.numBl };
        this.myservice.delete_livraison_sortie(data).then(data => {
            this.resultat_livraison(data.code, data.contenu);
            this.myservice.get_liste_livraison_sortie_by_sortie(this.state.selectedSortie.idSortie).then(data => this.setState({ liste_livraison_sortie: data }));
        });
    }

    delete_sortie() {
        if (this.state.selectedSortie == null || this.state.selectedSortie == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            let data = { idSortie: this.state.selectedSortie.idSortie };
            this.myservice.delete_sortie(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_lsortie() {
        if (this.state.selectedLsortie === null || this.state.selectedLsortie === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            let ligne = { idLsortie: this.state.selectedLsortie.idLsortie, qteLsortie: this.state.selectedLsortie.qteLsortie, idArticle: this.state.selectedLsortie.idArticle, idSortie: this.state.selectedSortie };
            this.myservice.delete_lsortie(ligne).then(data => this.resultat_lsortie(data.code, data.contenu));
        }
    }

    conf_delete_sortie(data) {
        if (data !== null && data !== undefined) {
            if (data.typeSortie === 'Transfert') {
                this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez pas supprimer cette ligne' });
                return;
            };
            this.setState({ selectedSortie: data });
            this.setState({ msg: data.numFactureSortie });
            this.setState({ visible: true });
        }
    }

    conf_delete_assure(rowData) {
        if (rowData === null || rowData === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_assure(rowData).then(data => this.resultat_assure(data.code, data.contenu, 'ASS'));
        }
    }

    conf_delete_parent(rowData) {
        if (rowData === null || rowData === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.delete_parent_assure(rowData).then(data => this.resultat_assure(data.code, data.contenu, 'PARENT'));
        }
    }

    conf_delete_lsortie(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedLsortie: data });
            this.setState({ msg: data.idArticle.libArticle });
            this.setState({ visible_lsortie: true });
        }
    }

    show_dlg_reglSortie_bloc() {
        this.setState({ visible_dlg_reglSortie_bloc: true });
    }

    show_dlg_corbeille() {
        this.setState({ visible_dlg_corbeille_sortie: true });
    }

    show_new_client_dlg() {
        this.setState({ visible_dlg_client: true });
    }

    show_new_sortie_dlg() {
        this.setState({ visible_new_sortie_dlg: true });
        this.setState({ modif: false });
    }

    show_lsortie_dlg() {
        this.setState({ visible_dlg_lsortie: true });
        if (this.state.selectedSortie.idCli.nomCli.includes('UAGCP')) {
            this.get_liste_article_spec();
            this.setState({ qteLsortie: 0, qteLivrLsortie: 0 });
        }
        this.setState({ modif_lsortie: false });
        this.setState({
            disable_prod: false,
            qteDlsortie: 0,
            qteLivrLsortie: 0,
            designationLsortie: '',
            descDlsortie: '',
            descLsortie: '',
            liste_detail: [],
            total_restant: 0,
            total_detail: 0,
            puLsortie: 0,
            remiseLsortie: 0,
            idArticle: null
        });
        this.setState({ receveurLsortie: this.state.selectedSortie.idCli.nomCli, liste_detail: [] });
    }

    show_dlg_livraison() {
        this.setState({ dateBl: new Date() });
        this.setState({ receveurBl: this.state.selectedSortie.idCli.nomCli });
        this.setState({ visible_dlg_livraison: true });
    }

    show_dlg_assure() {
        this.get_liste_parent_assure();
        this.setState({ visible_dlg_assure: true });
    }

    show_dlg_Bl(rowData) {
        this.myservice.get_liste_livraison_sortie_by_sortie(rowData.idSortie).then(data => this.setState({ liste_livraison_sortie: data }));
        this.setState({ visible_dlg_Bl: true });
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_liste_sortie_for_reglement() {
        let type_sortie = this.get_type_sortie();
        this.setState({ showindicator: true });
        let rid_client = 'Tous';
        let rid_parent_assure = 'Tous';
        let rid_assure = 'Tous';
        let rtype_client = 'Tous';
        let spd1 = moment(this.state.pd1).format('DD-MM-YYYY');
        let spd2 = moment(this.state.pd2).format('DD-MM-YYYY');
        if (this.state.ridClient != undefined || this.state.ridClient != null) {
            rid_client = this.state.ridClient.value;
        }
        if (this.state.rtypeClient != undefined || this.state.rtypeClient != null) {
            rtype_client = this.state.rtypeClient.value;
        }
        if (this.state.ridParent_assure != undefined || this.state.ridParent_assure != null) {
            rid_parent_assure = this.state.ridParent_assure.value;
        }
        if (this.state.ridAssure != undefined || this.state.ridAssure != null) {
            rid_assure = this.state.ridAssure.value;
        }
        this.setState({ liste_sortie_for_reglement_selecteds: [] });
        this.setState({ total_reglement_format: '0' });
        this.myservice.get_liste_sortie_by_criteria(spd1, spd2, 'Tous', 'Tous', 'Non regle', 'Tous', rid_client, rid_parent_assure, rid_assure, 'Tous', type_sortie, rtype_client).then(data => this.setState({ liste_sortie_for_reglement: data, showindicator: false }));
    }

    calculer_total(liste) {
        let _mtpaye = this.state.mtpaye;
        let _montant_interesse_format = "0";
        let _montant_interesse = 0;
        let _part_interesse = (100 - this.state.idAssSortie.remiseCli - this.state.idAssSortie.remiseAss - this.state.idAssSortie.pchargeAss);
        let _montant_brut = 0;
        let _remise_total = 0;
        let _montant_total = 0;
        let _montant_total_format = "0";
        let _remise_total_format = "0";
        let _nbligne_vente = 0;
        let _montant_brut_format = "0";
        let _remiseSortie = this.state.val_remise;
        let _mtTVA = 0;
        let _mtapayer = 0;
        let _total_restant = '0';
        let _mtapayer_format = '0';

        if (liste != null) {
            for (var i = liste.length; i--;) {
                if (liste[i].coefLsortie == 0 || liste[i].coefLsortie == undefined) {
                    liste[i].coefLsortie = 1;
                }
                if (liste[i].constLsortie == 0 || liste[i].constLsortie == undefined) {
                    liste[i].constLsortie = 1;
                }
                liste[i].montantVente = (liste[i].qteLsortie * liste[i].puLsortie * liste[i].coefLsortie) / liste[i].constLsortie;
                liste[i].montantRemise = liste[i].qteLsortie * liste[i].remiseLsortie;
                if (this.state.idCli.typeCli === 'Default' || this.state.visible_dlg_vente_restau === true) {
                    this.state.liste_ligne[i].qteLivrLsortie = this.state.liste_ligne[i].qteLsortie;
                }
                _montant_brut += liste[i].montantVente;
                _remise_total += liste[i].montantRemise;
            }
            _nbligne_vente = liste.length;
        }
        if (this.state.mode_remise.value == 'PRC') {
            _remiseSortie = _montant_brut * 0.01 * this.state.val_remise;
        }
        _montant_total = _montant_brut - _remiseSortie - _remise_total;
        _mtTVA = this.formaterMontant(0.01 * this.state.tvaSortie * _montant_total);
        _montant_total_format = this.formaterMontant(_montant_total);
        _mtapayer = Math.round(_montant_total + 0.01 * this.state.tvaSortie * _montant_total);
        _mtapayer = _mtapayer - 0.01 * this.state.precompteSortie * _mtapayer;
        _mtapayer_format = this.formaterMontant(_mtapayer);
        _remise_total_format = this.formaterMontant(parseFloat(_remise_total) + parseFloat(_remiseSortie));

        _montant_interesse = 0.01 * _part_interesse * _mtapayer;
        _montant_interesse_format = this.formaterMontant(_montant_interesse);
        _montant_brut_format = this.formaterMontant(_montant_brut);

        _mtpaye = Math.round(_montant_interesse);
        if (this.state.idCli !== null && this.state.idCli !== undefined) {
            if (this.state.idCli.label === 'COMPTOIR') {
                _mtpaye = _mtapayer;
                this.setState({ mtpaye: _mtapayer });
            } else {
                if (this.state.userconnected.idEtabl.codeEtabl == 'KEBALY') {
                    _mtpaye = 0;
                    this.setState({ mtpaye: _mtpaye });
                } else if (this.state.userconnected.idEtabl.codeEtabl === 'PHCAM') {
                    let nom = this.state.idCli.label;
                    if (nom.includes('PARTICULIER') || nom.includes('AMBASSADE DU MALI')) {
                        _mtpaye = 0;
                        this.setState({ mtpaye: _mtpaye });
                    } else {
                        this.setState({ mtpaye: _mtpaye });
                    }
                }
                else {
                    this.setState({ mtpaye: _mtpaye });
                }
            }
        }
        this.setState({ remiseSortie: _remiseSortie });
        _total_restant = _mtapayer - _mtpaye;
        this.setState({ montant_interesse_format: _montant_interesse_format });
        this.setState({ total_restant: _total_restant });
        this.setState({ mtapayer_format: _mtapayer_format });
        this.setState({ remise_total_format: _remise_total_format });
        this.setState({ mtTVA: _mtTVA });
        this.setState({ montant_brut_format: _montant_brut_format });
        this.setState({ mtapayer: _mtapayer });
    }

    calculer_mt(qte, pu, remise) {
        let mt = 0;
        if (this.state.modif_lsortie === true) {
            let _montant_total_old = parseFloat(this.state.selectedLsortie.qteLsortie) * (parseFloat(this.state.selectedLsortie.puLsortie) - parseFloat(this.state.selectedLsortie.remiseLsortie));
            let mt_old = _montant_total_old + 0.01 * this.state.selectedSortie.tvaSortie * _montant_total_old;

            let _montant_total = parseFloat(qte) * (parseFloat(pu) - parseFloat(remise));
            let mt_new = _montant_total + 0.01 * this.state.selectedSortie.tvaSortie * _montant_total;

            if (mt_old > mt_new) {
                mt = 0;
            } else {
                mt = mt_new - mt_old;
            }
        } else {
            let _montant_total = parseFloat(qte) * (parseFloat(pu) - parseFloat(remise));
            mt = _montant_total + 0.01 * this.state.selectedSortie.tvaSortie * _montant_total;
            mt = mt - 0.01 * this.state.selectedSortie.precompteSortie * mt
        }
        this.setState({ total_restant: mt, mtpaye: mt, mtapayer: mt });
        return mt;
    }

    calculer_restant() {
        this.setState({ total_restant: this.state.mtapayer - this.state.mtpaye });
    }

    resultat_assure(code, contenu, cible) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            if (cible === 'ASS') {
                this.annule_assure();
                this.get_liste_assure();
            } else {
                this.annule_parent_assure();
                this.get_liste_parent_assure();
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    annule_parent_assure() {
        this.setState({ nomp: '' });
        this.setState({ remiseAss: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annule_parent_assure() {
        this.setState({ nomParentAss: '' });
        this.setState({ numPoliceParentAss: 0 });
        this.setState({ modif_parent: false });
        this.forceUpdate();
    }

    edit_parent(data) {
        if (data != null && data != undefined) {
            this.setState({ adrParentAss: data.adrParentAss });
            this.setState({ nomParentAss: data.nomParentAss });
            this.setState({ modif_parent: true });
            this.setState({ numPoliceParentAss: data.numPoliceParentAss });
            this.setState({ selectedParentAssure: data });
            this.setState({ remiseParentAss: data.remiseParentAss });
            let client = this.state.lclient.filter(function (el) {
                return el.value === data.idCli.idCli;
            })[0];

            this.setState({ idCli: client });
        }
    }

    actionTemplate_parent(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_parent(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_parent(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    enreg_parent_assure() {
        if (this.state.idCli === undefined || this.state.idCli === "" || this.state.nomParentAss === undefined || this.state.nomParentAss === "" || this.state.remiseParentAss === "") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif_parent == false) {
                let data = { idParentAss: '0', statutSyncParentAss: this.myservice.get_statut_sync(), nomParentAss: this.state.nomParentAss, numPoliceParentAss: this.state.numPoliceParentAss, adrParentAss: this.state.adrParentAss, remiseParentAss: this.state.remiseParentAss, etatParentAss: 'En cours', idCli: { idCli: this.state.idCli.value } };
                this.myservice.enreg_parent_assure(data).then(data => this.resultat_assure(data.code, data.contenu, 'PARENT'));
            } else {
                let data = this.state.selectedParentAssure;
                data.nomParentAss = this.state.nomParentAss;
                data.remiseParentAss = this.state.remiseParentAss;
                data.idCli = { idCli: this.state.idCli.value };
                data.numPoliceParentAss = this.state.numPoliceParentAss;
                this.myservice.enreg_parent_assure(data).then(rep => this.resultat_assure(rep.code, rep.contenu, 'PARENT'));
            }
        }
    }

    annule_assure() {
        this.setState({ nomAss: '' });
        this.setState({ matrAss: '' });
        this.setState({ numPoliceAss: '' });
        this.setState({ pchargeAss: '' });
        this.setState({ adrAss: '' });
        this.setState({ remiseAss: 0 });
        this.setState({ modif_assure: false });
        this.forceUpdate();
    }

    edit_assure(data) {
        if (data != null && data != undefined) {
            this.setState({ matrAss: data.matrAss });
            this.setState({ nomAss: data.nomAss });
            this.setState({ modif_assure: true });
            this.setState({ pchargeAss: data.pchargeAss });
            this.setState({ remiseAss: data.remiseAss });
            this.setState({ numPoliceAss: data.numPoliceAss });
            this.setState({ adrAss: data.adrAss });
            this.setState({ sexeAss: { label: data.sexeAss, value: data.sexeAss } });
            this.setState({ selectedAssure: data });
            let parent = this.state.lparent_assure.filter(function (el) {
                return el.value === data.idParentAss.idParentAss;
            })[0];
            this.setState({ idParentAss: parent });
        }
    }

    actionTemplate_assure(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_assure(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_assure(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    enreg_assure() {
        if (this.state.idParentAss === undefined || this.state.idParentAss === "" || this.state.nomAss === undefined || this.state.nomAss === "" || this.state.pchargeAss === "") {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif_assure == false) {
                let data = { idAss: '0', statutSyncAss: this.myservice.get_statut_sync(), sexeAss: this.state.sexeAss.value, pchargeAss: this.state.pchargeAss, nomAss: this.state.nomAss, adrAss: this.state.adrAss, numPoliceAss: this.state.numPoliceAss, libAss: 'Assuré', matrAss: this.state.matrAss, remiseAss: this.state.remiseAss, etatAss: 'En cours', idParentAss: { idParentAss: this.state.idParentAss.value } };
                this.myservice.enreg_assure(data).then(data => this.resultat_assure(data.code, data.contenu, 'ASS'));
            } else {
                let data = this.state.selectedAssure;
                data.matrAss = this.state.matrAss;
                data.nomAss = this.state.nomAss;
                data.remiseAss = this.state.remiseAss;
                data.sexeAss = this.state.sexeAss.value;
                data.pchargeAss = this.state.pchargeAss;
                data.idParentAss = { idParentAss: this.state.idParentAss.value, idCli: { idCli: this.state.idParentAss.idCli, nomCli: this.state.idParentAss.nomCli } };
                this.myservice.enreg_assure(data).then(rep => this.resultat_assure(rep.code, rep.contenu, 'ASS'));
            }
        }
    }

    option_recherche_corbeille() {
        let color = '#0d8ced';
        let hovercolor = '#39a3f4';
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            color = '#4e805c';
            hovercolor = '#b4df98';
        } else if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Station') {
            color = '#e10032';
            hovercolor = '#f8b4cb';
        }
        const selectStyles = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 600,
            }),
        };
        return <div className="p-grid">
            {this.state.visible_dlg_corbeille_sortie == true ?
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <WindowedSelect
                                isClearable={false}
                                options={this.state.lclient_rech}
                                styles={selectStyles}
                                placeholder="Client"
                                value={this.state.ridClient}
                                onChange={(value) => this.setState({ ridClient: value, idCli_situation: value })}
                            />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <Dropdown options={this.state.lboutique_rech} placeholder="Boutique" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-inputgroup">
                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '200px' }} onClick={(e) => this.get_liste_corbeille_sortie()} />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    }

    option_recherche_reconcilliation() {
        return <div className="p-grid">
            {this.state.visible_dlg_reconcilliation == true ?
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                        </div>

                        <div className="p-col-12 p-md-3">
                            <Dropdown options={this.state.lboutique_rech} placeholder="Toutes les boutiques" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-inputgroup">
                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '220px' }} onClick={(e) => this.reconcillier()} />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </div>
                        </div>
                    </div>
                </div> : null}
        </div >
    }

    option_recherche() {
        let color = '#0d8ced';
        let hovercolor = '#39a3f4';
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            color = '#4e805c';
            hovercolor = '#b4df98';
        } else if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Station') {
            color = '#e10032';
            hovercolor = '#f8b4cb';
        }

        if (this.get_type_sortie() === 'Proforma' || this.get_type_sortie() === 'Perte')
            return <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <InputText value={this.state.rnum_facture} placeholder="N° facture" onChange={(e) => this.setState({ rnum_facture: e.target.value })} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-grid">
                                <div className="p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.show_situation_client_dlg} style={{ width: '30px' }} />
                                </div>
                                <div className="p-col-10">
                                    <Dropdown id="new_dp_client_rech"
                                        options={this.state.lclient_rech}
                                        value={this.state.ridClient}
                                        placeholder="Client"
                                        onChange={(e) => {
                                            this.setState({ ridClient: e.value, idCli_situation: e.value }, () => {
                                                if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                                                    this.select_parent_assure_by_client(this.state.ridClient.value);
                                                }
                                            })
                                        }}
                                        style={{ width: '100%' }}
                                        filter={true} filterBy='label_rech' filterMatchMode='contains'
                                        optionLabel='label_rech'
                                        itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="p-clearfix">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-12">
                                                                <label>{option.label}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                <div className="p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                        this.op.toggle(e);
                                    }} style={{ marginLeft: -15, width: '30px' }} />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <Dropdown options={this.state.lboutique_rech} placeholder="Toutes les boutiques" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-inputgroup">
                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '220px' }} onClick={(e) => this.get_liste_sortie()} />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        else return <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12 p-md-3">
                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                    </div>
                    <div className="p-col-12 p-md-3">
                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                    </div>
                    <div className="p-col-12 p-md-3">
                        <InputText value={this.state.rnum_facture} placeholder="N° facture" onChange={(e) => this.setState({ rnum_facture: e.target.value })} />
                    </div>
                    <div className="p-col-12 p-md-3">
                        <div className="p-grid">
                            <div className="p-col-1">
                                <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.show_situation_client_dlg} style={{ width: '30px' }} />
                            </div>
                            <div className="p-col-10">
                                <Dropdown id="new_dp_client_rech"
                                    options={this.state.lclient_rech}
                                    value={this.state.ridClient}
                                    placeholder="Client"
                                    onChange={(e) => {
                                        this.setState({ ridClient: e.value, idCli_situation: e.value }, () => {
                                            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                                                this.select_parent_assure_by_client(this.state.ridClient.value);
                                            }
                                        })
                                    }}
                                    style={{ width: '100%' }}
                                    filter={true} filterBy='label_rech' filterMatchMode='contains'
                                    optionLabel='label_rech'
                                    itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        }
                                        else {
                                            return (
                                                <div className="p-clearfix">
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-12">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <div className="p-col-1">
                                <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                    this.op.toggle(e);
                                }} style={{ marginLeft: -15, width: '30px' }} />
                            </div>
                        </div>

                    </div>
                    {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' ?
                        <div className="p-col-12 p-md-3">
                            <Dropdown style={{ width: '85%' }} options={this.state.lparent_assure_rech} placeholder="Souscripteur" value={this.state.ridParent_assure} optionLabel='label' filter={true} filterBy='label_rech' filterMatchMode='contains' onChange={(e) => this.setState({ ridParent_assure: e.value })} autoWidth={false} panelStyle={{ width: '550px' }} />
                        </div>
                        :
                        <div className="p-col-12 p-md-3">
                            <Dropdown options={this.state.liste_statut_livraison} placeholder="Livraison" value={this.state.statutLivraison} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ statutLivraison: e.value })} autoWidth={false} />
                        </div>}


                    <div className="p-col-12 p-md-3">
                        <Dropdown options={this.state.liste_statut_reglement} placeholder="Reglement" value={this.state.statutReglement} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ statutReglement: e.value })} autoWidth={false} />
                    </div>

                    <div className="p-col-12 p-md-3">
                        <Dropdown options={this.state.lboutique_rech} placeholder="Toutes les boutiques" value={this.state.ridBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridBoutiq: e.value })} autoWidth={false} />
                    </div>
                    <div className="p-col-12 p-md-3">
                        <div className="p-inputgroup">
                            <Button icon='pi pi-search' label='Rechercher' style={{ width: '220px' }} onClick={(e) => this.get_liste_sortie()} />
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    aff_field_coef(form) {
        if (this.state.idArticle !== null && this.state.idArticle !== undefined) {
            if (this.state.idArticle.libsoustypearticle == 'METAL PRECIEUX') {
                if (form == 'NEW') {
                    return <div className="p-col-12" >
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Carat *</label>
                        </span>
                        <InputText keyfilter="pnum" onKeyDown={this.handleKeyPress} value={this.state.coefLsortie} onChange={(e) => this.setState({ coefLsortie: e.target.value })} />
                    </div>
                } else {
                    return <>

                        <div className="p-col-12 p-md-3">
                            <label >Carat *</label>
                            <InputText keyfilter="pnum" onKeyDown={this.handleKeyPress} value={this.state.coefLsortie} onChange={(e) => this.setState({ coefLsortie: e.target.value })} />
                        </div>
                    </>
                }

            }
        }
    }


    page_new_sortie() {

        let items = [
            {
                label: 'Historique',
                icon: 'pi pi-search',
                command: (e) => {
                    this.show_dlg_historique();
                }
            },
            {
                label: 'Nouveau client',
                icon: 'pi pi-user',
                command: (e) => {
                    this.show_new_client_dlg();
                }
            },
            {
                label: 'Détails',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.show_dlg_detail_lsortie();
                }
            }
        ];
        if ((this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur' || this.state.userconnected.profilUser === 'Administrateur' || (this.state.userconnected.profilUser === 'Gérant' && this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Fonderie')) && this.myservice.get_statut_offline() == 'false') {
            items.push(
                {
                    label: 'Article',
                    icon: 'pi pi-plus',
                    command: (e) => {
                        this.show_dlg_article();
                    }
                }
            );
        }

        let form_payement = <div className="p-grid" style={{ marginTop: -16 }}>
            <div className="p-col-12 p-md-3">
                <span >
                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Mode remise *</label>
                </span>
                <Dropdown options={this.state.liste_mode_remise} value={this.state.mode_remise} panelStyle={{ width: '500px' }} optionLabel='label' filterMatchMode='contains' onChange={(e) => this.setState({ mode_remise: e.value })} autoWidth={false} />
            </div>
            <div className="p-col-3">
                <span >
                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Valeur remise *</label>
                </span>
                <Cleave onKeyDown={this.handleKeyPress} value={this.state.val_remise} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ val_remise: e.target.rawValue }, () => {
                    this.calculer_total(this.state.liste_ligne);
                })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
            </div>
            <div className="p-col-3">
                <span >
                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Montant payé *</label>
                </span>
                <Cleave onKeyDown={this.handleKeyPress} value={this.state.mtpaye} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtpaye: e.target.rawValue }, () => {
                    this.calculer_restant();
                })} className="p-inputtext" />
            </div>

            <div className="p-col-3">
                <span >
                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Référence payement</label>
                </span>
                <InputText onKeyDown={this.handleKeyPress} value={this.state.refRglSortie} onChange={(e) => this.setState({ refRglSortie: e.target.value })} />
            </div>

            <div className="p-col-3">
                <span className="p-float-label">
                    <InputText onKeyDown={this.handleKeyPress} value={this.state.libSortie} onChange={(e) => this.setState({ libSortie: e.target.value })} />
                    <label >Objet</label>
                </span>
            </div>

            <div className="p-col-3">
                <span className="p-float-label">
                    <InputText onKeyDown={this.handleKeyPress} value={this.state.refSortie} onChange={(e) => this.setState({ refSortie: e.target.value })} />
                    <label >Référence commande</label>
                </span>
            </div>

            <div className="p-col-3">
                <span className="p-float-label">
                    <InputText onKeyDown={this.handleKeyPress} value={this.state.modalitePaySortie} onChange={(e) => this.setState({ modalitePaySortie: e.target.value })} />
                    <label >Modalité payement</label>
                </span>
            </div>
            <div className="p-col-3">
                <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                    this.setState({ modeRgl: e.value })
                }
                } autoWidth={false} />
            </div>

        </div>

        if (this.get_type_sortie() === 'Proforma') {
            form_payement = <div className="p-grid" style={{ marginTop: 6 }}>
                <div className="p-col-12 p-md-3">
                    <span >
                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Mode remise *</label>
                    </span>
                    <Dropdown options={this.state.liste_mode_remise} value={this.state.mode_remise} panelStyle={{ width: '500px' }} optionLabel='label' filterMatchMode='contains' onChange={(e) => this.setState({ mode_remise: e.value })} autoWidth={false} />
                </div>
                <div className="p-col-2">
                    <span >
                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Valeur remise *</label>
                    </span>
                    <Cleave onKeyDown={this.handleKeyPress} value={this.state.val_remise} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ val_remise: e.target.rawValue }, () => {
                        this.calculer_total(this.state.liste_ligne);
                    })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />
                </div>

                <div className="p-col-3">
                    <span >
                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Objet</label>
                    </span>
                    <InputText onKeyDown={this.handleKeyPress} value={this.state.libSortie} onChange={(e) => this.setState({ libSortie: e.target.value })} />
                </div>

                <div className="p-col-2">
                    <span >
                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Référence commande</label>
                    </span>
                    <InputText onKeyDown={this.handleKeyPress} value={this.state.refSortie} onChange={(e) => this.setState({ refSortie: e.target.value })} />
                </div>

                <div className="p-col-2">
                    <span >
                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Modalité payement</label>
                    </span>
                    <InputText onKeyDown={this.handleKeyPress} value={this.state.modalitePaySortie} onChange={(e) => this.setState({ modalitePaySortie: e.target.value })} />

                </div>

            </div>
        }

        if (this.get_type_sortie() === 'Perte') {
            form_payement = <div className="p-grid" style={{ marginTop: 6 }}>
                <div className="p-col-12 p-md-2">
                    <label htmlFor="inPu">Type perte *</label>
                </div>
                <div className="p-col-12 p-md-4">
                    <Dropdown options={this.state.liste_type_sortie} value={this.state.typeSortie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                        onChange={(e) => this.setState({ typeSortie: e.value })} autoWidth={false} />
                </div>
            </div>
        }

        return <div className="p-grid" style={{ backgroundColor: "#ffffff" }}>
            <div className="p-col-12" style={{ marginBottom: '-15px' }}>
                <div className="p-grid">
                    <div className="p-col-12 p-md-2">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Date *</label>
                        </span>
                        <Calendar value={this.state.dateSortie} disabled={this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur'} showTime={true} hourFormat="24" showSeconds={true} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateSortie: e.value })} viewDate={new Date()} />
                    </div>
                    {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && (this.get_type_sortie() !== 'Perte') ?
                        <div className="p-col-12 p-md-2">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Boutique concernée *</label>
                            </span>
                            <Dropdown options={this.state.lboutique} placeholder="Toutes les boutiques" value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                onChange={(e) => this.setState({ idBoutiq: e.value }, () => {
                                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                                        this.setState({ rfiltreArticle: 'Tous' }, () => {
                                            this.get_liste_article(this.state.idBoutiq.value);
                                        })
                                    }

                                })} autoWidth={false} />
                        </div>
                        : null}

                    <div className="p-col-12 p-md-4">
                        <span >
                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Client *</label>
                        </span>
                        <div className="p-grid">
                            <div className="p-col-1">
                                <Button type="button" icon="pi pi-plus" style={{ width: '35px' }} className="p-button-info" onClick={this.show_new_client_dlg} />
                            </div>
                            <div className="p-col-1">
                                <Button type="button" icon="pi pi-search" style={{ width: '35px' }} className="p-button-info" onClick={this.show_situation_client_dlg} />
                            </div>
                            <div className="p-col-9">
                                <Dropdown id="new_dp_client"
                                    options={this.state.lclient}
                                    value={this.state.idCli}
                                    placeholder="Client"
                                    onChange={(e) => {
                                        this.setState({ idCli: e.value, idCli_situation: e.value }, () => {
                                            this.setState({ tvaSortie: this.state.idCli.tvaCli, precompteSortie: this.state.idCli.precompteCli, nomSelectedClient: this.state.idCli.label }, () => {
                                                this.calculer_total(this.state.liste_ligne);
                                                if (this.state.idArticle != null && this.state.idArticle != undefined) {
                                                    let tt_remise = this.state.idArticle.currentRemise + this.state.idCli.remiseCli;
                                                    this.setState({ remiseLsortie: 0.01 * tt_remise * this.state.puLsortie })
                                                }
                                                this.setState({ nomInteresseSortie: this.state.idCli.label });
                                                document.getElementById("new_dp_prod_add").click();
                                            });
                                            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                                                this.select_assure_by_client(this.state.idCli.value);
                                            }
                                            this.get_tickets_for_vente();
                                        })
                                    }}
                                    style={{ width: '100%' }}
                                    filter={true} filterBy='label_rech' filterMatchMode='contains'
                                    optionLabel='label_rech'
                                    itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        }
                                        else {
                                            return (
                                                <div className="p-clearfix">
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-12">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <div className="p-col-1">
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                        this.op.toggle(e);
                                        document.getElementById("id_ovp").focus();
                                    }} style={{ marginLeft: -15, width: '35px' }} />
                                }
                            </div>
                        </div>
                    </div>
                    {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' && (this.get_type_sortie() !== 'Perte') ?
                        <div className="p-col-12 p-md-3">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Assuré </label>
                            </span>
                            <div className="p-inputgroup">
                                <Dropdown options={this.state.liste_assure_vente} value={this.state.idAssSortie} panelStyle={{ width: '650px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' itemTemplate={this.assureTemplate}
                                    onChange={(e) => {
                                        this.setState({ idAssSortie: e.value }, () => {
                                            this.setState({ paSortie: this.state.idAssSortie.pchargeAss });
                                            this.calculer_total(this.state.liste_ligne);
                                        })
                                    }
                                    } style={{ width: '70%' }} filterInputAutoFocus={true} />
                                <span>
                                    <Button type="button" icon="pi pi-users" className="p-button-info" onClick={this.show_dlg_assure} style={{ width: '30px' }} />
                                </span>
                            </div>

                        </div>
                        : null
                    }
                    {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' ?
                        <div className="p-col-12 p-md-1">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Précompte</label>
                            </span>
                            <InputText value={this.state.precompteSortie} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ precompteSortie: e.target.value })} disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />

                        </div>
                        : null
                    }
                    {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' && (this.get_type_sortie() !== 'Perte') ?
                        <div className="p-col-12 p-md-1">
                            <span >
                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Prise en charge</label>
                            </span>
                            <InputText value={this.state.paSortie} keyfilter="pnum" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ paSortie: e.target.value })} />

                        </div>
                        : null
                    }

                    <div className="p-col-12 p-md-2">
                        <Panel header="Net à payer" style={{ marginTop: '-30px', backgroundColor: '#ccff66', }}  >
                            <label style={{ color: 'red', fontSize: '22px', fontWeight: 'bold' }} htmlFor="in">{this.state.montant_interesse_format}</label>
                        </Panel>
                    </div>
                </div>
            </div>

            <div className="p-col-12 p-lg-3">
                <div className="p-grid">
                    <div className="p-col-12">
                        <Panel style={{ minHeight: '310px' }} header="Ajout d'article">
                            <div className="p-grid" style={{ marginTop: 6 }}>
                                <div className="p-col-12" style={{ marginBottom: '13px' }}>

                                    <div className="p-grid">
                                        <div className="p-col-11">
                                            <span className="p-float-label">
                                                <InputText id="input_code_barre" value={this.state.code_barre} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ code_barre: e.target.value })} />
                                                <label htmlFor="in">Rechercher par code barre ou nom</label>
                                            </span>
                                        </div>
                                        <div className="p-col-1">
                                            <Button type="button" icon="pi pi-search" style={{ width: '35px', marginLeft: -15 }} className="p-button-info" onClick={(e) => this.search_article()} />
                                        </div>
                                    </div>


                                </div>
                                <div className="p-col-12" style={{ marginBottom: '13px' }}>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon" style={{ backgroundColor: '#f5f8f9', color: 'red', fontWeight: 'bold', width: 70 }}>{this.state.qte_stock}</span>
                                        <SplitButton icon="pi pi-plus" style={{ width: 70 }} onClick={this.show_dlg_article} model={items} />
                                        <span>
                                            <Dropdown id="new_dp_prod_add"
                                                style={{ width: '170px' }}
                                                value={this.state.idArticle}
                                                options={this.state.lprod}
                                                placeholder="Articles"
                                                panelStyle={{ width: '500px' }}
                                                onChange={(e) => {
                                                    this.setState({ idArticle: e.value }, () => {
                                                        let prix_vente = this.state.idArticle.prix_ref_vente;
                                                        if (this.state.idArticle.prix_except > 0) {
                                                            prix_vente = this.state.idArticle.prix_except;
                                                        }
                                                        this.setState({ constLsortie: this.state.idArticle.constRefArticle });
                                                        this.setState({ puLsortie: prix_vente });
                                                        this.setState({ qte_stock: this.state.idArticle.qte_stock_format });
                                                        this.setState({ qteLsortie: 1 });
                                                        let remise_client = 0;
                                                        if (this.state.idCli != null && this.state.idCli != undefined) {
                                                            remise_client = this.state.idCli.remiseCli;
                                                        }
                                                        let tt_remise = this.state.idArticle.currentRemise + remise_client;
                                                        this.setState({ remiseLsortie: 0.01 * tt_remise * prix_vente });
                                                        this.setState({ designationLsortie: this.state.idArticle.label });
                                                        this.get_tickets_for_vente();
                                                        //document.getElementById("new_dp_prod_add").click();
                                                    })
                                                }}
                                                optionLabel='label_rech' filter={true} filterBy='label_rech,cbArt' filterMatchMode='contains' itemTemplate={this.articleItemTemplate}
                                            />
                                        </span>
                                    </div>
                                </div>


                                <div className="p-col-12" style={{ marginTop: 5 }}>
                                    <span className="p-float-label">
                                        <InputText keyfilter="pnum" onKeyDown={this.handleKeyPress} value={this.state.qteLsortie} onChange={(e) => {
                                            if (this.state.userconnected.idEtabl.codeEtabl == 'MOUTOU') {
                                                this.setState({ qteLsortie: e.target.value, qteLivrLsortie: 0 });
                                            } else {
                                                this.setState({ qteLsortie: e.target.value, qteLivrLsortie: e.target.value });
                                            }
                                        }} />
                                        <label htmlFor="in">Quantité / Poids en g </label>
                                    </span>
                                </div>
                                <div className="p-col-12" >
                                    <span >
                                        <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Prix unitaire *</label>
                                    </span>
                                    <Cleave disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} onKeyDown={this.handleKeyPress} value={this.state.puLsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLsortie: e.target.rawValue }, () => {
                                        let remise_client = 0;
                                        if (this.state.idCli != null && this.state.idCli != undefined) {
                                            remise_client = this.state.idCli.remiseCli;
                                        }
                                        let tt_remise = this.state.idArticle.currentRemise + remise_client;
                                        this.setState({ remiseLsortie: 0.01 * tt_remise * this.state.puLsortie })
                                    })} className="p-inputtext" />
                                </div>

                                {this.aff_field_coef('NEW')}

                                {this.get_type_sortie() !== 'Perte' ?
                                    <div className="p-col-12" style={{ marginBottom: '13px' }}>
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Remise *</label>
                                        </span>
                                        <Cleave onKeyDown={this.handleKeyPress} disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.idUser !== '471/403145S071161220' /* Sidafa*/} value={this.state.remiseLsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ remiseLsortie: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                    : null}

                                {this.get_type_sortie() != 'Proforma' && this.get_type_sortie() !== 'Perte' ?
                                    <div className="p-col-12" style={{ marginBottom: '3px' }}>
                                        <span className="p-float-label">
                                            <InputText onKeyDown={this.handleKeyPress} keyfilter="pint" value={this.state.qteLivrLsortie} onChange={(e) => this.setState({ qteLivrLsortie: e.target.value })} />
                                            <label htmlFor="in">Quantité livrée</label>
                                        </span>
                                    </div>
                                    : null}

                                {this.state.userconnected.idEtabl.codeEtabl == 'EDK' ?
                                    <div className="p-col-12" style={{ marginBottom: '3px' }}>
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Ticket fidélité</label>
                                        </span>
                                        <Dropdown placeholder="Sélectionner le ticket" options={this.state.liste_fidelisation} value={this.state.idLfid} optionLabel='label' onChange={(e) => {
                                            this.setState({ idLfid: e.value }, () => {
                                                this.setState({
                                                    prcFid: this.state.idLfid.prcFid,
                                                    refLfid: this.state.idLfid.idLfid,
                                                    numTicketLfid: this.state.idLfid.value,
                                                })
                                            })
                                        }
                                        } autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '300px' }} />
                                    </div>
                                    : null}

                                <div className="p-col-12 p-md-12">
                                    <Button label="Ajouter >> " id="bt_add" icon="pi pi-plus" style={{ width: '200px' }} onClick={(e) => this.add_article()} />
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>

            <div className="p-col-12 p-lg-9">
                <div className="p-grid">
                    <div className="p-col-12">
                        <Panel style={{ minHeight: '300px' }}>
                            <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="200px"
                                onRowClick={e => this.setState({ selectedLigne: e.data })}
                                responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 12 }} editable={true}
                            >
                                <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} />
                                <Column header="Article" style={{ width: '46%' }} body={(rowData, column) => {
                                    let color = 'black';
                                    if (parseFloat(rowData.qteLsortie) > parseFloat(rowData.qte_stock)) {
                                        color = 'red';
                                    }
                                    return <div>
                                        <span style={{ color: color }}>{rowData.idArticle.libArticle}</span>
                                    </div>
                                }} />
                                <Column field="qteLsortie" header="Qté" style={{ width: '8%' }} body={(rowData, column) => {
                                    let color = 'black';
                                    if (parseFloat(rowData.qteLsortie) > parseFloat(rowData.qte_stock)) {
                                        color = 'red';
                                    }
                                    return <div>
                                        <span style={{ color: color }}>{rowData.qteLsortie}</span>
                                    </div>
                                }} editor={this.qteLsortieEditor} />
                                <Column field="puLsortie" header="Prix" style={{ width: '10%' }} editor={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG' ? null : this.puLsortieEditor} body={(rowData, column) => {
                                    return <div>
                                        <span>{this.formaterMontant(parseFloat(rowData.puLsortie))}</span>
                                    </div>
                                }} />
                                <Column field="remiseLsortie" header="Remise" style={{ width: '8%' }} body={this.remiseLsortieBodyTemplate} editor={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG' ? null : this.remiseLsortieEditor} />
                                <Column header="Montant" style={{ width: '15%' }} body={this.montantVenteBodyTemplate} />
                                <Column field="qteLivrLsortie" header="Livré" style={{ width: '8%' }} editor={this.qteLivrLsortieEditor} body={(rowData, column) => {
                                    let color = 'black';
                                    if (parseFloat(rowData.qteLsortie) < parseFloat(rowData.qteLivrLsortie)) {
                                        color = 'red';
                                    }
                                    return <div>
                                        <span style={{ color: color }}>{rowData.qteLivrLsortie}</span>
                                    </div>
                                }} />
                            </DataTable>
                        </Panel>
                        <div className="card card-w-title" style={{ marginTop: '5px', marginBottom: -20 }}>
                            {form_payement}
                            <div className="p-grid" style={{ marginTop: 3, backgroundColor: '#f5f8f9', border: '1px solid #C2C4C4', marginBottom: '5px' }}>
                                <div className="p-col-12 p-md-1">
                                    <label >TT brut</label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label style={{ fontWeight: 'bold', fontSize: '16px' }}>{this.state.montant_brut_format}</label>
                                </div>
                                <div className="p-col-12 p-md-1">
                                    <label >TT remise</label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label style={{ fontWeight: 'bold', fontSize: '16px' }}>{this.state.remise_total_format}</label>
                                </div>
                                <div className="p-col-12 p-md-1">
                                    <label >Net</label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label style={{ fontWeight: 'bold', fontSize: '16px' }}>{this.state.mtapayer_format}</label>
                                </div>
                                <div className="p-col-12 p-md-1">
                                    <label >Restant</label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label style={{ fontWeight: 'bold', fontSize: '16px', color: 'red' }}>{this.formaterMontant(this.state.total_restant)}</label>
                                </div>
                            </div>

                            <center style={{ marginBottom: '-15px' }}>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                <div className="p-grid" style={{ width: 500 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_sortie_dlg()} /></div>
                                    {this.state.actif_vente_proforma === false && this.get_type_sortie() === 'Facture' ?
                                        <div className="p-col"><Button disabled={this.state.liste_ligne.length <= 0 || this.state.idCli === null || this.state.idCli === undefined} label="Mise en attente" icon="fa fa-arrow-circle-down" className="p-button-warning" onClick={(e) => this.enreg_sortie('Proforma')} /></div>
                                        : null}
                                    <div className="p-col"><Button disabled={this.state.liste_ligne.length <= 0 || this.state.idCli === null || this.state.idCli === undefined} label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_sortie(this.get_type_sortie())} /></div>
                                </div>
                            </center>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    }

    annule_client() {
        this.setState({ nomCli: '' });
        this.setState({ adrCli: '' });
        this.setState({ emailCli: '' });
        this.setState({ telCli: '' });
        this.setState({ remiseCli: 0 });
        this.setState({ tvaCli: 0 });
        this.setState({ visible_dlg_client: false });
        window.scrollTo(0, 0);
        this.forceUpdate();
    }

    enreg_client() {
        if (this.state.telCli === "" || this.state.nomCli === "" || this.state.nomCli === undefined || this.state.nomCli === null) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let data = { idCli: '0', telCli: this.state.telCli, capturePieceCli: 'NA', seuilCreditCli: this.state.seuilCreditCli, statutSyncCli: this.myservice.get_statut_sync(), nomCli: this.state.nomCli, adrCli: this.state.adrCli, precompteCli: 0, typeCli: this.state.typeCli.value, typePieceCli: 'Inconnu', emailCli: this.state.emailCli, remiseCli: this.state.remiseCli, tvaCli: this.state.tvaCli, numPieceCli: '-', idBoutiq: this.state.userconnected.matrPers.idBoutiq };
            this.myservice.enreg_client(data).then(rep => {
                if (rep.code == 200) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
                    this.setState({ showindicator: false });
                    this.get_liste_client();
                    this.annule_client();
                } else {
                    this.growl.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
                }
            });
        }
    }

    actionTemplateDetail(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            {this.state.modif_lsortie == true ? <div className="p-col"><Button onClick={(e) => this.edit_detail(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div> : null}
            <div className="p-col"><Button onClick={(e) => this.delete_ligne_detail(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -11 }} icon="pi pi-times" /></div>
        </div>
    }

    show_dlg_detail_lsortie() {
        if (this.state.idArticle != null && this.state.idArticle != undefined && this.state.idCli != null && this.state.idCli != undefined) {
            if (this.state.idCli.label.includes('UAGCP')) {
                this.get_liste_article_spec();
                this.setState({ visible_dlg_detail: true });
            } else {
                this.setState({ visible_dlg_descLsortieContent: true });
            }
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: 'Veuillez sélectionner, un client et un article' });
            return;
        }
    }

    add_detail() {
        if (this.state.idArticleSpec === null || this.state.qteDlsortie === undefined || this.state.nbDlsortie === undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner un article et saisir les quantités' });
        }
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        if (this.state.visible_dlg_detail == true) {
            let rang = this.state.liste_detail.length + 1;
            this.state.liste_detail.insert(rang,
                {
                    idDlsortie: rang,
                    qteDlsortie: this.state.qteDlsortie,
                    nbDlsortie: this.state.nbDlsortie,
                    puDlsortie: this.state.puDlsortie,
                    descDlsortie: this.state.descDlsortie,
                    coordonneeDlsortie: this.state.coordonneeDlsortie,
                    idEtabl: this.state.userconnected.idEtabl.idEtabl,
                    iduser: this.state.userconnected.idUser,
                    idArticle: { idArticle: this.state.idArticleSpec.value, libArticle: this.state.idArticleSpec.label }

                }
            );
        } else {
            let rang = this.state.liste_detail.length + 1;
            let trouve = false;
            for (var i = this.state.liste_detail.length; i--;) {
                if (this.state.idDlsortie === this.state.liste_detail[i].idDlsortie) {
                    this.state.liste_detail[i].qteDlsortie = this.state.qteDlsortie;
                    this.state.liste_detail[i].nbDlsortie = this.state.nbDlsortie;
                    this.state.liste_detail[i].puDlsortie = this.state.puDlsortie;
                    this.state.liste_detail[i].montant = this.state.liste_detail[i].puDlsortie * this.state.liste_detail[i].qteDlsortie * this.state.liste_detail[i].nbDlsortie;
                    this.state.liste_detail[i].descDlsortie = this.state.descDlsortie;
                    this.state.liste_detail[i].coordonneeDlsortie = this.state.coordonneeDlsortie;
                    trouve = true;
                    break;
                }
            }
            if (trouve == false) {
                this.state.liste_detail.insert(rang,
                    {
                        idDlsortie: rang,
                        qteDlsortie: this.state.qteDlsortie,
                        nbDlsortie: this.state.nbDlsortie,
                        puDlsortie: this.state.puDlsortie,
                        descDlsortie: this.state.descDlsortie,
                        coordonneeDlsortie: this.state.coordonneeDlsortie,
                        montant: this.state.nbDlsortie * this.state.puDlsortie * this.state.qteDlsortie,
                        idEtabl: this.state.userconnected.idEtabl.idEtabl,
                        iduser: this.state.userconnected.idUser,
                        idArticle: { idArticle: this.state.idArticleSpec.value, libArticle: this.state.idArticleSpec.label }

                    }
                );
            }

        }

        this.setState({
            // qteDlsortie: 0,
            // nbDlsortie: 0,
            puDlsortie: 0,
            coordonneeDlsortie: '',
        })
        this.forceUpdate();
        this.calculer_total_detail();
    }

    calculer_total_detail() {
        let mt = 0;
        if (this.state.liste_detail.length > 0) {
            for (var i = this.state.liste_detail.length; i--;) {
                if (this.state.liste_detail[i].montant == undefined) {
                    let art = this.state.liste_detail[i].idArticle.libArticle;
                    if (art.toUpperCase().includes("RELIURE")) {
                        this.state.liste_detail[i].montant = this.state.liste_detail[i].qteDlsortie * this.state.liste_detail[i].puDlsortie;
                    } else {
                        this.state.liste_detail[i].montant = this.state.liste_detail[i].nbDlsortie * this.state.liste_detail[i].qteDlsortie * this.state.liste_detail[i].puDlsortie;
                    }
                }
                mt += this.state.liste_detail[i].montant;
            }
            this.setState({ puLsortie: mt / this.state.liste_detail[0].qteDlsortie });
        }
        this.setState({ total_detail: mt, total_restant: mt - this.state.mtpaye });

    }

    calculerPrix() {
        let idStype = this.state.idArticleSpec.idStype;
        let formatPprix = this.state.idArticleSpec.formatArticle;
        let specPprix = this.state.idArticleSpec.specArticle;
        let liste = this.state.liste_paramprix.filter(function (el) {
            return el.idStype.idStype === idStype && el.formatPprix == formatPprix && el.specPprix == specPprix;
        })
        if (liste !== null && liste !== undefined) {
            for (let i = 0; i < liste.length; i++) {
                let correspPoids = false;
                let correspNbpage = false;
                let correspQte = false;
                let val = liste[i];
                if (this.state.poidsArticle >= val.poidsInfPprix && this.state.poidsArticle <= val.poidsSupPprix) {
                    correspPoids = true;
                }
                if (this.state.nbDlsortie >= val.nbPageInfPprix && this.state.nbDlsortie <= val.nbPageSupPprix) {
                    correspNbpage = true;
                }
                if (this.state.qteDlsortie >= val.qteInfPprix && this.state.qteDlsortie <= val.qteSupPprix) {
                    correspQte = true;
                }
                if (correspPoids && correspQte && correspNbpage) {
                    this.setState({
                        puDlsortie: val.newPprix,
                        coordonneeDlsortie: val.coordonneePprix
                    });
                    break;
                }
            }
        }
    }

    descLsortieContent() {
        return <><div className="p-grid">
            <div className="p-col-12 p-md-12">
                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Description  *</label>
                <InputTextarea rows={5} cols={25} value={this.state.designationLsortie} onChange={(e) => this.setState({ designationLsortie: e.target.value })} />
            </div>
        </div>
            <center>
                <Button label="Terminer" icon="pi pi-check" onClick={(e) => {
                    this.setState({ visible_dlg_descLsortieContent: false });
                }} style={{ width: '120px' }} />
            </center>
        </>
            ;
    }

    detail_content() {
        return <div className="p-grid">
            <div className="p-col-12">
                <Panel style={{ marginBottom: -8 }}>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4">
                            <label htmlFor="libCodif">Article *</label>
                            <br />
                            <Dropdown id="new_dp_prod_spec"
                                style={{ width: '100%' }}
                                value={this.state.idArticleSpec}
                                options={this.state.liste_article_spec}
                                placeholder="Articles"
                                panelStyle={{ width: '500px' }}
                                onChange={(e) => {
                                    this.setState({ idArticleSpec: e.value }, () => {
                                        this.calculerPrix();
                                        this.setState({ descDlsortie: this.state.idArticleSpec.label });
                                    })
                                }}
                                optionLabel='label_rech' filter={true} filterBy='label_rech,cbArt' filterMatchMode='contains'
                            />
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label htmlFor="libCodif">Désignation *</label>
                            <InputTextarea rows={2} cols={25} value={this.state.descDlsortie} onChange={(e) => this.setState({ descDlsortie: e.target.value })} />
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label htmlFor="libCodif">Grammage </label>
                            <Cleave value={this.state.poidsArticle} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ poidsArticle: e.target.rawValue }, () => {
                                this.calculerPrix();
                            })} className="p-inputtext" />
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label htmlFor="libCodif">Nb page *</label>
                            <Cleave value={this.state.nbDlsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ nbDlsortie: e.target.rawValue }, () => {
                                this.calculerPrix();
                            })} className="p-inputtext" />
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label htmlFor="libCodif">Exemplaire</label>
                            <Cleave value={this.state.qteDlsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ qteDlsortie: e.target.rawValue, qteLsortie: e.target.rawValue }, () => {
                                this.calculerPrix();

                            })} className="p-inputtext" />
                        </div>
                        <div className="p-col-12 p-md-1">
                            <label htmlFor="libCodif">Prix *</label>
                            <Cleave value={this.state.puDlsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puDlsortie: e.target.rawValue })} className="p-inputtext" />
                        </div>
                        <div className="p-col-12 p-md-1">
                            <br />
                            <Button label={this.state.modif_detail == false ? "Ajouter" : "Enregistrer"} icon="pi pi-plus" onClick={(e) => this.add_detail()} style={{ width: '80px' }} />
                        </div>
                    </div>

                    <DataTable value={this.state.liste_detail} scrollable={true} scrollHeight="150px"
                        onRowClick={e => this.setState({ selectedDetail: e.data })}
                        selectionMode="single" selection={this.state.selectedDetail}
                        footer={this.state.modif_lsortie == false ? <label style={{ fontSize: 20 }}>{this.formaterMontant(this.state.total_detail)}</label> : null}
                        responsive={true} style={{ marginBottom: 12 }}>
                        <Column header="#" body={this.actionTemplateDetail.bind(this)} style={{ width: '8%' }} />
                        <Column field="idArticle.libArticle" header="Article" style={{ width: '20%' }} />
                        <Column field="descDlsortie" header="Spécifications" />
                        <Column field="nbDlsortie" header="Nb page" style={{ width: '8%' }} />
                        <Column field="qteDlsortie" header="Exemplaire" style={{ width: '9%' }} />
                        <Column field="puDlsortie" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000' }}>{this.formaterMontant(rowData.puDlsortie)}</span>
                            </div>
                        }} header="Prix" style={{ width: '8%' }} />
                        <Column field="montant" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000' }}>{this.formaterMontant(rowData.montant)}</span>
                            </div>
                        }} header="Montant" style={{ width: '8%' }} />
                        <Column field="coordonneeDlsortie" header="Coord." style={{ width: '6%' }} />
                    </DataTable>
                    {this.state.modif_lsortie == false ?
                        <>
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Description article *</label>
                                </div>
                                <div className="p-col-12 p-md-8">
                                    <InputTextarea rows={2} cols={25} value={this.state.designationLsortie} onChange={(e) => this.setState({ designationLsortie: e.target.value })} />
                                </div>
                                {this.state.visible_dlg_lsortie == false ?
                                    <div className="p-col-12 p-md-2">
                                        <center>
                                            <Button label="Terminer" icon="pi pi-check" onClick={(e) => {
                                                if (this.state.liste_detail.length > 0) {
                                                    this.setState({
                                                        puLsortie: this.state.total_detail / this.state.qteLsortie,
                                                        qteLivrLsortie: 0
                                                    }, () => {
                                                        this.add_article();
                                                    })
                                                }
                                                this.setState({ visible_dlg_detail: false });
                                            }} style={{ width: '120px' }} />
                                        </center>
                                    </div>
                                    : null}

                            </div>
                        </>
                        : null}
                </Panel>


            </div>
        </div>
    }

    dlg_detail() {
        return <Dialog header="Détails techniques" visible={this.state.visible_dlg_detail} style={{ width: '85%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_detail: false })} >
            {this.detail_content()}
        </Dialog>
    }

    dlg_descLsortieContent() {
        return <Dialog header="Détails techniques" visible={this.state.visible_dlg_descLsortieContent} style={{ width: '300px' }} modal={false} onHide={(e) => this.setState({ visible_dlg_descLsortieContent: false })} >
            {this.descLsortieContent()}
        </Dialog>
    }


    render() {
        let disable_regl = false;
        if (this.state.userconnected.profilUser == 'Administrateur' && this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Usine') {
            disable_regl = true;
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_sortie();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_situation_client = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_situation_client: false, visible_dlg_historique: false, visible_dlg_article: false, visible_edition_facture: false })} label="Fermer" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_lsortie = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_lsortie: false });
                    this.delete_lsortie();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_lsortie: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_edition = (
            <div>
                <Button icon="pi pi-print" onClick={() => {
                    this.setState({ visible_edition_facture: false });
                    this.conf_print_facture(this.state.idSortie_impression, this.state.mode_impression)
                }} label="Générer" />
                <Button icon="pi pi-times" onClick={() => {
                    this.setState({ visible_edition_facture: false });
                    window.location.reload();
                }} label="Fermer" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_livraison = (
            <div>
                <Button icon="pi pi-print" onClick={() => {
                    this.conf_print_facture(this.state.selectedSortie, 'BL');
                }} label="BL global" className="p-button-warning" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_Bl: false, visible_dlg_livraison: false, visible_dlg_reglSortie: false })} label="Fermer" className="p-button-secondary" />
            </div>
        );

        let formmulaire = 'New';
        if (this.props.match.params.typeSortie != null && this.props.match.params.typeSortie != undefined) {
            if (this.props.match.params.typeSortie === 'Nouvelle vente') {
                formmulaire = 'Nouvelle vente';
            } if (this.props.match.params.typeSortie === 'Restaurant') {
                formmulaire = 'Restaurant';
            } else {
                formmulaire = this.props.match.params.typeSortie;
            }
        }

        let titre_new = "Nouvelle facture";

        if (this.get_type_sortie() == 'Proforma') {
            titre_new = "Nouvelle proforma";
        }

        const header_sortie = <div style={{ textAlign: 'left' }}>
            <Button type="button" disabled={disable_regl} icon="pi pi-briefcase" className="p-button-success" iconPos="left" label="Régler en bloc" style={{ width: 140 }} onClick={this.show_dlg_reglSortie_bloc}></Button>
            <Button type="button" icon="pi pi-trash" iconPos="left" label="Corbeille" style={{ width: 100 }} onClick={this.show_dlg_corbeille}></Button>
            <label style={{ float: 'right', fontSize: 22, fontWeight: 'bold', color: 'green' }}>{this.state.total_facture}</label>
        </div>;

        const header_corbeille = <div style={{ textAlign: 'left' }}>
            <label style={{ float: 'right', fontSize: 22, fontWeight: 'bold', color: 'red' }}>{this.state.total_facture_corbeille}</label>
        </div>;

        const header_proforma = <div style={{ textAlign: 'left' }}>
            <Button type="button" icon="pi pi-plus" iconPos="left" label={titre_new} style={{ width: 160 }} onClick={this.show_new_sortie_dlg}></Button>
            <label style={{ float: 'right', fontSize: 22, fontWeight: 'bold', color: 'green' }}>{this.state.total_facture}</label>
        </div>;

        const header_perte = <div style={{ textAlign: 'left' }}>
            <Button type="button" icon="pi pi-plus" iconPos="left" label="Nouvelle sortie perte" style={{ width: 200 }} onClick={this.show_new_sortie_dlg}></Button>
            <label style={{ float: 'right', fontSize: 22, fontWeight: 'bold', color: 'green' }}>{this.state.total_facture}</label>
        </div>;

        let header = header_sortie;
        if (this.props.match.params.typeSortie != null && this.props.match.params.typeSortie != undefined) {
            if (this.props.match.params.typeSortie === 'Proforma') {
                header = header_proforma;
            } else if (this.props.match.params.typeSortie === 'Perte') {
                header = header_perte;
            }
        }

        const header_lcmde = <div style={{ textAlign: 'left' }}>
            <Button type="button" disabled={this.state.disable_add_prod} icon="pi pi-plus" iconPos="left" label="Ajouter un article" style={{ width: 200 }} onClick={this.show_lsortie_dlg}></Button>
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
        </div>;

        let color = '#0d8ced';
        let hovercolor = '#39a3f4';
        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
            color = '#4e805c';
            hovercolor = '#b4df98';
        } else if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Station') {
            color = '#e10032';
            hovercolor = '#f8b4cb';
        }

        const header_refresh = <div style={{ textAlign: 'left' }}>
            <Button type="button" icon="pi pi-refresh" className="p-button-success" iconPos="left" label="Resynchroniser" style={{ width: 150 }} onClick={this.resynchroniser}></Button>
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            CF=FACTURE CONFORME IC = INTER MAGASIN CONFORME
        </div>


        if (formmulaire === 'Nouvelle vente' || formmulaire === 'Nouvelle proforma') {
            return <div className="p-fluid">
                <Dialog header="Inventaire" visible={this.state.visible_dlg_article} style={{ width: '40%' }} modal={false} onHide={(e) => {
                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                        this.setState({ rfiltreArticle: 'Tous' }, () => {
                            this.get_liste_article(this.state.userconnected.matrPers.idBoutiq.idBoutiq);
                        })
                    }
                    this.setState({ visible_dlg_article: false });
                }}>
                    <FormOptArticle idArticle={this.state.idArticle_opt} lprod={this.state.lprod_opt} />
                </Dialog>

                <Growl ref={(el_growl) => this.growl = el_growl} />
                {this.dlg_detail()}
                {this.dlg_descLsortieContent()}
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '300px' }} >
                    <div className="p-grid">
                        <div className="p-col-10">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '40px' }} />
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText value={this.state.nomInteresseSortie} onChange={(e) => this.setState({ nomInteresseSortie: e.target.value })} />
                                <label >Nom du client</label>
                            </span>
                        </div>

                    </div>
                </OverlayPanel>
                <Dialog header="Gestion assuré" visible={this.state.visible_dlg_assure} style={{ width: '80%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_assure: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ minHeight: '570px', marginBottom: -8 }}>
                                <TabView activeIndex={this.state.indexAssure} onTabChange={(e) => {
                                    this.setState({ indexAssure: e.index }, () => {
                                        if (this.state.indexAssure === 0) {
                                            if (this.state.liste_parent_assure !== undefined) {
                                                if (this.state.liste_parent_assure.length === 0) {
                                                    this.get_liste_parent_assure();
                                                }
                                            }
                                        } else {
                                            if (this.state.liste_generale_assure !== undefined) {
                                                if (this.state.liste_generale_assure.length === 0) {
                                                    this.get_liste_assure();
                                                }
                                            }
                                        }

                                    });
                                }}>
                                    <TabPanel header="Souscripteur" leftIcon="pi pi-home">
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-2">
                                                    <label >Client *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <Dropdown options={this.state.lclient_assure} value={this.state.idCli} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                                        onChange={(e) => {
                                                            this.setState({ idCli: e.value });
                                                        }} autoWidth={false} />
                                                </div>


                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Nom *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.nomParentAss} onChange={(e) => this.setState({ nomParentAss: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Remise *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.remiseParentAss} onChange={(e) => this.setState({ remiseParentAss: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Numéro police</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.numPoliceParentAss} onChange={(e) => this.setState({ numPoliceParentAss: e.target.value })} />
                                                </div>

                                            </div>
                                        </div>
                                        <center>
                                            <div className="p-grid" style={{ width: 300 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_parent_assure()} /></div>
                                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_parent_assure()} /></div>
                                            </div>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </center>
                                        <DataTable value={this.state.liste_parent_assure}
                                            responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single"
                                            onRowClick={e => this.setState({ selectedParentAssure: e.data })}
                                            selection={this.state.selectedParentAssure} >
                                            <Column header="#" body={this.actionTemplate_parent.bind(this)} style={{ width: '10%' }} />
                                            <Column field="nomParentAss" header="Souscripteur" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '30%' }} />
                                            <Column field="numPoliceParentAss" header="N° police" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                            <Column field="remiseParentAss" header="Remise" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                            {/* <Column field="statutSyncParentAss" body={(rowData, column) => {
                                                if (rowData.statutSyncParentAss == 'Envoyé') {
                                                    return <div>
                                                        <Button className="p-button-success" style={{ height: 25, fontWeight: 'bold' }} label='ENV' />
                                                    </div>
                                                } else {
                                                    return <div>
                                                        <Button className="p-button-warning" style={{ height: 25, fontWeight: 'bold' }} label='EC' />
                                                    </div>
                                                }
                                            }} header="Sync" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} /> */}
                                            <Column field="idCli.nomCli" header="Client" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '25%' }} />

                                        </DataTable>

                                    </TabPanel>
                                    <TabPanel header="Assurés" leftIcon="pi pi-users">
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-2">
                                                    <label >Souscripteur *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <Dropdown options={this.state.lparent_assure} value={this.state.idParentAss} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                                        onChange={(e) => {
                                                            this.setState({ idParentAss: e.value });
                                                        }} autoWidth={false} panelStyle={{ width: '500px' }} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Nom assuré *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.nomAss} onChange={(e) => this.setState({ nomAss: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Remise *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.remiseAss} onChange={(e) => this.setState({ remiseAss: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Sexe *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <Dropdown options={this.state.liste_sexe} value={this.state.sexeAss} panelStyle={{ width: '500px' }}
                                                        optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                                        onChange={(e) => this.setState({ sexeAss: e.value })} autoWidth={false} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Numéro police</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.numPoliceAss} onChange={(e) => this.setState({ numPoliceAss: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Prise en charge *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.pchargeAss} onChange={(e) => this.setState({ pchargeAss: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Matricule *</label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.matrAss} onChange={(e) => this.setState({ matrAss: e.target.value })} />
                                                </div>

                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="qteSeuil">Adresse </label>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <InputText value={this.state.adrAss} onChange={(e) => this.setState({ adrAss: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>

                                        <center>
                                            <div className="p-grid" style={{ width: 300 }}>
                                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_assure()} /></div>
                                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_assure()} /></div>
                                            </div>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </center>

                                        <DataTable value={this.state.liste_generale_assure}
                                            responsive={true} scrollable={true} paginatorPosition="top" paginator={true} rows={5}
                                            onRowClick={e => this.setState({ selectedAssure: e.data })}
                                            selection={this.state.selectedAssure} selectionMode="single">
                                            <Column header="#" body={this.actionTemplate_assure.bind(this)} style={{ width: '10%' }} />
                                            <Column field="nomAss" header="Nom assuré" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                            <Column field="remiseAss" header="Remise" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                            <Column field="pchargeAss" header="PA" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                            <Column field="idParentAss.nomParentAss" header="Souscripteur" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '22%' }} />
                                            <Column field="idParentAss.idCli.nomCli" header="Client" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                            {/* <Column field="statutSyncAss" body={(rowData, column) => {
                                                if (rowData.statutSyncAss == 'Envoyé') {
                                                    return <div>
                                                        <Button className="p-button-success" style={{ height: 25, fontWeight: 'bold' }} label='ENV' />
                                                    </div>
                                                } else {
                                                    return <div>
                                                        <Button className="p-button-warning" style={{ height: 25, fontWeight: 'bold' }} label='EC' />
                                                    </div>
                                                }
                                            }} header="Sync" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
 */}
                                        </DataTable>

                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>

                </Dialog>

                <Dialog header="Situation client" visible={this.state.visible_situation_client} style={{ width: '90%' }} modal={false} footer={dialogFooter_situation_client} onHide={(e) => this.setState({ visible_situation_client: false })}>
                    <FormSituation_client client={this.state.idCli_situation} />
                </Dialog>

                <Dialog header="Edition facture" visible={this.state.visible_edition_facture} style={{ width: '400px' }} modal={false} footer={dialogFooter_edition} onHide={(e) => this.setState({ visible_edition_facture: false })}>
                    <div className="content-section implementation">
                        {this.state.idSortie_impression !== undefined && this.state.idSortie_impression !== null ?
                            <div style={{ textAlign: 'left' }}>
                                <label >Format d'édition de la facutre </label>
                                <label style={{ color: 'red', fontWeight: 'bold' }} >N° {this.state.idSortie_impression.idSortie}</label>
                            </div>
                            : null}
                        <div className="p-grid" >
                            <div className="p-col-12 p-md-4">
                                <RadioButton inputId="rb1" name="A4" value="A4" onChange={(e) => this.setState({ mode_impression: e.value })} checked={this.state.mode_impression === 'A4'} />
                                <label htmlFor="rb1" className="p-radiobutton-label">A4</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <RadioButton inputId="rb2" name="Ticket" value="Ticket" onChange={(e) => this.setState({ mode_impression: e.value })} checked={this.state.mode_impression === 'Ticket'} />
                                <label htmlFor="rb2" className="p-radiobutton-label">Ticket</label>
                            </div>
                            {this.state.userconnected.idEtabl.codeEtabl == 'KEBALY' ?
                                <div className="p-col-12 p-md-4">
                                    <RadioButton inputId="rb3" name="Sans_entete" value="Sans_entete" onChange={(e) => this.setState({ mode_impression: e.value })} checked={this.state.mode_impression === 'Sans_entete'} />
                                    <label htmlFor="rb3" className="p-radiobutton-label">Sans entête</label>
                                </div> : null
                            }
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Nouveau client" visible={this.state.visible_dlg_client} style={{ width: '40%' }} modal={false} onHide={(e) => {
                    this.setState({ visible_dlg_client: false });
                }}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 430, marginBottom: -10 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="nomCli">Nom client *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText id="nomCli" onKeyDown={this.handleKeyPress} value={this.state.nomCli} onChange={(e) => this.setState({ nomCli: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label >Téléphone *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText keyfilter="pnumber" id="telCli" onKeyDown={this.handleKeyPress} value={this.state.telCli} onChange={(e) => this.setState({ telCli: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label >Type client </label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Dropdown options={this.state.liste_type_cli} value={this.state.typeCli} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => { this.setState({ typeCli: e.value }) }} autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <label >Adresse</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText id="adrCli" onKeyDown={this.handleKeyPress} value={this.state.adrCli} onChange={(e) => this.setState({ adrCli: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="adrCli">TVA *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText keyfilter="pnumber" onKeyDown={this.handleKeyPress} id="adrCli" value={this.state.tvaCli} onChange={(e) => this.setState({ tvaCli: e.target.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="adrCli">Remise en % *</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText keyfilter="pnumber" onKeyDown={this.handleKeyPress} value={this.state.remiseCli} onChange={(e) => this.setState({ remiseCli: e.target.value })} />
                                    </div>

                                    {this.state.userconnected.profilUser == 'PDG' || this.state.userconnected.profilUser == 'Coordinateur' ?
                                        <>
                                            <div className="p-col-12 p-md-4">
                                                <label htmlFor="mtDep">Seuil de crédit *</label>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <Cleave value={this.state.seuilCreditCli} onKeyDown={this.handleKeyPress} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ seuilCreditCli: e.target.rawValue })} className="p-inputtext" disabled={this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Directeur' && this.state.userconnected.profilUser !== 'Coordinateur'} />
                                            </div>
                                        </> : null}

                                    <div className="p-col-12 p-md-4">
                                        <label htmlFor="adrCli">Email</label>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText id="adrCli" onKeyDown={this.handleKeyPress} value={this.state.emailCli} onChange={(e) => this.setState({ emailCli: e.target.value })} />
                                    </div>
                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 400, marginTop: 10 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_client()} style={{ width: 150 }} /></div>
                                        <div className="p-col"> {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_client()} style={{ width: 150 }} /></div>
                                    </div>
                                </center>

                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Historique" visible={this.state.visible_dlg_historique} style={{ width: '80%' }} modal={false} onHide={(e) => { this.setState({ visible_dlg_historique: false }); }}>
                    <FormHistoriqueArticle idArticle={this.state.idArticle_opt} />
                </Dialog>



                <KeyboardEventHandler
                    handleKeys={['ctrl+u', 'ctrl+enter', 'ctrl+b', 'ctrl+a', 'ctrl+k', 'ctrl+j', 'ctrl+i']}
                    shiftKey
                    onKeyEvent={(key, e) => {
                        if (key === 'ctrl+enter') {
                            this.enreg_sortie(this.get_type_sortie());
                        } else if (key === 'ctrl+u') {
                            this.annule_sortie();
                        } else if (key === 'ctrl+b') {
                            this.enreg_sortie('Proforma');
                        } else if (key === 'ctrl+a') {
                            this.add_article();
                        } else if (key === 'ctrl+k') {
                            document.getElementById("new_dp_prod").click();
                        } else if (key === 'ctrl+j') {
                            window.location = '#/gestion/Proforma';
                        } else if (key === 'ctrl+i') {

                            if (this.selectedSortie != null && this.state.selectedSortie != undefined) {
                                this.vente_proforma(this.selectedSortie);
                            }
                        }
                    }} />
                {this.page_new_sortie()}
            </div>
        } else if (formmulaire === 'Restaurant') {
            const header = <div>
                <span style={{ color: 'red', fontSize: '18px', fontWeight: 'bold' }}>{this.state.montant_interesse_format}</span>
            </div>

            return <div className="p-fluid">
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '300px' }} >
                    <div className="p-grid">
                        <div className="p-col-8">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    document.getElementById("new_dp_client").click();
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                document.getElementById("new_dp_client").click();
                                this.get_liste_client();
                            }} style={{ width: '40px' }} />
                        </div>

                    </div>
                </OverlayPanel>
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Edition ticket" visible={this.state.visible_edition_facture} style={{ width: '300px' }} modal={false} footer={dialogFooter_edition} onHide={(e) => this.setState({ visible_edition_facture: false })}>
                    <div className="content-section implementation">
                        {this.state.idSortie_impression !== undefined && this.state.idSortie_impression !== null ?
                            <div style={{ textAlign: 'left' }}>
                                <label >Format d'édition de la facutre </label>
                                <label style={{ color: 'red', fontWeight: 'bold' }} >N° {this.state.idSortie_impression.idSortie}</label>
                            </div>
                            : null}
                        <div className="p-grid" >
                            <div className="p-col-12 p-md-4">
                                <RadioButton inputId="rb1" name="A4" value="A4" onChange={(e) => this.setState({ mode_impression: e.value })} checked={this.state.mode_impression === 'A4'} />
                                <label htmlFor="rb1" className="p-radiobutton-label">A4</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <RadioButton inputId="rb2" name="Ticket" value="Ticket" onChange={(e) => this.setState({ mode_impression: e.value })} checked={this.state.mode_impression === 'Ticket'} />
                                <label htmlFor="rb2" className="p-radiobutton-label">Ticket</label>
                            </div>
                        </div>
                    </div>

                </Dialog>
                <Dialog header="Note" visible={this.state.visible_dlg_vente_restau} style={{ width: '95%' }} modal={false} onHide={(e) => {
                    this.setState({ visible_dlg_vente_restau: false, modif: false });
                    this.get_liste_table();
                    this.annule_sortie_dlg();
                }}>
                    <div className="p-grid" >
                        <div className="p-col-12 p-lg-4">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 650, marginBottom: -12 }}>

                                        <Panel style={{ minHeight: '380px' }}>
                                            <DataTable value={this.state.liste_ligne} selectionMode="single" scrollable={true} scrollHeight="290px"
                                                onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                                responsive={true} selection={this.state.selectedLigne} style={{ marginBottom: 0 }} editable={true}
                                                header={header}>
                                                <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '12%' }} />
                                                <Column header="Article" style={{ width: '50%' }} body={(rowData, column) => {
                                                    let color = 'black';
                                                    if (parseFloat(rowData.qteLsortie) > parseFloat(rowData.qte_stock)) {
                                                        color = 'red';
                                                    }
                                                    return <div>
                                                        <span style={{ color: color }}>{rowData.idArticle.libArticle}</span>
                                                    </div>
                                                }} />
                                                <Column field="qteLsortie" header="Qté" style={{ width: '15%' }} body={(rowData, column) => {
                                                    let color = 'black';
                                                    if (parseFloat(rowData.qteLsortie) > parseFloat(rowData.qte_stock)) {
                                                        color = 'red';
                                                    }
                                                    return <div>
                                                        <span style={{ color: color }}>{rowData.qteLsortie}</span>
                                                    </div>
                                                }} editor={this.qteLsortieEditor} />
                                                <Column field="puLsortie" header="Prix" style={{ width: '23%' }} editor={this.puLsortieEditor} body={(rowData, column) => {
                                                    return <div>
                                                        <span>{this.formaterMontant(parseFloat(rowData.puLsortie))}</span>
                                                    </div>
                                                }} />
                                            </DataTable>
                                        </Panel>
                                        <Panel style={{ marginTop: 6 }}>
                                            <div className="p-grid" >
                                                <div className="p-col-12 p-md-3">
                                                    <label htmlFor="nomCli">Client *</label>
                                                </div>
                                                <div className="p-col-12 p-md-9">
                                                    <Dropdown options={this.state.lclient} id="new_dp_client" panelStyle={{ width: '500px' }} value={this.state.idCli} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                                        itemTemplate={this.clientItemTemplate} onChange={(e) => {
                                                            this.setState({ idCli: e.value, idCli_situation: e.value }, () => {
                                                                this.setState({ tvaSortie: this.state.idCli.tvaCli }, () => {
                                                                    this.calculer_total(this.state.liste_ligne);
                                                                    if (this.state.idArticle != null && this.state.idArticle != undefined) {
                                                                        let tt_remise = this.state.idArticle.currentRemise + this.state.idCli.remiseCli;
                                                                        this.setState({ remiseLsortie: 0.01 * tt_remise * this.state.puLsortie })
                                                                    }
                                                                });
                                                            })
                                                        }
                                                        } autoWidth={false} />

                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <label htmlFor="nomCli">Livreur *</label>
                                                </div>
                                                <div className="p-col-12 p-md-9">
                                                    <Dropdown options={this.state.liste_pers} value={this.state.matrLivreur} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => {
                                                        this.setState({ matrLivreur: e.value })
                                                    }} autoWidth={false} panelStyle={{ width: '500px' }} />

                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <label htmlFor="nomCli">Remise *</label>
                                                </div>
                                                <div className="p-col-12 p-md-9">
                                                    <Cleave value={this.state.val_remise} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ val_remise: e.target.rawValue }, () => {
                                                        this.calculer_total(this.state.liste_ligne);
                                                    })} className="p-inputtext" />
                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <label htmlFor="nomCli">Mt. payé *</label>
                                                </div>
                                                <div className="p-col-12 p-md-9">
                                                    <Cleave value={this.state.mtpaye} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtpaye: e.target.rawValue }, () => {
                                                        this.calculer_restant();
                                                    })} className="p-inputtext" />
                                                </div>
                                            </div>


                                            <center style={{ marginBottom: '-15px' }}>
                                                <div className="p-grid" style={{ width: 380 }}>
                                                    <div className="p-col"><Button label="Annuler" style={{ width: '100px', borderRadius: 15 }} icon="pi pi-ban" onClick={(e) => this.annule_sortie_dlg()} /></div>
                                                    <div className="p-col"><Button style={{ width: '100px', borderRadius: 15 }} disabled={this.state.liste_ligne.length <= 0 || this.state.idCli === null || this.state.idCli === undefined || this.state.modif === true} label="Enregistrer" className="p-button-warning" icon="pi pi-check" onClick={(e) => this.setState({ mtpaye: 0 }, () => {
                                                        this.enreg_sortie(this.get_type_sortie());
                                                    })} /></div>
                                                    <div className="p-col"><Button style={{ width: '100px', borderRadius: 15 }} disabled={this.state.liste_ligne.length <= 0 || this.state.idCli === null || this.state.idCli === undefined} label="Payer" className="p-button-success" icon="fa fa-fw fa-credit-card" onClick={(e) => {
                                                        if (this.state.modif === true && this.state.visible_dlg_vente_restau === true) {
                                                            let data = { idrglSortie: '0', refRgl: '', statutSyncRegl: this.myservice.get_statut_sync(), mtrglSortie: this.state.mtpaye, modeRgl: 'Espèce', daterglSortie: moment(new Date()).format('DD/MM/YYYY'), idSortie: { idSortie: this.state.selectedTable.sortie.idSortie } };
                                                            this.myservice.enreg_regl_sortie(data).then(rep => {
                                                                if (rep !== null && rep !== undefined) {
                                                                    if (rep.code == 200) {
                                                                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Payement effectué avec succès' });
                                                                        this.annule_lsortie();
                                                                    } else {
                                                                        this.growl.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
                                                                    }
                                                                } else {
                                                                    this.growl.show({ severity: 'error', summary: 'Error', detail: 'Erreur inconnue' });
                                                                }

                                                            });
                                                        } else {
                                                            this.setState({ mtpaye: this.state.mtapayer }, () => {
                                                                this.enreg_sortie(this.get_type_sortie());
                                                            });
                                                        }

                                                    }} /></div>
                                                </div>
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </center>
                                        </Panel>



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-8">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 650, marginBottom: -12 }}>
                                        <div className="p-grid" style={{ marginTop: -15, marginBottom: -2 }}>
                                            <div className="p-col-12 p-md-2" >
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Code barre</label>
                                                </span>
                                                <InputText placeholder="Scanner" id="input_code_barre" value={this.state.code_barre} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ code_barre: e.target.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-6" >
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Article *</label>
                                                </span>
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon" style={{ backgroundColor: '#f5f8f9', color: 'red', fontWeight: 'bold', width: 70 }}>{this.state.qte_stock}</span>
                                                    <Dropdown id="new_dp_prod" options={this.state.lprod} placeholder="Articles" value={this.state.idArticle} panelStyle={{ width: '500px' }} optionLabel='label_rech' filter={true} filterBy='label_rech' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                        onChange={(e) => {
                                                            this.setState({ idArticle: e.value }, () => {
                                                                this.setState({ puLsortie: this.state.idArticle.prix_ref_vente });
                                                                this.setState({ qte_stock: this.state.idArticle.qte_stock_format });
                                                                this.setState({ qteLsortie: 1 });
                                                                let remise_client = 0;
                                                                if (this.state.idCli != null && this.state.idCli != undefined) {
                                                                    remise_client = this.state.idCli.remiseCli;
                                                                }
                                                                let tt_remise = this.state.idArticle.currentRemise + remise_client;
                                                                this.setState({ remiseLsortie: 0.01 * tt_remise * this.state.idArticle.prix_ref_vente })
                                                            })
                                                        }
                                                        } filterInputAutoFocus={true} autoWidth={false} onKeyPress={this.handleKeyPress} />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-1" >
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Prix *</label>
                                                </span>
                                                <Cleave onKeyDown={this.handleKeyPress} placeholder="Prix unitaire" value={this.state.puLsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLsortie: e.target.rawValue }, () => {
                                                    this.setState({ puLsortie: e.target.rawValue });
                                                })} className="p-inputtext" />

                                            </div>
                                            <div className="p-col-12 p-md-1" >
                                                <span >
                                                    <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Qté *</label>
                                                </span>
                                                <InputText keyfilter="pint" placeholder="Quantité" id="qteInput" onKeyDown={this.handleKeyPress} value={this.state.qteLsortie} onChange={(e) => this.setState({ qteLsortie: e.target.value, qteLivrLsortie: e.target.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-2">
                                                <br />
                                                {this.state.showindicator ?
                                                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" />
                                                    :
                                                    <Button label="Ajouter" id="bt_add" icon="pi pi-plus" style={{ width: '80px' }} onClick={(e) => this.add_article()} />}
                                            </div>
                                        </div>
                                        <TabView activeIndex={this.state.activeIndex_stype} onTabChange={(e) => this.setState({ activeIndex_stype: e.index })}>
                                            {
                                                this.state.liste_sous_type_article.map((tab, index) => (
                                                    <TabPanel header={tab.libStype} key={index} {...tab}>
                                                        {this.get_content_article(tab.idStype)}
                                                    </TabPanel>
                                                ))
                                            }
                                        </TabView>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <div className="p-grid" >
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            {this.state.showindicator ?
                                <center>
                                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" />
                                </center>
                                : null}
                            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                                {
                                    this.state.liste_salle.map((tab, index) => (
                                        <TabPanel header={tab.nomSalle} key={index} {...tab}>
                                            {this.get_containt(tab.idSalle)}
                                        </TabPanel>
                                    ))
                                }
                            </TabView>
                        </div>
                    </div>
                </div>

            </div>
        } else return (
            //NOUVELLE PROFORMA, GESTION VENTE ET GESTION PROFORMAT 
            <div className="p-fluid">
                {this.dlg_detail()}
                {this.dlg_descLsortieContent()}
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Inventaire" visible={this.state.visible_dlg_article} style={{ width: '40%' }} modal={false} onHide={(e) => {
                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                        this.setState({ rfiltreArticle: 'Tous' }, () => {
                            this.get_liste_article(this.state.userconnected.matrPers.idBoutiq.idBoutiq);
                        })
                    }
                    this.setState({ visible_dlg_article: false });
                }}>

                    <FormOptArticle idArticle={this.state.idArticle_opt} lprod={this.state.lprod_opt} />

                </Dialog>
                <OverlayPanel ref={(el) => { this.op = el; }} style={{ width: '300px' }} >
                    <div className="p-grid">
                        <div className="p-col-8">
                            <InputText value={this.state.rfiltreClient} id="id_ovp" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.op.toggle(e);
                                    if (this.state.modif == true) {
                                        document.getElementById("new_dp_client_modif").click();
                                    } else if (this.state.modif == false) {
                                        document.getElementById("new_dp_client_rech").click();
                                    }

                                    if (this.state.visible_dlg_reglSortie_bloc == true) {
                                        document.getElementById("new_dp_client_rgl").click();
                                    }
                                    this.get_liste_client();
                                }
                            }} onChange={(e) => this.setState({ rfiltreClient: e.target.value })} autoFocus={true} />
                        </div>
                        <div className="p-col-2">
                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                this.op.toggle(e);
                                if (this.state.modif == true) {
                                    document.getElementById("new_dp_client_modif").click();
                                } else if (this.state.modif == false) {
                                    document.getElementById("new_dp_client_rech").click();
                                }

                                if (this.state.visible_dlg_reglSortie_bloc == true) {
                                    document.getElementById("new_dp_client_rgl").click();
                                }
                                this.get_liste_client();
                            }} style={{ width: '40px' }} />
                        </div>
                        <div className="p-col-12">
                            <span className="p-float-label">
                                <InputText value={this.state.nomInteresseSortie} onChange={(e) => this.setState({ nomInteresseSortie: e.target.value })} />
                                <label >Nom du client</label>
                            </span>
                        </div>

                    </div>
                </OverlayPanel>
                <KeyboardEventHandler
                    handleKeys={['ctrl+i']}

                    onKeyEvent={(key, e) => {
                        if (key === 'ctrl+i') {
                            if (this.selectedSortie != null && this.state.selectedSortie != undefined) {
                                this.vente_proforma(this.selectedSortie);
                            }
                        }
                    }} />

                <Dialog header="Historique" visible={this.state.visible_dlg_trace} style={{ width: '70%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_trace: false })} >
                    <center>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400 }}>
                                <DataTable value={this.state.liste_trace} scrollable={true} scrollHeight="350px"
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column field="dateTrace" header="Date" style={{ width: '10%' }} />
                                    <Column field="actionTrace" header="Action" style={{ width: '15%' }} />
                                    <Column field="idutil.matrPers.nomPers" header="Utilisateur" style={{ width: '20%' }} />
                                    <Column field="detailTrace" header="Détail" style={{ width: '55%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Situation client" visible={this.state.visible_situation_client} style={{ width: '90%' }} modal={false} footer={dialogFooter_situation_client} onHide={(e) => this.setState({ visible_situation_client: false })}>
                    <FormSituation_client client={this.state.idCli_situation} />
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_lsortie} style={{ width: '250px' }} modal={false} footer={dialogFooter_lsortie} onHide={(e) => this.setState({ visible_lsortie: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>



                <Dialog header="Règlement en bloc" visible={this.state.visible_dlg_reglSortie_bloc} style={{ width: '85%', opacity: '1' }} modal={false} appendToBody={true} onHide={(e) => this.setState({ visible_dlg_reglSortie_bloc: false })} >
                    <div className="p-grid">
                        {this.state.visible_dlg_reglSortie_bloc ?
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 430, marginBottom: '10px' }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-grid">
                                                <div className="p-col-10">
                                                    <Dropdown id="new_dp_client_rgl"
                                                        options={this.state.lclient}
                                                        value={this.state.ridClient}
                                                        placeholder="Client"
                                                        onChange={(e) => {
                                                            this.setState({ ridClient: e.value, idCli_situation: e.value }, () => {
                                                                this.get_liste_sortie_for_reglement();
                                                                if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                                                                    this.select_parent_assure_by_client(this.state.ridClient.value);
                                                                }
                                                            })
                                                        }}
                                                        style={{ width: '100%' }}
                                                        panelStyle={{ width: '400px' }}
                                                        filter={true} filterBy='label_rech' filterMatchMode='contains'
                                                        optionLabel='label_rech'
                                                        itemTemplate={(option) => {
                                                            if (!option.value) {
                                                                return option.label;
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="p-clearfix">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-12">
                                                                                <label>{option.label}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="p-col-1">
                                                    <Button type="button" icon="pi pi-search" className="p-button-warning" onClick={(e) => {
                                                        this.op.toggle(e);
                                                    }} style={{ marginLeft: -15, width: '30px' }} />
                                                </div>
                                            </div>

                                        </div>
                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' ?
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown style={{ width: '90%' }} options={this.state.lparent_assure_rech} placeholder="Souscripteur" value={this.state.ridParent_assure} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' onChange={(e) => this.setState({ ridParent_assure: e.value }, () => {
                                                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                                                        this.select_assure_by_parent(this.state.ridParent_assure.value);
                                                    }
                                                })} autoWidth={false} panelStyle={{ width: '550px' }} />
                                            </div>
                                            : null}
                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' ?
                                            <div className="p-col-12 p-md-2">
                                                <Dropdown style={{ width: '90%' }} options={this.state.lassure_rech} placeholder="Assuré" value={this.state.ridAssure} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' onChange={(e) => this.setState({ ridAssure: e.value })} autoWidth={false} panelStyle={{ width: '550px' }} itemTemplate={this.assureTemplate} />
                                            </div>
                                            : null}

                                        <div className="p-col-12 p-md-2">
                                            <Dropdown options={this.state.liste_type_cli} placeholder="Type client" value={this.state.rtypeClient} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rtypeClient: e.value }, () => {
                                                this.get_liste_sortie_for_reglement();
                                            })} autoWidth={false} style={{ width: '99%' }} panelStyle={{ width: '250px' }} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <div className="p-inputgroup">
                                                <Button icon='pi pi-search' label='Rechercher' style={{ width: '120px' }} onClick={(e) => {
                                                    this.get_liste_sortie_for_reglement();
                                                }} />
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable value={this.state.liste_sortie_for_reglement} scrollable={true} scrollHeight="260px"
                                        onSelectionChange={e => {
                                            this.setState({ total_reglement_format: '0' });
                                            this.setState({ liste_sortie_for_reglement_selecteds: e.value }, () => {
                                                let total = 0;
                                                if (this.state.liste_sortie_for_reglement_selecteds != null && this.state.liste_sortie_for_reglement_selecteds != undefined) {
                                                    if (this.state.liste_sortie_for_reglement_selecteds.length > 0) {
                                                        for (var i = this.state.liste_sortie_for_reglement_selecteds.length; i--;) {
                                                            total += this.state.liste_sortie_for_reglement_selecteds[i].mt_restant;
                                                        }
                                                    }
                                                }
                                                this.setState({ total_reglement_format: this.formaterMontant(total) });
                                            });
                                        }
                                        } selection={this.state.liste_sortie_for_reglement_selecteds}
                                        responsive={true} style={{ marginBottom: 12 }}>
                                        <Column selectionMode="multiple" style={{ width: '5%' }} />
                                        <Column field="dateSortie_format" header="Date" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="numFactureSortie" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#F34C1F', fontWeight: 'bold' }}>{rowData.numFactureSortie}</span>
                                            </div>
                                        }} header="Facture" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="montant_sortie" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#000', fontWeight: 'bold' }}>{rowData.montant_sortie_format}</span>
                                            </div>
                                        }} header="Montant" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="mt_restant" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.mt_restant_format}</span>
                                            </div>
                                        }} header="Restant" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                        <Column field="nomComplet" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ fontWeight: 'bold' }}>{rowData.idCli.nomCli} / </span>
                                                <span>{rowData.nom_souscripteur}</span><br />
                                                <span style={{ fontWeight: 'bold', color: '#F34C1F' }}>{rowData.nom_assure}</span>
                                            </div>
                                        }} header="Client" style={{ width: '40%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    </DataTable>

                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Date *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar value={this.state.daterglSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglSortie: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Montant total </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label style={{ fontWeight: 'bold', fontSize: '18px', color: 'green' }}>{this.state.total_reglement_format} </label>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Référence </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Mode reglement *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                            this.setState({ modeRgl: e.value })
                                        }
                                        } autoWidth={false} />
                                    </div>
                                </div>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_reglSortie_bloc()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_reglSortie_bloc()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            </div>
                            : null}
                    </div>
                </Dialog>

                <Dialog header="Règlement" visible={this.state.visible_dlg_reglSortie} style={{ width: '60%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_reglSortie: false })} footer={dialogFooter_livraison}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400, marginBottom: -8 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Date *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar value={this.state.daterglSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ daterglSortie: e.value })} />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Montant *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Cleave value={this.state.mtrglSortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtrglSortie: e.target.rawValue }, () => {
                                            //
                                        })} className="p-inputtext" />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Référence </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Modalité *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                            this.setState({ modeRgl: e.value })
                                        }
                                        } autoWidth={false} />
                                    </div>
                                </div>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_reglSortie()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_reglSortie()} /></div>
                                    </div>
                                </center>
                                <DataTable value={this.state.liste_regler_sortie} scrollable={true} scrollHeight="220px"
                                    onRowClick={e => this.setState({ selectedReglSortie: e.data })}
                                    selectionMode="single" selection={this.state.selectedReglSortie}
                                    responsive={true} style={{ marginBottom: 12 }}>
                                    <Column header="#" body={this.actionTemplate_reglSortie.bind(this)} style={{ width: '20%' }} />
                                    <Column field="daterglSortie" header="Date" style={{ width: '14%' }} />
                                    <Column field="mtrglSortie" body={this.montantReglBodyTemplate} header="Montant" style={{ width: '20%' }} />
                                    <Column field="modeRgl" header="Mode règl." style={{ width: '14%' }} />
                                    <Column field="refRgl" header="Référence" style={{ width: '22%' }} />
                                    <Column field="statutSyncRegl" body={(rowData, column) => {
                                        if (rowData.statutSyncRegl == 'Envoyé') {
                                            return <div>
                                                <Button className="p-button-success" style={{ height: 25, fontWeight: 'bold' }} label='ENV' />
                                            </div>
                                        } else {
                                            return <div>
                                                <Button className="p-button-warning" style={{ height: 25, fontWeight: 'bold' }} label='EC' />
                                            </div>
                                        }
                                    }} header="Sync" style={{ width: '10%' }} />

                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Bons de livraison" visible={this.state.visible_dlg_Bl} style={{ width: '85%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_Bl: false })} footer={dialogFooter_livraison}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 400 }}>
                                        {this.state.modif_bl == true ?
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="libCodif">Date *</label>
                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <Calendar value={this.state.dateBl} dateFormat="dd/mm/yy" disabled={this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur'} onChange={(e) => this.setState({ dateBl: e.value })} />
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <label htmlFor="descParamCodif">Receveur *</label>
                                                </div>
                                                <div className="p-col-12 p-md-5">
                                                    <InputText value={this.state.receveurBl} onChange={(e) => this.setState({ receveurBl: e.target.value })} />
                                                </div>
                                            </div>
                                            : null}
                                        {this.state.modif_bl == true ?
                                            <center>
                                                <div className="p-grid" style={{ width: 300 }}>
                                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_livraison()} /></div>
                                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_bl()} /></div>
                                                </div>
                                            </center>
                                            : null}

                                        <DataTable value={this.state.liste_livraison_sortie} scrollable={true} scrollHeight="320px"
                                            selectionMode="single" selection={this.state.selectedLivraisonSortie}
                                            onRowClick={e => this.setState({ selectedLivraisonSortie: e.data })} onRowSelect={e =>
                                                this.setState({ selectedLivraisonSortie: e.data }, () => {
                                                    this.get_liste_livraison_lsortie_by_bl(this.state.selectedLivraisonSortie.numBl);
                                                })
                                            }
                                            responsive={true} >
                                            <Column header="#" body={this.actionTemplate_bl.bind(this)} style={{ width: '25%' }} />
                                            <Column field="dateBl" header="Date" style={{ width: '18%' }} />
                                            <Column field="receveurBl" header="Receveur" />
                                            <Column field="numBl" header="N° BL" style={{ width: '20%' }} />
                                        </DataTable>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-lg-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="card card-w-title" style={{ height: 400 }}>
                                        <DataTable value={this.state.liste_livraison_lsortie} scrollable={true} scrollHeight="250px"
                                            responsive={true} >
                                            <Column field="idLsortie.idArticle.libArticle" body={this.articleLivraisonBodyTemplate} header="Désignation" style={{ width: '70%' }} />
                                            <Column field="idLsortie.qteLsortie" header="Achétée" style={{ width: '15%' }} />
                                            <Column field="qteLivrLs" header="Livrée" style={{ width: '15%' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Dialog>


                <Dialog header="Livraisons" visible={this.state.visible_dlg_livraison} style={{ width: '70%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_livraison: false })} footer={dialogFooter_livraison}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 400 }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libCodif">Date *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar value={this.state.dateBl} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateBl: e.value })} disabled={this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur'} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descParamCodif">Receveur *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.receveurBl} onChange={(e) => this.setState({ receveurBl: e.target.value })} />
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_livraison_lsortie} selectionMode="single" scrollable={true} scrollHeight="250px"
                                    onRowClick={e => this.setState({ selectedLivraisonLsortie: e.data })} onRowSelect={e => this.setState({ selectedLivraisonLsortie: e.data })}
                                    responsive={true} selection={this.state.selectedLivraisonLsortie} style={{ marginBottom: 12 }} editable={true}>
                                    <Column field="idLsortie.idArticle.libArticle" body={this.articleLivraisonBodyTemplate} header="Désignation" style={{ width: '55%' }} />
                                    <Column field="idLsortie.qteLsortie" header="Achetée" style={{ width: '15%' }} />
                                    <Column field="qteLivrLs_restant" header="Restant" style={{ width: '15%' }} />
                                    <Column field="qteLivrLs" header="Livrer" style={{ width: '15%' }} editor={this.qteLivrLsEditor} />
                                </DataTable>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_livraison()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_bl()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header="Envoi mail" visible={this.state.visible_dlg_mail} style={{ width: '50%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_mail: false })} >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ minHeight: 500, marginBottom: -10 }}>
                                <div className="p-grid">

                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Objet *</label>
                                        </span>
                                        <InputText value={this.state.objetMsg} onChange={(e) => this.setState({ objetMsg: e.target.value })} />
                                    </div>

                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Destinataires *</label>
                                        </span>
                                        <InputTextarea placeholder="email1;email2;email3;etc..." rows={2} cols={30} value={this.state.destMsg} onChange={(e) => this.setState({ destMsg: e.target.value })} />
                                    </div>
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Cc </label>
                                        </span>
                                        <InputTextarea placeholder="email1;email2;email3;etc..." rows={2} cols={30} value={this.state.ccMsg} onChange={(e) => this.setState({ ccMsg: e.target.value })} />
                                    </div>
                                    <div className="p-col-12">
                                        <span >
                                            <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Message *</label>
                                        </span>
                                        <InputTextarea rows={5} value={this.state.contenuMsg} onTextChange={(e) => this.setState({ contenuMsg: e.htmlValue })} />
                                    </div>

                                </div>
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_send_facture()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.send_facture()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            </div>
                        </div>
                    </div>
                </Dialog>


                <Dialog header={titre_new} visible={this.state.visible_new_sortie_dlg} style={{ width: '95%' }} modal={false} onHide={(e) => this.setState({ visible_new_sortie_dlg: false })}>
                    {this.state.visible_new_sortie_dlg === true ?
                        this.page_new_sortie()
                        : null}
                </Dialog>

                <Dialog header="Cofirmation modification client" visible={this.state.visible_conf_modif_cli} style={{ width: '400px' }} modal={false} footer={
                    <div>
                        <Button icon="pi pi-check" onClick={() => {
                            this.setState({ visible_conf_modif_cli: false, visible_dlg_sortie: true });
                            this.enreg_sortie('');
                        }} label="Valider" />
                        <Button icon="pi pi-times" onClick={() => {
                            this.setState({ visible_conf_modif_cli: false, visible_dlg_sortie: true });
                        }} label="Annuler" className="p-button-secondary" />
                    </div>
                } onHide={(e) => this.setState({ visible_conf_modif_cli: false, visible_dlg_sortie: true })}>
                    {this.state.visible_conf_modif_cli === true ?
                        <div style={{ marginBottom: -10 }}>
                            <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i> <span style={{ fontSize: 25, fontWeight: 'bold' }}>ATTENTION</span>
                            <hr />
                            <label style={{ fontSize: 16 }} >Voulez-vous vraiment modifier le client  <span style={{ fontWeight: 'bold', fontSize: 16, color: 'red' }}>{this.state.selectedSortie.idCli.nomCli}</span> en  <span style={{ fontWeight: 'bold', fontSize: 16, color: 'green' }}>{this.state.idCli.label}</span> ?</label>
                        </div> : null}

                </Dialog>

                <Dialog header="Modification facture" visible={this.state.visible_dlg_sortie} style={{ width: '70%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_sortie: false })}>
                    {this.state.visible_dlg_sortie === true ?
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ marginBottom: '-10px' }} >
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <label >Date facture *</label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <Calendar showTime={true} hourFormat="24" showSeconds={true} disabled={this.state.userconnected.profilUser != 'PDG'} value={this.state.dateSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateSortie: e.value })} viewDate={new Date()} />
                                        </div>

                                        {this.state.visible_drp_boutiq_concerne == true ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Boutique concernée *</label>
                                            </div>
                                            : null
                                        }
                                        {this.state.visible_drp_boutiq_concerne == true ?
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown options={this.state.lboutique} placeholder="Toutes les boutiques" value={this.state.idBoutiq} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                                    onChange={(e) => this.setState({ idBoutiq: e.value }, () => {
                                                        if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie') {
                                                            this.setState({ rfiltreArticle: 'Tous' }, () => {
                                                                // this.get_liste_article(this.state.idBoutiq.value);
                                                            })
                                                        }
                                                    })} autoWidth={false} />
                                            </div>
                                            : null
                                        }
                                        <div className="p-col-12 p-md-2">
                                            <label >N° facture *</label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <InputText value={this.state.numFactureSortie} onChange={(e) => this.setState({ numFactureSortie: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <label >Précompte </label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <InputText value={this.state.precompteSortie} onChange={(e) => this.setState({ precompteSortie: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <label >Client *</label>
                                        </div>

                                        <div className="p-col-12 p-md-4">
                                            <div className="p-grid">
                                                <div className="p-col-11">
                                                    <Dropdown id="new_dp_client_modif"
                                                        options={this.state.lclient}
                                                        value={this.state.idCli}
                                                        placeholder="Client"
                                                        onChange={(e) => {
                                                            this.setState({ idCli: e.value, idCli_situation: e.value }, () => {
                                                                if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie') {
                                                                    this.select_assure_by_client(this.state.idCli.value);
                                                                }
                                                                this.setState({ nomInteresseSortie: this.state.idCli.label });
                                                            })
                                                        }}
                                                        style={{ width: '100%' }}
                                                        filter={true} filterBy='label_rech' filterMatchMode='contains'
                                                        optionLabel='label_rech'
                                                        itemTemplate={(option) => {
                                                            if (!option.value) {
                                                                return option.label;
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="p-clearfix">
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-12">
                                                                                <label>{option.label}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="p-col-1">
                                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={(e) => {
                                                        this.op.toggle(e);
                                                    }} style={{ marginLeft: -15, width: '30px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <label >Nom client facture </label>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <InputText value={this.state.nomInteresseSortie} onChange={(e) => this.setState({ nomInteresseSortie: e.target.value })} />
                                        </div>

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Assuré</label>
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown options={this.state.liste_assure_vente} value={this.state.idAssSortie} optionLabel='label_rech' filter={true} filterBy='label_rech' filterMatchMode='contains' itemTemplate={this.assureTemplate} onChange={(e) => {
                                                    this.setState({ idAssSortie: e.value }, () => {
                                                        this.setState({ paSortie: this.state.idAssSortie.pchargeAss });
                                                    });
                                                }} autoWidth={false} panelStyle={{ width: '550px' }} />
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Prise en charge</label>
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <InputText keyfilter='pnum' value={this.state.paSortie} onChange={(e) => this.setState({ paSortie: e.target.value })} />
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Délai reglement *</label>
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <Calendar value={this.state.delaiRglSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ delaiRglSortie: e.value })} viewDate={new Date()} />
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Remise *</label>
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <Cleave value={this.state.remiseSortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ remiseSortie: e.target.rawValue })} className="p-inputtext" />
                                            </div>
                                            : null}


                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >TVA *</label>
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <InputText keyfilter='pnum' value={this.state.tvaSortie} onChange={(e) => this.setState({ tvaSortie: e.target.value })} />
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Modalité payement</label>
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <InputText value={this.state.modalitePaySortie} onChange={(e) => this.setState({ modalitePaySortie: e.target.value })} />
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Validité </label>
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <Calendar value={this.state.valideSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ valideSortie: e.value })} viewDate={new Date()} />
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Délai livraison </label>
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <Calendar value={this.state.delaiLivrSortie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ delaiLivrSortie: e.value })} viewDate={new Date()} />
                                            </div>
                                            : null}


                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Garantie </label>
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <InputText value={this.state.garantieSortie} onChange={(e) => this.setState({ garantieSortie: e.target.value })} />
                                            </div>
                                            : null}


                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Référence commande</label>
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <InputText value={this.state.refSortie} onChange={(e) => this.setState({ refSortie: e.target.value })} />
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Objet </label>
                                            </div>
                                            : null}

                                        {this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <InputText value={this.state.libSortie} onChange={(e) => this.setState({ libSortie: e.target.value })} />
                                            </div>
                                            : null}
                                        {this.get_type_sortie() === 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Type perte *</label>
                                            </div>
                                            : null}

                                        {this.get_type_sortie() === 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown options={this.state.liste_type_sortie} value={this.state.typeSortie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                                    onChange={(e) => this.setState({ typeSortie: e.value })} autoWidth={false} />                                            </div>
                                            : null}

                                        {this.get_type_sortie() === 'Facture' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Tag </label>
                                            </div>
                                            : null}

                                        {this.get_type_sortie() === 'Facture' ?
                                            <div className="p-col-12 p-md-4">
                                                <Dropdown disabled={this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur'} options={this.state.liste_tag_sortie} value={this.state.tagSortie} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                                    onChange={(e) => this.setState({ tagSortie: e.value })} autoWidth={false} />
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-2">
                                                <label >Conditions gnles </label>
                                            </div>
                                            : null}

                                        {this.state.userconnected.matrPers.idBoutiq.typeBoutiq !== 'Pharmacie' && this.get_type_sortie() !== 'Perte' ?
                                            <div className="p-col-12 p-md-4">
                                                <InputTextarea rows={2} value={this.state.cmtSortie} onChange={(e) => this.setState({ cmtSortie: e.target.value })} />
                                            </div>
                                            : null}


                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_sortie()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => {
                                                //#confmodif
                                                if (this.state.idCli == "" || this.state.idCli == null || this.state.dateSortie == "" || this.state.dateSortie == null) {
                                                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
                                                } else if (this.state.idBoutiq === undefined || this.state.idBoutiq === null) {
                                                    this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez la boutique concernée' });
                                                } else {
                                                    if (this.state.idCli.value !== this.state.selectedSortie.idCli.idCli) {
                                                        this.setState({ visible_conf_modif_cli: true, visible_dlg_sortie: false });
                                                    } else {
                                                        this.enreg_sortie('');
                                                    }
                                                }

                                            }} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                </div>
                            </div>
                        </div>
                        : null}
                </Dialog>

                <Dialog header="Ajout/Modification article" visible={this.state.visible_dlg_lsortie} style={{ width: '80%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_lsortie: false, modif_lsortie: false })}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-grid">
                                {this.state.visible_dlg_lsortie === true ?
                                    <div className="p-col-12 p-md-5">
                                        <label >Article *</label>
                                        <div className="p-grid">
                                            <div className="p-col-10">
                                                {this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Pharmacie' && this.state.modif_lsortie == false ?
                                                    <span className="p-float-label">
                                                        <InputText value={this.state.code_barre} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ code_barre: e.target.value })} />
                                                        <label htmlFor="in">Rechercher par code barre ou nom</label>
                                                    </span> : null
                                                }
                                                {this.state.modif_lsortie == false ?
                                                    <Dropdown id="new_dp_prod_modif"
                                                        options={this.state.lprod}
                                                        value={this.state.idArticle}
                                                        placeholder="Articles"
                                                        disabled={this.state.disable_prod}
                                                        onChange={(e) => {
                                                            this.setState({ idArticle: e.value }, () => {
                                                                this.setState({ constLsortie: this.state.idArticle.constRefArticle });
                                                                this.setState({ puLsortie: this.state.idArticle.prix_ref_vente });
                                                                this.setState({ qte_stock: this.state.idArticle.qte_stock_format });
                                                                this.setState({ qteLsortie: 1, designationLsortie: this.state.idArticle.label });
                                                                let remise_client = this.state.selectedSortie.idCli.remiseCli;
                                                                let tt_remise = this.state.idArticle.currentRemise + remise_client;
                                                                this.setState({ remiseLsortie: 0.01 * tt_remise * this.state.idArticle.prix_ref_vente });
                                                                this.calculer_mt(1, this.state.idArticle.prix_ref_vente, tt_remise * this.state.idArticle.prix_ref_vente);
                                                            })
                                                        }} style={{ width: '100%' }}
                                                        panelStyle={{ width: '550px' }}
                                                        optionLabel='label_rech' filter={true} filterBy='label_rech' itemTemplate={this.articleItemTemplate} filterMatchMode='contains'
                                                    />
                                                    :
                                                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>{this.state.libArticle}</label>
                                                }

                                            </div>
                                            <div className="p-col-2" style={{ marginTop: 5 }}>
                                                <span style={{ color: 'red', fontWeight: 'bold', width: 70, marginLeft: -15, padding: 8, height: 30, borderWidth: 1 }}>Stock {this.state.qte_stock}</span>
                                            </div>

                                        </div>

                                    </div>
                                    : null}

                                <div className="p-col-12 p-md-4">
                                    <label >Désignation *</label>
                                    <InputTextarea rows={2} cols={30} value={this.state.designationLsortie} onChange={(e) => this.setState({ designationLsortie: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-3">
                                    <label >Total </label>
                                    <Panel style={{ backgroundColor: '#ccff66', }}  >
                                        <label style={{ color: 'red', fontSize: '22px', fontWeight: 'bold' }} htmlFor="in">{this.formaterMontant(this.state.total_restant)}</label>
                                    </Panel>
                                </div>


                                <div className="p-col-12 p-md-3">
                                    <label >Quantité / poids *</label>
                                    <InputText keyfilter="pint" value={this.state.qteLsortie} onChange={(e) => {
                                        this.setState({ qteLsortie: e.target.value, qteLivrLsortie: e.target.value }, () => {
                                            if (this.state.modif_lsortie === true) {
                                                if (this.state.selectedLsortie.qteLsortie < this.state.qteLsortie) {
                                                    this.setState({ qteLivrLsortie: this.state.qteLsortie - this.state.selectedLsortie.qteLsortie });
                                                } else {
                                                    this.setState({ qteLivrLsortie: 0 });
                                                }
                                            }
                                            this.calculer_mt(this.state.qteLsortie, this.state.puLsortie, this.state.remiseLsortie);
                                        });
                                    }
                                    } />
                                </div>

                                <div className="p-col-12 p-md-3">
                                    <label >Prix unitaire *</label>
                                    <Cleave value={this.state.puLsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ puLsortie: e.target.rawValue }, () => {
                                        this.calculer_mt(this.state.qteLsortie, this.state.puLsortie, this.state.remiseLsortie);
                                    })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />


                                </div>
                                {this.aff_field_coef()}

                                <div className="p-col-12 p-md-3">
                                    <label >Remise *</label>
                                    <Cleave value={this.state.remiseLsortie} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ remiseLsortie: e.target.rawValue }, () => {
                                        this.calculer_mt(this.state.qteLsortie, this.state.puLsortie, this.state.remiseLsortie);
                                    })} className="p-inputtext" disabled={this.state.userconnected.idEtabl.codeEtabl == 'EDK' && this.state.userconnected.profilUser !== 'Administrateur' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.userconnected.profilUser !== 'PDG'} />

                                </div>

                                {this.get_type_sortie() === 'Facture' ?
                                    <div className="p-col-3" >
                                        <label >Quantité livrée *</label>
                                        <InputText keyfilter="pint" value={this.state.qteLivrLsortie} onChange={(e) => {
                                            this.setState({ qteLivrLsortie: e.target.value });

                                        }} />
                                    </div>
                                    : null}


                                {this.get_type_sortie() === 'Facture' && this.state.mtapayer > 0 ?
                                    <div className="p-col-12 p-md-3">
                                        <label >Montant payé *</label>
                                        <Cleave value={this.state.mtpaye} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ mtpaye: e.target.rawValue })} className="p-inputtext" />
                                    </div>
                                    : null}


                                {this.get_type_sortie() === 'Facture' && this.state.mtpaye > 0 ?
                                    <div className="p-col-12 p-md-3">
                                        <label >Mode règlement </label>
                                        <br />
                                        <Dropdown placeholder="Mode reglement" options={this.state.liste_mode_reglement} value={this.state.modeRgl} optionLabel='label' onChange={(e) => {
                                            this.setState({ modeRgl: e.value })
                                        }
                                        } autoWidth={false} />
                                    </div>
                                    : null}

                                {this.get_type_sortie() === 'Facture' && this.state.qteLivrLsortie > 0 ?
                                    <div className="p-col-12 p-md-3">
                                        <label >Receveur </label>
                                        <InputText value={this.state.receveurLsortie} onChange={(e) => this.setState({ receveurLsortie: e.target.value })} />
                                    </div>
                                    : null}
                            </div>
                            <br />
                            {this.state.userconnected.idEtabl.codeEtabl == 'MOUTOU' ?
                                this.state.nomSelectedClient.includes('UAGCP') ?
                                    this.detail_content()
                                    :
                                    null
                                : null}
                            <br />
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => { this.setState({ visible_dlg_lsortie: false }) }} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_lsortie()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                        </div>
                    </div>


                </Dialog>

                <ContextMenu model={this.menuModel} ref={elc => this.cm = elc} />

                <Dialog header="Corbeille" visible={this.state.visible_dlg_corbeille_sortie} style={{ width: '95%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_corbeille_sortie: false })}>

                    <div className="p-grid">
                        <div className="p-col-12">
                            {this.option_recherche_corbeille()}
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 500 }}>
                                                <DataTable value={this.state.liste_corbeille_sortie} selectionMode="single" paginatorPosition="top" paginator={true} rows={6}
                                                    onRowClick={e => this.setState({ selectedCorbeilleSortie: e.data })} onRowSelect={e =>
                                                        this.setState({ selectedCorbeilleSortie: e.data }, () => {
                                                            this.get_liste_corbeille_lsortie(this.state.selectedCorbeilleSortie.idSortie);
                                                        })
                                                    }
                                                    header={header_corbeille}
                                                    responsive={true} selection={this.state.selectedCorbeilleSortie}>
                                                    <Column field="statutSyncSortie" header="#" body={this.actionTemplate_corbeille.bind(this)} style={{ width: '10%', overflow: 'visible' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="numFactureSortie" body={this.factureTemplate} header="Facture" style={{ width: '27%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="montant_sortie" body={this.montantTemplate} header="Montant" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="nomComplet" body={this.clientTemplate} header="Client" style={{ width: '43%' }} filter={true} filterMatchMode='contains' sortable={true} />

                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 500 }}>
                                                <DataTable value={this.state.liste_corbeille_lsortie} selectionMode="single" scrollable={true} scrollHeight="380px"
                                                    responsive={true}  >
                                                    <Column field="idArticle.libArticle" header="Désignation" style={{ width: '50%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="qteLsortie" header="Qté" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="puLsortie_format" header="Prix" style={{ width: '17%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="montantLsortie_format" header="Montant" style={{ width: '23%' }} filter={true} filterMatchMode='contains' />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </Dialog>


                <Dialog header="Réconcilliation des factures" visible={this.state.visible_dlg_reconcilliation} style={{ width: '95%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_reconcilliation: false })}>
                    <div className="card card-w-title" style={{ height: 650 }}>
                        {this.option_recherche_reconcilliation()}
                        <DataTable value={this.state.liste_sortie_reconcilliation} paginatorPosition="top" paginator={true} rows={10}
                            responsive={true} onSelectionChange={e => this.setState({ liste_sortie_reconcilliation_selected: e.value })
                            } header={header_refresh} selection={this.state.liste_sortie_reconcilliation_selected}>
                            <Column selectionMode="multiple" style={{ width: '3%' }} />
                            <Column field="statutSyncSortie" header="Synchro" body={(rowData, column) => {
                                let cl = '';
                                let label = '';
                                if (rowData.statutSyncSortie == 'Envoyé') {
                                    cl = "p-button-success";
                                    label = rowData.ordre + '- Envoyé';
                                } else {
                                    label = rowData.ordre + '- Attente';
                                    cl = "p-button-warning";
                                }

                                return <div>
                                    <Button className={cl} label={label} />
                                </div>

                            }} style={{ width: '7%', overflow: 'visible' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="conforme" header="FC" style={{ width: '5%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="conforme_intermag" header="IC" style={{ width: '5%' }} filter={true} filterMatchMode='contains' sortable={true} />

                            <Column field="numFactureSortie" body={this.factureTemplate} header="Facture" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="nomComplet" body={this.clientTemplate} header="Client" style={{ width: '22%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            <Column field="montant_sortie" body={this.montantTemplate} header="Mt local" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />

                            <Column field="montant_sortie_remote" body={(rowData, column) => {
                                let livreur = '';
                                if (rowData.livreur != null) {
                                    if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Restaurant') {
                                        livreur = rowData.livreur.nomPers;
                                    }
                                }

                                return <div>
                                    <span style={{ color: '#000', fontWeight: 'bold' }}>{rowData.montant_sortie_format_remote}</span><br />
                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{rowData.mt_restant_format_remote}</span><br />
                                    <span style={{ color: '#000' }}>{livreur}</span>
                                </div>

                            }} header="Mt serveur" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />

                            <Column field="statutLivraison" body={this.statutTemplate} header="Statut local" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />

                            <Column field="statutLivraison_remote" body={(rowData, column) => {
                                let nom_table = rowData.nom_table;
                                let typeSortie = rowData.typeSortie;
                                if (rowData.idBoutiq.idBoutiq !== rowData.idBoutiqConcerne.idBoutiq) {
                                    typeSortie = 'Inter-MAG';
                                }
                                if (nom_table = 'null / null') {
                                    nom_table = '';
                                }

                                return <div>
                                    <span>{typeSortie}</span><br />
                                    <span style={{ color: '#000', fontWeight: 'bold' }}>{rowData.statutSortie_remote} {rowData.statutLivraison_remote}</span><br />
                                    <span>{nom_table}</span>
                                </div>

                            }} header="Statut serveur" style={{ width: '14%' }} filter={true} filterMatchMode='contains' sortable={true} />

                        </DataTable>
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" >
                            {this.option_recherche()}
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 820 }}>
                                                <DataTable value={this.state.liste_sortie} selectionMode="single" paginatorPosition="top" paginator={true} rows={7}
                                                    onRowClick={e => this.setState({ selectedSortie: e.data })} onRowSelect={e =>
                                                        this.setState({ selectedSortie: e.data }, () => {
                                                            this.get_liste_lsortie(this.state.selectedSortie.idSortie);
                                                            this.setState({ disable_add_prod: false, nomSelectedClient: this.state.selectedSortie.nomclient });
                                                            if (this.state.selectedSortie.idBoutiq.idBoutiq !== this.state.selectedSortie.idBoutiqConcerne.idBoutiq || this.state.selectedSortie.typeSortie === 'Transfert') {
                                                                this.setState({ disable_add_prod: true });
                                                                if (this.state.userconnected.profilUser === 'PDG' || this.state.userconnected.profilUser === 'Coordinateur') {
                                                                    this.setState({ disable_add_prod: false });
                                                                }
                                                            } else {
                                                                if (this.state.userconnected.profilUser !== 'PDG' && this.state.userconnected.profilUser !== 'Coordinateur' && this.state.selectedSortie.dateSortie_format_court !== moment(new Date()).format('DD/MM/YYYY')) {
                                                                    this.setState({ disable_add_prod: true });
                                                                }
                                                            };
                                                        })
                                                    }
                                                    header={header} onContextMenu={e => this.cm.show(e.originalEvent)}
                                                    responsive={true} selection={this.state.selectedSortie} ref={(el) => { this.dt = el; }}>
                                                    <Column field="statutSyncSortie" header="#" body={this.actionTemplate.bind(this)} style={{ width: '14%', overflow: 'visible' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="numFactureSortie" body={this.factureTemplate} header="Facture" style={{ width: '24%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="montant_sortie" body={this.montantTemplate} header="Montant" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="statutLivraison" body={this.statutTemplate} header="Statut" style={{ width: '16%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                                    <Column field="nomComplet" body={this.clientTemplate} header="Client" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />

                                                </DataTable>
                                                <Panel style={{ backgroundColor: '#f7f7f7' }}>
                                                    <div className="p-grid">
                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-1">
                                                                    <label htmlFor="libCodif">Ventes</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-3">
                                                                    <label style={{ fontSize: 16, fontWeight: 'bold' }} htmlFor="libCodif">{this.state.total_facture}</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-1">
                                                                    <label htmlFor="libCodif">Payé</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-3">
                                                                    <label style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }} htmlFor="libCodif">{this.state.montant_paye_format}</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-1">
                                                                    <label htmlFor="libCodif">Restant</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-3">
                                                                    <label style={{ fontSize: 16, fontWeight: 'bold', color: 'red' }} htmlFor="libCodif">{this.state.montant_restant_format}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Panel>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-6">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <div className="card card-w-title" style={{ height: 820 }}>
                                                <DataTable value={this.state.liste_lsortie} selectionMode="single" scrollable={true} scrollHeight="480px"
                                                    onRowClick={e => this.setState({ selectedLsortie: e.data })} onRowSelect={e => this.setState({ selectedLsortie: e.data })}
                                                    responsive={true} selection={this.state.selectedLsortie} header={header_lcmde} >
                                                    <Column header="#" body={this.actionTemplate_lsortie.bind(this)} style={{ width: '15%' }} />
                                                    <Column field="idArticle.libArticle" header="Désignation" style={{ width: '42%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="qteLsortie" header="Qté" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="puLsortie_format" header="Prix" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                                    <Column field="montantLsortie_format" header="Montant" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>





            </div>
        );
    }
}